import { lazy } from 'react';
// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import PrivateRoutes from 'config/privateroute';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Product = Loadable(lazy(() => import('views/product/products')));
const Company = Loadable(lazy(() => import('views/company/company')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/signin',
            element: <AuthLogin3 />
        },
        {
            path: '/signup',
            element: <AuthRegister3 />
        },
        {
            path: '/Product',
            element: <Product />
        },
        {
            path: '/app/product/company',
            element: <Company />
        },
        {
            path: '/app/product/company/employee',
            element: <>Coming Soon...</>
        }
    ]
};

export default AuthenticationRoutes;
