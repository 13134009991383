import payroll from './payroll-employee';
import dashboard from './dashboard-employee';
import { IconDashboard } from '@tabler/icons';

const icons = { IconDashboard };

// ==============================|| MENU ITEMS ||============================== //

const menuItemsEm = {
    items: [dashboard,
        //  payroll

    ]
};

export default menuItemsEm;
