import { Button, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { CompanyApi, EmployeeApi, JobInfoApi, PayscheduleApi, ProfileApi, ProvincesApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import swal from 'sweetalert';

const EmployeePersonalDetails = () => {
    const location = useLocation();
    const theme = useTheme();
    const [first_name, setfirst_name] = useState("")
    const [middle_name, setmiddle_name] = useState("")
    const [last_name, setlast_name] = useState("")
    const [birth_date, setbirth_date] = useState("")
    const [hire_date, sethire_date] = useState()
    const [SIN, setSIN] = useState("")
    const [term_date, setterm_date] = useState("")
    const [external_id, setexternal_id] = useState("")
    const [address, setaddress] = useState("")
    const [city, setcity] = useState(null)
    const [postal_code, setpostal_code] = useState("")
    const [country, setcountry] = useState("")
    const [phone, setphone] = useState("")
    const [Email, setEmail] = useState("")
    const [ProvincesALL, setProvincesALL] = useState([]);
    const [ProvinceID, setProvinceID] = useState(0);
    const [ProvinceName, setProvinceName] = useState("Select Province");
    const [PayTypeName, setPayTypeName] = useState("select");
    const [MasterPayTypes, setMasterPayTypes] = useState([]);
    const [PayTypeSelectedID, setPayTypeSelectedID] = useState(0);
    const [paygroupIdget, setpaygroupIdget] = useState(0);
    const [PaygroupName, setPaygroupName] = useState("select");
    const [MasterDataFrequency, setMasterDataFrequency] = useState([]);
    const [CountryList, setCountryList] = useState([]);
    const [CountrySelected, setCountrySelected] = useState("select");
    const [CountryId, setCountryId] = useState(0);
    useEffect(() => {
        getEmplyee();
        getPayTypesMAsterData();
        getFrequencyMAsterData();
        getCountryList();
    }, []);
    const [BissnessNumberValidation, setBissnessNumberValidation] = useState("");
    const [BisunessValid, setBisunessValid] = useState(false);
    function validateBN(bn) {
        // function to validate a Canadian CRA Busines Number (BN)
        // Note: same checkdigit formula as SIN validation applies to BN

        // initialize validation variable to true
        var valid = true;
        var msg = "";

        // trim whitespace at beginning and end of string
        //bn = bn.trim();

        // trim extra whitespace from string
        // Note: this function will trim ALL whitespace from string vs prev function which only trimmed 
        // from beginning and end of string.
        // Requires trim() function in genfunc.js to be included as external script file
        // bn = trim(bn);

        // Remove non-numeric characters.
        // Note: Only use this if we want to fix input errors without alerting user to the error.  Probably not a useful thing
        // to do since the string probably won't pass the required test for 9 characters or the checkdigit - better to alert the
        // user to the fact that they input non-numeric data.
        // bn = numericOnly(bn);	// function (will work in v3 browsers)
        // bn = bn.replace(/(\D)+/g,"");	// (will not work in v3 browsers)

        // must be 9 characters (digits)
        var digits = bn.length;
        if (digits === 0) {
            valid = false;
            setBisunessValid(false);
            msg = "";
        }
        else if (digits != 9) {
            valid = false;
            setBisunessValid(false);
            msg = "Submitted BN must have 9 digits";
            // msg = "";
        }
        else if (!bn.match(/^\d+$/)) 	// must contain ONLY digits 0-9
        {
            valid = false;
            setBisunessValid(false);

            msg = "BN contains invalid non-numeric characters";
        }
        else if (bn == "000000000")		// for use when unknown SIN only
        {
            setBisunessValid(false);

            msg = "000000000 may be used only when BN is unknown - please revalidate when BN is available";
        }
        else {	// perform the checkdigit validation routine

            // last (9th) digit is the check digit
            var checkdigit = bn.substr(8, 1);

            // Double the even-numbered position digits (pos 2,4,6 & 8)
            var double2 = parseInt(bn.substr(1, 1)) * 2;
            var double4 = parseInt(bn.substr(3, 1)) * 2;
            var double6 = parseInt(bn.substr(5, 1)) * 2;
            var double8 = parseInt(bn.substr(7, 1)) * 2;

            // concatenate the doubles into one number string
            var num1 = double2.toString() + double4.toString() + double6.toString() + double8.toString();

            // Extract the odd-numbered position digits
            var digit1 = bn.substr(0, 1);
            var digit3 = bn.substr(2, 1);
            var digit5 = bn.substr(4, 1);
            var digit7 = bn.substr(6, 1);

            // concatenate the digits into one number string
            var num2 = digit1 + digit3 + digit5 + digit7;

            // sum the digits in num1
            var crossadd1 = 0;
            var position = 0;
            for (position = 0; position < num1.length; position++) {
                crossadd1 = crossadd1 + parseInt(num1.substring(position, position + 1));
            }

            // sum the digits in num2
            var crossadd2 = 0;
            for (position = 0; position < num2.length; position++) {
                crossadd2 = crossadd2 + parseInt(num2.substring(position, position + 1));
            }

            // add the two sums
            var checksum1 = crossadd1 + crossadd2;
            var checksum2;
            var checkdigitX;

            if (checksum1.toString().substring(checksum1.toString().length - 1) == "0") {
                checksum2 = checksum1;
                checkdigitX = '0';
            } else {
                checksum2 = (Math.ceil(checksum1 / 10.0) * 10.0);
                checkdigitX = parseFloat(checksum2 - checksum1).toString();
            }

            if (checkdigitX == checkdigit) {
                valid = true;
                setBisunessValid(false);

                msg = "Valid: " + bn + " is a valid BN format.";
            } else {
                valid = false;
                msg = "Invalid: " + bn + " - check digit does not pass validation test.";
            }
        }
        /*else {
          msg = "Testing only - BN validates";
        }*/

        // alert(msg);
        console.log(msg);
        setBissnessNumberValidation(msg);
        return valid;
    }
    const getCountryList = () => {
        EmployeeApi.getContryList().then((res) => {
            setCountryList(res.data);

        }).catch((err) => { });
    }
    const getEmplyee = () => {

        EmployeeApi.getEmployeesById(localStorage.getItem('cffggr_id'), location.state).then((response) => {

            setphone(response.data.phone);
            setEmail(response.data.email);
            setfirst_name(response.data.first_name);
            setlast_name(response.data.last_name);
            setmiddle_name(response.data.middle_name);
            setSIN(response.data.sin);
            setcity(response.data.city);
            setaddress(response.data.address_1);
            setCountryId(response.data.country.id);
            setCountrySelected(response.data.country.name);
            setbirth_date(moment(response.data.birth_date).format('YYYY-MM-DD'));
            console.log(moment(response.data.birth_date).format('YYYY-MM-DD'));
            setcity(response.data.city);
            setpostal_code(response.data.postal_code);
            setcountry(response.data.country);
            setPaygroupName(response.data.payschedule.schedule_name);
            setpaygroupIdget(response.data.payschedule.id);
            setPayTypeName(response.data.pay_type.name);
            setPayTypeSelectedID(response.data.pay_type.id);
        }).catch((error) => {
            console.log(error);
        });
    }
    const updateEmployee = (e) => {
        e.preventDefault();
        console.log(birth_date);
        var cdata = {
            // "user_id": location.state,
            "birth_date": birth_date !== "Invalid date" ? birth_date : null,
            "sin": SIN,
            "address_1": address,
            "first_name": first_name,
            "middle_name": middle_name,
            "last_name": last_name,
            "phone": parseInt(phone),
            "email": Email,
            "address_2": address,
            "country_id": CountryId,
            "city": city,
            "postal_code": postal_code,
            // "pay_type_id": PayTypeSelectedID,
            // "payschedule_id": paygroupIdget
        };
        EmployeeApi.UpdateEmployeesById(localStorage.getItem('cffggr_id'), location.state, cdata)
            .then((response) => {
                console.log(response.data)
                swal("Empolyee Info Updated", "", "success");
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const getPayTypesMAsterData = () => {
        JobInfoApi.AllPayTypes().then((response) => {
            setMasterPayTypes(response.data);
        }).catch((error) => {
        })
    }
    const getFrequencyMAsterData = () => {
        PayscheduleApi.AllPayschedule(localStorage.getItem("cffggr_id")).then((response) => {
            setMasterDataFrequency(response.data);
        }).catch((error) => {

        })
    }
    // if (!first_name) {
    //     return null;
    // }
    const [checkSIN, setcheckSIN] = useState(true);
    function isValidUSZip(sZip) {
        if (/^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/.test(sZip)) {
            setcheckSIN(true);
            console.log("right");
        } else {
            console.log("wrong")
            setcheckSIN(false);
        }
    }

    const [checkEmail, setcheckEmail] = useState(true);
    function ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail.target.value)) {
            setcheckEmail(true)
        } else {
            setcheckEmail(false);
        }
    }
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <MainCard title="" style={{ marginTop: 20 }}>
            <Box
                sx={{
                    '& .MuiTextField-root': { m: 1.5 },
                }}
                noValidate
            >
                <form noValidate onSubmit={updateEmployee}>
                    <div>
                        <div style={{ color: theme.palette.secondary.dark }}>Personal Details</div>
                        <br />
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    required
                                    // disabled
                                    id="outlined-required"
                                    label="First Name"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={first_name}
                                    onChange={(e) => setfirst_name(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    // disabled

                                    id="outlined-required"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Middle Name"
                                    value={middle_name}
                                    onChange={(e) => setmiddle_name(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    type="date"
                                    fullWidth
                                    id="outlined-required"
                                    label="Birth Date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={birth_date}
                                    // value={moment(birth_date).format('DD/MM/YYYY')}
                                    onChange={(e) => setbirth_date(e.target.value)}
                                />
                                {/* {birth_date}
                                {moment(birth_date).format('DD/MM/YYYY')} */}

                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="SIN"
                                    inputProps={{
                                        maxLength: 9,
                                    }}
                                    pattern="[0-9]+"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    onChange={(e) => {
                                        const regex = /^[0-9\b]+$/;
                                        if (e.target.value === "" || regex.test(e.target.value)) {
                                            setSIN(e.target.value)
                                        }
                                    }}
                                    onBlur={(e) => validateBN(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={SIN}
                                // onChange={(e) => setSIN(e.target.value)}
                                />
                                <span style={{ color: BisunessValid ? "green" : "red" }}>
                                    {BissnessNumberValidation}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    // disabled

                                    id="outlined-required"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Last Name"
                                    value={last_name}
                                    onChange={(e) => setlast_name(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={4}>
                                <Select
                                    required
                                    style={{ marginLeft: 10 ,marginTop:10}}
                                    fullWidth
                                    value={ProvinceName}
                                    defaultValue='Select Province'
                                    onChange={(e) => setProvinceName(e.target.value)}
                                >
                                    <MenuItem value={"Select Province"} defaultChecked>Select Province</MenuItem>

                                    {ProvincesALL.map((data) => {
                                        return <MenuItem value={data.name} onClick={() => {
                                            console.log(data);
                                            setProvinceID(data.id);
                                        }}>{data.name}</MenuItem>;
                                    })}

                                </Select>
                            </Grid>


                        </Grid> */}
                        <br /><br />
                        <div style={{ color: theme.palette.secondary.dark }}>Contact Details</div>
                        <br />
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={4}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Address"
                                    fullWidth

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={address}
                                    onChange={(e) => setaddress(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="City"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={city}
                                    onChange={(e) => setcity(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} style={{ marginTop: 10, }}>
                                <Select
                                    required
                                    // value={CountrySelected}
                                    name="country"
                                    fullWidth
                                    defaultValue='select'
                                // onChange={(e) => setCountrySelected(e.target.data)}
                                >
                                    <MenuItem value={CountrySelected} defaultChecked>Country Select</MenuItem>
                                    {CountryList.map((data) => {
                                        return <MenuItem value={data.name} onClick={() => {
                                            setCountrySelected(data.name);
                                            setCountryId(data.id);
                                        }}>{data.name}</MenuItem>
                                    })}
                                    {/* <MenuItem value={"Hourly"}>Hourly</MenuItem>
                                                <MenuItem value={"Salary"}>salary</MenuItem> */}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={4}>
                                <div>
                                    <TextField
                                        required
                                        fullWidth
                                        id="outlined-required"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        pattern="/^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/"
                                        label="Postal Code"
                                        value={postal_code}
                                        onChange={(e) => {
                                            // if (isValidUSZip(e.target.value)) {
                                            setpostal_code(e.target.value)
                                            // }
                                        }}
                                        onBlur={(e) => isValidUSZip(e.target.value)}
                                    />
                                    <div style={{ marginLeft: 20 }}>{checkSIN === true ? "" : <div style={{ color: "red" }}>Enter the Valid Postal Code</div>}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>

                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <div class="col-md-6 form-group hire-input-country_box position-relative">

                                        </div>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Phone"
                                            fullWidth
                                            // disabled
                                            InputLabelProps={{
                                                shrink: true,

                                            }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <select name="country" class="form-control" id="country">
                                                        <option data-country-code="DZ" value="213">+91</option>
                                                        <option data-country-code="AD" value="376">Andorra</option>
                                                        <option data-country-code="AO" value="244">Angola</option>
                                                        <option data-country-code="AI" value="1264">Anguilla</option>
                                                        <option data-country-code="AG" value="1268">Antigua &amp; Barbuda
                                                        </option>
                                                    </select></InputAdornment>,
                                            }}
                                            value={phone}
                                            onChange={(e) => {
                                                const regex = /^[0-9\b]+$/;
                                                if (e.target.value === "" || regex.test(e.target.value)) {
                                                    setphone(e.target.value)
                                                }
                                            }
                                            }
                                        />  </div>

                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Email"
                                    fullWidth
                                    // disabled
                                    type={"email"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={Email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onBlur={(e) => ValidateEmail(e)}

                                />
                                <div style={{ marginLeft: 20 }}>{checkEmail === true ? "" : <div style={{ color: "red" }}>Enter the Valid Email</div>}</div>

                            </Grid>
                        </Grid>
                        <br></br>
                    </div>
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        xs={8}
                    >
                        Save changes
                    </Button>
                </form>
            </Box>

        </MainCard>
    )
}

export default EmployeePersonalDetails;