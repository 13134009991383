import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Product from "views/product/products";
const PrivateRoutes = () => {
    return (
        localStorage.getItem("token") ?
            <Outlet />
            :
            <Navigate to={"/signin"} />
    );
}

    ;

export default PrivateRoutes;