import { useTheme } from '@emotion/react';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import useScriptRef from 'hooks/useScriptRef';
import { AppBar, Button, Dialog, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Switch, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';

import React from 'react'
import MainCard from 'ui-component/cards/MainCard'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Close, Delete, Edit } from '@mui/icons-material';
import { IconEye } from '@tabler/icons';
import { useState } from 'react';
import { Box } from '@mui/system';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { HiringInfoApi } from 'config/api';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import moment from 'moment';
import swal from 'sweetalert';
import Moment from 'react-moment';
function Ehiringinfo() {
    const theme = useTheme();
    const scriptedRef = useScriptRef();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#5E35B1",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const [HireTermData, setHireTermData] = useState([]);
    const [onClose, setonClose] = useState(false);
    const handleClose = () => {
        setonClose(!onClose);
    };
    const location = useLocation();
    console.log(location.state);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const [HiringRecordId, setHiringRecordId] = useState(0);
    const [HiringDate, setHiringDate] = useState("");
    const [TerminateDate, setTerminateDate] = useState("");
    useEffect(() => {
        gethiringData(localStorage.getItem('cffggr_id'), location.state);
    }, [])

    const gethiringData = (cid, eid) => {
        console.log("res.data")

        HiringInfoApi.getHireTermData(cid, eid).then((res) => {

            setHireTermData(res.data);
            console.log(HireTermData)
        }).catch((err) => { })
    }
    const DeletehiringData = (id) => {
        console.log("res.data")

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Hiring Info!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    HiringInfoApi.deleteHireTermData(localStorage.getItem('cffggr_id'), location.state, id).then((res) => {
                        swal("hiring Info has been deleted!", {
                            icon: "success",
                        });
                        gethiringData(localStorage.getItem('cffggr_id'), location.state);

                    }).catch((err) => { })

                } else {
                    // swal("Your imaginary file is safe!");
                }
            });

    }

    const updateData = (id, hiringDate) => {
        var data = {
            "id": id,
            "user_id": location.state,
            "hire_date": hiringDate,
            "term_date": moment().format('YYYY-MM-DD')
        }
        HiringInfoApi.UpdateHireTermData(localStorage.getItem('cffggr_id'), location.state, id, data).then((re) => {
            gethiringData(localStorage.getItem('cffggr_id'), location.state);
            // handleClose();

            swal("Employee Terminated Successful", "", "success");

        }).catch((err) => {
            console.log(err);
        });
    }
    const getDataById = (id) => {

        HiringInfoApi.getHireTermDataById(localStorage.getItem('cffggr_id'), location.state, id).then((re) => {
            setHiringDate(moment(re.data.hire_date).format('YYYY-MM-DD'));
            setTerminateDate(moment(re.data.term_date).format('YYYY-MM-DD'));
            handleClose();
        }).catch((err) => {
            console.log(err);
        });
    }


    return (
        <MainCard>
            <Button
                onClick={() => {
                    handleClose();
                    setHiringDate("");
                    setTerminateDate("");
                    setHiringRecordId(0);
                }
                }
                variant="contained"
                color="secondary"
            >Create New Term Days</Button> <br />
            <br />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Hiring Date</StyledTableCell>
                            <StyledTableCell>Term Date</StyledTableCell>
                            <StyledTableCell>Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {HireTermData.map((data) => (

                            <StyledTableRow sx={{ border: 0 }}>
                                <StyledTableCell>

                                    {/* {moment(data.hire_date).format('LL')} */}
                                    <Moment format="LL" utc="true">
                                        {data.hire_date}
                                    </Moment>
                                </StyledTableCell>

                                <StyledTableCell>{data.term_date === null ?
                                    <Tooltip title="Terminate now">
                                        <Button
                                            onClick={() => updateData(data.id, data.hire_date)
                                            }
                                            variant="contained"
                                            color="secondary"
                                        >Termination</Button></Tooltip> :
                                    <Moment format="LL" utc="true">
                                        {data.term_date}
                                    </Moment>
                                }</StyledTableCell>

                                {/* <StyledTableCell>{data.term_date ? moment(data.term_date).format('LL') : "-"}</StyledTableCell> */}
                                <StyledTableCell> <Edit style={{ marginRight: 5, cursor: "pointer" }} onClick={() => {
                                    setHiringRecordId(data.id);
                                    getDataById(data.id);
                                }} /><Delete style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => DeletehiringData(data.id)} /></StyledTableCell>

                            </StyledTableRow>
                        ))}


                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                onClose={handleClose}
                open={onClose}
                closeAfterTransition>
                <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
                    <Toolbar >

                        <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">

                            Hiring Information</Typography>

                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close style={{ color: "black" }} />
                        </IconButton>

                    </Toolbar>
                </AppBar>
                <div style={{ padding: 20 }}>
                    <Formik
                        initialValues={{
                            term_date: TerminateDate,
                            hiring_date: HiringDate,
                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            // password: Yup.string().max(255).required('Password is required')
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

                            if (HiringRecordId === 0) {
                                var data = {

                                    "hire_date": values.hiring_date,
                                    "term_date": values.term_date ? values.term_date : null
                                }
                                HiringInfoApi.AddHireTermData(localStorage.getItem('cffggr_id'), location.state, data).then((re) => {
                                    gethiringData(localStorage.getItem('cffggr_id'), location.state);
                                    handleClose();
                                    swal("Employee Info Updated Successful", "", "success");

                                }).catch((err) => {
                                    console.log(err);
                                });
                            } else {
                                var data = {
                                    "id": HiringRecordId,
                                    "user_id": location.state,
                                    "hire_date": values.hiring_date,
                                    "term_date": values.term_date ? values.term_date : null
                                }
                                HiringInfoApi.UpdateHireTermData(localStorage.getItem('cffggr_id'), location.state, HiringRecordId, data).then((re) => {
                                    gethiringData(localStorage.getItem('cffggr_id'), location.state);
                                    handleClose();
                                    swal("Employee Info Updated Successful", "", "success");

                                }).catch((err) => {
                                    console.log(err);
                                });
                            }







                            try {
                                if (scriptedRef.current) {
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                }
                            } catch (err) {
                                console.error(err);
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>


                                <Grid container spacing={matchDownSM ? 0 : 2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth error={Boolean(touched.hiring_date && errors.hiring_date)}>

                                            {/* <InputLabel>Date</InputLabel> */}
                                            <TextField
                                                id="outlined-number"
                                                label="Hiring Date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={values.hiring_date}
                                                name="hiring_date"
                                                onChange={handleChange}

                                            />
                                            {touched.hiring_date && errors.hiring_date && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.hiring_date}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth error={Boolean(touched.term_date && errors.term_date)}>

                                            {/* <InputLabel>Date</InputLabel> */}
                                            <TextField
                                                id="outlined-number"
                                                label="Term Date"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={values.term_date}
                                                name="term_date"
                                                onChange={handleChange}

                                            />
                                            {touched.term_date && errors.term_date && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.term_date}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>


                                </Grid>
                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}

                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Save
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </div>
            </Dialog>
        </MainCard>
    )
}

export default Ehiringinfo