// assets
import { IconDashboard, IconPackage, IconReport, IconMan } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconPackage, IconReport, IconMan };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const payroll = {
    id: 'payroll',
    title: 'Payroll',
    type: 'group',
    children: [
        {
            id: 'payroll',
            title: 'Payroll',
            type: 'item',
            url: '/app-payroll-employee/payroll',
            icon: icons.IconPackage,
            breadcrumbs: false
        },
        {
            id: 'report',
            title: 'Report',
            type: 'item',
            url: '/app-payroll-employee/report',
            icon: icons.IconReport,
            breadcrumbs: false
        },

    ]
};

export default payroll;
