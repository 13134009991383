import { AppBar, Button, Chip, Dialog, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { CompanyApi, DeductionApi, IncomeApi, JobInfoApi, VacationApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@emotion/react';
import { Check, Close, Delete, Edit, Info, InfoSharp } from '@mui/icons-material';

// ==============================|| SAMPLE PAGE ||============================== //

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const IncomeTypes = () => {
  const location = useLocation();
  console.log(localStorage.getItem("cffggr_id")
  );
  const [IncomeEditID, setIncomeEditID] = useState(0);
  const [income_description, setincome_description] = useState("")
  const [income_name, setincome_name] = useState("")
  const [PolicyName, setPolicyName] = useState("");
  const [gl_expense_account, setgl_expense_account] = useState("")
  const [gl_liablity_account, setgl_liablity_account] = useState("")
  const [income_tax, setincome_tax] = useState(false)
  const [cpp, setcpp] = useState(false)
  const [ei, setei] = useState(false)
  const [vacationable, setvacationable] = useState(false);
  const scriptedRef = useScriptRef();
  const [DataIncomeType, setDataIncomeType] = useState([]);


  //Type Masters
  const [SelectedTypeOdDeductionID, setSelectedTypeOdDeductionID] = useState(0);
  const [selectedTypePolicy, setselectedTypePolicy] = useState(0);
  const [IncomeTax, setIncomeTax] = useState("");
  const [Cpp_and_Qpp, setCpp_and_Qpp] = useState("");
  const [ei_select, setei_select] = useState("");
  const [qpip, setqpip] = useState("");

  const [IncomeTaxEmployer, setIncomeTaxEmployer] = useState("");
  const [Cpp_and_QppEmployer, setCpp_and_QppEmployer] = useState("");
  const [ei_selectEmployer, setei_selectEmployer] = useState("");
  const [qpipEmployer, setqpipEmployer] = useState("");
  const [IsIncomeTypeVacation, setIsIncomeTypeVacation] = useState(null);


  const [Payble, setPayble] = useState("");
  const [insurableHours, setinsurableHours] = useState("");
  const [insurableEarning, setinsurableEarning] = useState('');
  const [boxT4, setboxT4] = useState("");
  const [RL1Box, setRL1Box] = useState("");
  const [VacationsData, setVacationsData] = useState([]);
  const [MasterPayTypes, setMasterPayTypes] = useState([]);

  const [PayTypeSelectedID, setPayTypeSelectedID] = useState(0);
  const [SelectedPayTypesArr, setSelectedPayTypesArr] = useState([]);
  const [PayTypeName, setPayTypeName] = useState([]);
  const [PayTypeOBJ, setPayTypeOBJ] = useState([]);


  const [isPolicyCheck, setisPolicyCheck] = useState(false);



  const getIncomeData = () => {

    IncomeApi.GetIncomeData(localStorage.getItem("cffggr_id")).then((response) => {

      if (response.data === null)
        return null;
      setDataIncomeType(response.data);
    })
      .catch((error) => {
      });

    VacationApi.AllVacation(localStorage.getItem("cffggr_id")).then((response) => {
      if (response.data === null)
        return null;
      setVacationsData(response.data);
    })
      .catch((error) => {
      });
  }
  const getPayTypesMAsterData = () => {
    JobInfoApi.AllPayTypes().then((response) => {
      setMasterPayTypes(response.data);
    }).catch((error) => {

    })
  }
  const deleteDeduction = (id) => {
    console.log(id);
    IncomeApi.DeleteIncomeData(localStorage.getItem("cffggr_id"), id).then((resposne) => {
      getIncomeData();
    }).catch((error) => {

    })
  }
  useEffect(() => {
    getIncomeData();
    getIncomesMasterData();
    getPayTypesMAsterData();
  }, []);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const theme = useTheme();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [valueTab, setValueTab] = React.useState(0);

  const handleChangeTab = (event, newValueTab) => {
    setValueTab(newValueTab);
  };

  const [onClose, setonClose] = useState(false);
  const handleClose = () => {
    setIncomeEditID(0);
    clearData();
    setonClose(!onClose);
  };

  const [IncomsMasterData, setIncomsMasterData] = useState([]);
  const getIncomesMasterData = () => {
    IncomeApi.GetIncomeMasterData().then((response) => {
      setIncomsMasterData(response.data);
    }).catch((error) => {
      console.log(error);
    })
  }

  const clearData = () => {
    setIncomeEditID(0);
    setincome_name("");
    setgl_expense_account("");
    setincome_description("");
    setSelectedTypeOdDeductionID("");
    setIncomeTax("");
    setCpp_and_Qpp("");
    setei_select("");
    setPayble("");
    setinsurableHours("");
    setinsurableEarning("");
    setboxT4("");
    setRL1Box("");
  }
  const getIncomesDataById = (id) => {
    IncomeApi.IncomeDataDatabyId(localStorage.getItem("cffggr_id"), id).then((response) => {
      setIncomeEditID(response.data.id);
      setincome_name(response.data.income_type.income_type);
      setgl_expense_account(response.data.gl_expense_account);
      setisPolicyCheck(response.data.is_policy);
      setincome_description(response.data.income_name);
      setSelectedTypeOdDeductionID(response.data.income_type.id);
      setIncomeTax(response.data.income_type.income_tax);
      setCpp_and_Qpp(response.data.income_type.cpp);
      setei_select(response.data.income_type.ei);
      setPayble(response.data.income_type.payable);
      setinsurableHours(response.data.income_type.insurable_hours);
      setinsurableEarning(response.data.income_type.insurable_earning);
      setboxT4("85");
      setRL1Box("A,J");
      setSelectedPayTypesArr(response.data.income_type.income_pay_type !== null ? response.data.income_type.income_pay_type : []);
      console.log(response.data.income_type.income_type);
      console.log(income_name);
      setonClose(!onClose);
    }).catch((error) => {
      console.log(error);
    })
  }
  const handleChangeIncomeType = (event) => {
    const {
      target: { value },
    } = event;
    setPayTypeName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeIncomeTypeObject = (event) => {
    // const {
    //   target: { value },
    // } = event;
    setPayTypeOBJ(
      // On autofill we get a stringified value.
      event
    );
  };
  return (
    <MainCard title="Income Types">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1.5 },
        }}
        noValidate
        autoComplete="off"
      >

        <Button onClick={() => handleClose()}
          variant="contained"
          color="secondary"
        >Add an Income</Button>
        <br />
        <br />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Income Description</StyledTableCell>
                <StyledTableCell>Income Type</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {DataIncomeType.map((data) => (
                <StyledTableRow sx={{ border: 0 }}>
                  <StyledTableCell>{data.income_name}</StyledTableCell>
                  <StyledTableCell>{data.income_type.income_type}</StyledTableCell>
                  <StyledTableCell><Edit style={{ marginRight: 5, cursor: "pointer" }} onClick={() => getIncomesDataById(data.id)} />{data.income_type.is_fixed === false ? <Delete style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => deleteDeduction(data.id)} /> : ""}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog
        onClose={handleClose}
        maxWidth="xl"
        open={onClose}
        closeAfterTransition>

        <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
          <Toolbar >
            <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
              Income Type</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close style={{ color: "black" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container spacing={matchDownSM ? 0 : 2}>
          <Grid item xs={12} sm={6}>
            <div style={{ padding: 20 }}>
              <Formik
                initialValues={{
                  income_description: income_description,
                  income_name: income_name == "" ? "Income Type" : income_name,
                  gl_expense_account: gl_expense_account,
                  policy_name: PolicyName,
                  gl_liablity_account: gl_liablity_account,
                  income_tax: income_tax,
                  cpp: cpp,
                  ei: ei,
                  submit: null
                }}
                validationSchema={Yup.object().shape({
                  // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                  income_name: Yup.string().max(255).required('Income Type is required'),
                  income_description: Yup.string().max(255).required('Income Description is required'),
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                  console.log(IncomeEditID);
                  if (IncomeEditID !== 0) {
                    console.log("cdata");
                    var cdata = {
                      id: IncomeEditID,
                      income_name: values.income_description,
                      policy_id: selectedTypePolicy,
                      income_id: SelectedTypeOdDeductionID,
                      is_policy: isPolicyCheck,
                      gl_expense_account: values.gl_expense_account,
                    };
                    console.log(cdata);
                    IncomeApi.IncomeDataUpdatebyId(localStorage.getItem("cffggr_id"), IncomeEditID, cdata)
                      .then((response) => {
                        console.log(response.data)
                        getIncomeData();
                        handleClose();
                        swal("Income Type Updated", "", "success");
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    var cdata = {
                      income_name: values.income_description,
                      policy_id: selectedTypePolicy,

                      income_id: SelectedTypeOdDeductionID,
                      is_policy: isPolicyCheck,
                      gl_expense_account: values.gl_expense_account,
                    };
                    IncomeApi.RegisterIncome(localStorage.getItem("cffggr_id"), cdata)
                      .then((response) => {
                        console.log(response.data)
                        getIncomeData();
                        handleClose();
                        swal("Income Type Added", "", "success");

                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  try {
                    if (scriptedRef.current) {
                      setStatus({ success: true });
                      setSubmitting(false);
                    }
                  } catch (err) {
                    console.error(err);
                    if (scriptedRef.current) {
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                    }
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <div>Income Type</div>
                    <FormControl required fullWidth error={Boolean(touched.income_name && errors.income_name)} sx={{ ...theme.typography.customInput }}>
                      <Select
                        required
                        value={values.income_name}
                        name="income_name"
                        readOnly={IncomeEditID !== 0}
                        defaultValue='Income Type'
                        onChange={handleChange}
                      >
                        <MenuItem value={"Income Type"} defaultChecked>Income Type</MenuItem>
                        {IncomsMasterData.map((data) => {
                          return <MenuItem value={data.income_type} onClick={() => {
                            console.log(data);
                            setIsIncomeTypeVacation(data.is_policy ? true : false);
                            setSelectedTypeOdDeductionID(data.id);
                            setIncomeTax(data.income_tax);
                            setCpp_and_Qpp(data.cpp);
                            setei_select(data.ei);
                            setPayble(data.payable);
                            setqpip(data.employee_qpip);
                            setinsurableHours(data.insurable_hours);
                            setinsurableEarning(data.insurable_earning);
                            setboxT4("85");
                            setRL1Box("A,J");
                            setSelectedPayTypesArr(data.income_pay_type);
                          }}>{data.income_type}</MenuItem>;
                        })}
                      </Select>
                      {touched.income_name && errors.income_name && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.income_name}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <br />
                    <br />
                    {IsIncomeTypeVacation !== null ? IsIncomeTypeVacation === true ?
                      // <> Policy ?
                      <FormControl required fullWidth error={Boolean(touched.income_name && errors.income_name)} sx={{ ...theme.typography.customInput }}>
                        <Select
                          required
                          value={values.policy_name}
                          name="policy_name"
                          // readOnly={IncomeEditID !== 0}
                          defaultValue='Policy Name'
                          onChange={handleChange}
                        >
                          <MenuItem value={"Policy Name"} defaultChecked>Policy Name</MenuItem>
                          {VacationsData.map((data) => {
                            return <MenuItem value={data.policy_name} onClick={() => {
                              setselectedTypePolicy(data.id);
                              console.log(data);
                            }}>{data.policy_name}</MenuItem>;
                          })}
                        </Select>
                        {touched.income_name && errors.income_name && (
                          <FormHelperText error id="standard-weight-helper-text--register">
                            {errors.income_name}
                          </FormHelperText>
                        )}
                      </FormControl>
                      : <>
                      </>

                      : <div></div>}
                    <br />
                    <br />
                    <Grid container>
                      {SelectedPayTypesArr.length !== undefined ? SelectedPayTypesArr.map((data) => {
                        return <div style={{ backgroundColor: "skyblue", padding: 5, margin: 5, color: "white", borderRadius: 5 }}>{data.pay_type.name}</div>;
                      }) : ""}
                    </Grid>
                    {/* <div>Pay Type</div>
                    <FormControl required fullWidth error={Boolean(touched.pay_type && errors.pay_type)} sx={{ ...theme.typography.customInput }}>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={PayTypeName}
                        onChange={handleChangeIncomeType}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >

                        {MasterPayTypes.map((data) => (
                          <MenuItem
                            key={data.name}
                            value={data.name}
                            onClick={handleChangeIncomeTypeObject(data)}
                          >
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}

                    <FormControl fullWidth error={Boolean(touched.income_description && errors.income_description)} sx={{ ...theme.typography.customInput }}>

                      <InputLabel htmlFor="outlined-adornment-lastname-register">Income Description</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-lastname-register"
                        type="text"
                        value={values.income_description}
                        name="income_description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.income_description && errors.income_description && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.income_description}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <FormControl fullWidth error={Boolean(touched.gl_expense_account && errors.gl_expense_account)} sx={{ ...theme.typography.customInput }}>

                      <InputLabel htmlFor="outlined-adornment-lastname-register">GL Expense Account (Optional)</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-lastname-register"
                        type="text"
                        value={values.gl_expense_account}
                        name="gl_expense_account"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.gl_expense_account && errors.gl_expense_account && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.gl_expense_account}
                        </FormHelperText>
                      )}
                    </FormControl>


                    {errors.submit && (
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}

                    <Box sx={{ mt: 2 }}>
                      <AnimateButton>
                        <Button
                          disableElevation
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          color="secondary"
                        >
                          Save
                        </Button>
                      </AnimateButton>
                    </Box>
                  </form>
                )}
              </Formik>
            </div>

          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ padding: 20 }}>
              <Tabs
                orientation="horizontal"
                variant="scrollable"
                centered
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                value={valueTab} onChange={handleChangeTab}
              >
                <Tab label="Employee Contribution" />
              </Tabs>
              {
                valueTab === 0 ? <div>
                  <Grid container spacing={matchDownSM ? 0 : 2}>
                    <Grid item xs={12} sm={12}>
                      <TableContainer >
                        <Table sx={{ minWidth: 200 }} >
                          {/* <TableHead style={{ backgroundColor: "wheat" }}> */}
                          <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>FEDERAL</StyledTableCell>
                            {/* <StyledTableCell>GL Expense no</StyledTableCell> */}
                          </TableRow>
                          {/* </TableHead> */}
                          <TableBody style={{ borderWidth: 3, borderColor: "black" }} >

                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Income Tax</StyledTableCell>
                              <StyledTableCell>{IncomeTax ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>CPP/QPP</StyledTableCell>
                              <StyledTableCell>{Cpp_and_Qpp ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>  <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>EI</StyledTableCell>
                              <StyledTableCell>{ei_select ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>
                            {/* <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>QPIP</StyledTableCell>
                              <StyledTableCell>{qpip ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow> */}
                          </TableBody>
                          <br />
                          <TableBody>
                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Payable</StyledTableCell>
                              <StyledTableCell>{Payble ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>

                          </TableBody>
                          <br />
                          <b>Record Of Employment</b>
                          <br />

                          <TableBody>

                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Insurable hours</StyledTableCell>
                              <StyledTableCell>{insurableHours}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Insurable earnings</StyledTableCell>
                              <StyledTableCell>{insurableEarning}</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>

                          <br />
                          <b>From T4</b>
                          <br />

                          <TableBody>

                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Box</StyledTableCell>
                              <StyledTableCell>{boxT4}</StyledTableCell>
                            </StyledTableRow>

                          </TableBody>
                          <br />
                          <b>From RL-1</b>
                          <br />

                          <TableBody>

                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Box</StyledTableCell>
                              <StyledTableCell>{RL1Box}</StyledTableCell>
                            </StyledTableRow>

                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                  </Grid>
                </div> : <div>


                </div>
              }
            </div>
          </Grid>
        </Grid>
      </Dialog>


    </MainCard >
  );
};

export default IncomeTypes;

