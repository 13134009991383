import { AppBar, Button, Card, Dialog, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Switch, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { CompanyApi, DeductionApi, HolidayApi, IncomeApi, PaygroupApi, VacationApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@emotion/react';
import { Close, Delete, Edit, Info } from '@mui/icons-material';

const Vacation = () => {
  const location = useLocation();
  console.log(localStorage.getItem("cffggr_id")
  );

  const [EditDataId, setEditDataId] = useState(0);
  const [deduction_name, setdeduction_name] = useState("")
  const [policy_rate, setpolicy_rate] = useState(0)
  const [income_tax, setincome_tax] = useState(false)
  const [income_name, setincome_name] = useState("");
  const scriptedRef = useScriptRef();
  const [VacationsData, setVacationsData] = useState([]);

  const [selectedPolicyName, setselectedPolicyName] = useState("");
  const [selectedPolicyId, setselectedPolicyId] = useState(0);
  const getVacationData = () => {

    VacationApi.AllVacation(localStorage.getItem("cffggr_id")).then((response) => {
      if (response.data === null)
        return null;
      setVacationsData(response.data);
    })
      .catch((error) => {
      });
  }

  useEffect(() => {
    getVacationData();
    getIncomesTransData();
  }, []);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const theme = useTheme();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [TaxType, setTaxType] = React.useState('%');
  const [Type, setType] = useState("");
  const handleChangeTax = (event) => {
    setTaxType(event.target.value);
  };
  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const [onClose, setonClose] = useState(false);
  const handleClose = () => {
    clearData();
    setonClose(!onClose);
  };
  const clearData = () => {
    setEditDataId(0);
    setdeduction_name("");
    setpolicy_rate("");
    setTaxType("%");
  }

  const [IncomsMasterData, setIncomsMasterData] = useState([]);
  const getIncomesTransData = () => {

    IncomeApi.GetIncomeData(localStorage.getItem("cffggr_id")).then((response) => {

      if (response.data === null)
        return null;
      setIncomsMasterData(response.data);
    })
      .catch((error) => {
      });

  }
  const getDataById = (id) => {
    VacationApi.GetVacationById(localStorage.getItem("cffggr_id"), id).then((re) => {
      setEditDataId(re.data.id);
      setdeduction_name(re.data.policy_name);
      setpolicy_rate(re.data.rate);
      // setincome_name(re.data.)
      setTaxType(re.data.type === "percentage" ? "%" : "$");
      setonClose(!onClose);
    }).catch((err) => { });
  }

  const DeleteDataById = (id) => {
    VacationApi.DeleteVacation(localStorage.getItem("cffggr_id"), id).then((re) => {
      getVacationData();
    }).catch((err) => { });
  }
  return (
    <MainCard title="Policies">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1.5 },
        }}
        noValidate
        autoComplete="off"
      >


        <Grid container spacing={matchDownSM ? 0 : 2}>
          <Grid item xs={12} sm={12}>
            <Button
              onClick={() => handleClose()
              }
              variant="contained"
              color="secondary"
            >Add Policy</Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          </Grid>

        </Grid>
        <br />
        <br />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Policy Name</StyledTableCell>
                <StyledTableCell>Policy Rate</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {VacationsData.map((data) => (
                <StyledTableRow sx={{ border: 0 }}>
                  <StyledTableCell>{data.policy_name}</StyledTableCell>
                  <StyledTableCell>{data.type !== "percentage" ? " $ " : ""}{data.rate}{data.type === "percentage" ? " %" : ""}</StyledTableCell>
                  <StyledTableCell><Edit style={{ marginRight: 5, cursor: "pointer" }}

                    onClick={() => getDataById(data.id)}
                  /><Delete style={{ marginLeft: 5, cursor: "pointer" }}
                    onClick={() => DeleteDataById(data.id)}
                    /></StyledTableCell>

                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>



      </Box>


      <Dialog
        onClose={handleClose}
        maxWidth={"xl"}
        open={onClose}
        closeAfterTransition>

        <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
          <Toolbar >

            <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
              Policy</Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close style={{ color: "black" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />
        <br />
        <br />
        <MainCard>
          <Formik
            initialValues={{
              policy_name: deduction_name,
              income_name: income_name == "" ? "Income Type" : income_name,
              policy_rate: policy_rate,
              income_tax: income_tax,
              submit: null
            }}
            validationSchema={Yup.object().shape({
              // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              // password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              if (EditDataId !== 0) {
                var cdata = {
                  id: EditDataId,
                  "policy_name": values.policy_name,
                  // "income_id": selectedPolicyId,
                  "type": TaxType === "%" ? "percentage" : "fixed",
                  "rate": values.policy_rate
                };

                VacationApi.UpdateVacation(localStorage.getItem("cffggr_id"), EditDataId, cdata)
                  .then((response) => {
                    console.log(response.data)
                    getVacationData();
                    handleClose();
                    swal("Vacation Updated", "", "success");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                var cdata = {
                  "policy_name": values.policy_name,
                  // "income_id": selectedPolicyId,
                  "is_accrued": Type === "accrue_each_pay" ? true : false,
                  // "paid_out_each_pay": Type === "paid_out_each_pay" ? true : false,
                  "type": TaxType === "%" ? "percentage" : "fixed",
                  "rate": values.policy_rate
                };
                VacationApi.AddVacation(localStorage.getItem("cffggr_id"), cdata)
                  .then((response) => {
                    console.log(response.data)
                    getVacationData();
                    handleClose();
                    swal("Vacation Added", "", "success");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              try {
                if (scriptedRef.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
              } catch (err) {
                console.error(err);
                if (scriptedRef.current) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form noValidate onSubmit={handleSubmit}>


                <Grid container spacing={matchDownSM ? 0 : 2}>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth error={Boolean(touched.policy_name && errors.policy_name)}>

                      <TextField
                        id="outlined-number"
                        label="Policy Name"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="policy_name"
                        value={values.policy_name}
                        onChange={handleChange}

                      />
                      {touched.policy_name && errors.policy_name && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.policy_name}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={matchDownSM ? 0 : 2}>
                  <Grid item xs={12} sm={12}>
                    <FormControl required fullWidth error={Boolean(touched.income_name && errors.income_name)} sx={{ ...theme.typography.customInput }}>
                      <Select
                        required
                        value={values.income_name}
                        name="income_name"

                        defaultValue='Income Type'
                        onChange={handleChange}
                      >
                        <MenuItem value={"Income Type"} defaultChecked>Income Type</MenuItem>
                        {IncomsMasterData.map((data) => {
                          return data.is_policy ? <MenuItem value={data.income_name} onClick={() => {
                            console.log(data);
                            setselectedPolicyName(data.income_name);
                            setselectedPolicyId(data.id);
                          }}>{data.income_name}</MenuItem> : ""
                        })}
                      </Select>
                      {touched.income_name && errors.income_name && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.income_name}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid> */}
                <br></br>

                <Grid container spacing={matchDownSM ? 0 : 2}>
                  <Grid item xs={12} sm={12}>


                    <FormControl fullWidth>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        value={Type}
                        onChange={handleChangeType}
                      >
                        <Grid item xs={12} >
                          <FormControlLabel value="accrue_each_pay" control={<Radio />} label="Accumulate Vacation Pay" />
                        </Grid>
                        <Grid item xs={12} >
                          <FormControlLabel value="paid_out_each_pay" control={<Radio />} label="Pay out Each Pay" />
                        </Grid>
                      </RadioGroup>
                    </FormControl>

                  </Grid>
                </Grid>

                <br></br>
                <Grid container spacing={matchDownSM ? 0 : 2}>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={Boolean(touched.holiday_date && errors.holiday_date)}>

                      {/* <InputLabel>Date</InputLabel> */}
                      <TextField
                        id="outlined-number"
                        label="Policy Rate"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="policy_rate"
                        value={values.policy_rate}
                        onChange={handleChange}

                      />
                      {touched.holiday_date && errors.holiday_date && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.holiday_date}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <FormLabel id="demo-row-radio-buttons-group-label">Tax Type</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        value={TaxType}
                        onChange={handleChangeTax}
                      >
                        <FormControlLabel value="%" control={<Radio />} label="%" />
                        <FormControlLabel value="$" control={<Radio />} label="$" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid>


                  </Grid>
                </Grid>
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Box sx={{ mt: 2 }}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={isSubmitting}
                      fullWidth
                      size="medium"
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      Save
                    </Button>
                  </AnimateButton>
                </Box>
              </form>
            )}
          </Formik>
        </MainCard>
      </Dialog>


    </MainCard >
  );
};

export default Vacation