import { AppBar, Button, Card, Dialog, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Switch, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { CompanyApi, DeductionApi, HolidayApi, PaygroupApi, RemitterApi, TaxAgencyApi, VacationApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@emotion/react';
import { Close, Delete, Edit, Info } from '@mui/icons-material';

const TaxAgency = () => {
    const location = useLocation();
    console.log(localStorage.getItem("cffggr_id")
    );

    const [taxAgencyName, settaxAgencyName] = useState("")
    const [EditDataID, setEditDataID] = useState(0);
    const scriptedRef = useScriptRef();
    const [TaxAgencyData, setTaxAgencyData] = useState([]);
    const [remittanceSchedule, setremittanceSchedule] = useState("")
    const [RemirrerData, setRemirrerData] = useState([]);
    const [RemitterID, setRemitterID] = useState(0);
    const [Cranumber, setCranumber] = useState("");

    const getRemitterData = () => {
        RemitterApi.AllRemitter().then((res) => {
            console.log(res.data);
            setRemirrerData(res.data);

        }).catch((err) => {

        });
    }

    const getTaxAgencyData = () => {

        TaxAgencyApi.AllTaxAgency(localStorage.getItem("cffggr_id")).then((response) => {
            if (response.data === null)
                return null;
            setTaxAgencyData(response.data);
        })
            .catch((error) => {
            });
    }

    useEffect(() => {
        getTaxAgencyData();
        getRemitterData();
    }, []);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const theme = useTheme();

    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const [onClose, setonClose] = useState(false);
    const handleClose = () => {
        setEditDataID(0);
        settaxAgencyName("");
        setonClose(!onClose);
    };

    const deleteTax = (id) => {
        console.log(id);
        TaxAgencyApi.DeleteTaxAgency(localStorage.getItem("cffggr_id"), id).then((resposne) => {
            getTaxAgencyData();
        }).catch((error) => {

        })
    }

    const [CRANumberValidation, setCRANumberValidation] = useState("");
    const [CRANumberValid, setCRANumberValid] = useState(false);
    function validateSIN(sin) {
        // function to validate a Canadian Social Insurance Number (SIN)

        // initialize validation variable to true
        var valid = true;
        var msg = "";

        // trim whitespace at beginning and end of string
        //sin = sin.trim();

        // trim extra whitespace from string
        // Note: this function will trim ALL whitespace from string vs prev function which only trimmed 
        // from beginning and end of string.
        // Requires trim() function in genfunc.js to be included as external script file
        // sin = trim(sin);

        // Remove non-numeric characters.
        // Note: Only use this if we want to fix input errors without alerting user to the error.  Probably not a useful thing
        // to do since the string probably won't pass the required test for 9 characters or the checkdigit - better to alert the
        // user to the fact that they input non-numeric data.
        //sin = numericOnly(sin);	// function (will work in v3 browsers)
        //sin = sin.replace(/(\D)+/g,"");	// (will not work in v3 browsers)

        // must be 9 characters (digits)
        var digits = sin.length;
        if (digits > 9) {
            setCRANumberValid(false);

            valid = false;
            msg = "Invalid: " + sin + " has more than maximum 9 digits.";
        }
        else if (digits < 9) {
            setCRANumberValid(false);

            valid = false;
            if (digits == 0) {
                msg = "Please enter a 9 digit Social Insurance Number.";
            } else {
                msg = "Invalid: " + sin + " has less than the required 9 digits.";
            }
        }
        else if (!sin.match(/^\d+$/)) 	// must contain ONLY digits 0-9
        {
            setCRANumberValid(false);
            valid = false;
            msg = "Invalid: " + sin + " contains invalid non-numeric characters";
        }
        else if (sin == "000000000")		// for use when unknown SIN only
        {
            setCRANumberValid(false);
            msg = "000000000 may be used only when SIN is unknown - please revalidate when SIN is available";
        }
        else {	// perform the checkdigit validation routine

            // last (9th) digit is the check digit
            var checkdigit = sin.substr(8, 1);

            // Double the even-numbered position digits (pos 2,4,6 & 8)
            var double2 = parseInt(sin.substr(1, 1)) * 2;
            var double4 = parseInt(sin.substr(3, 1)) * 2;
            var double6 = parseInt(sin.substr(5, 1)) * 2;
            var double8 = parseInt(sin.substr(7, 1)) * 2;

            // concatenate the doubles into one number string
            var num1 = double2.toString() + double4.toString() + double6.toString() + double8.toString();

            // Extract the odd-numbered position digits
            var digit1 = sin.substr(0, 1);
            var digit3 = sin.substr(2, 1);
            var digit5 = sin.substr(4, 1);
            var digit7 = sin.substr(6, 1);

            // concatenate the digits into one number string
            var num2 = digit1 + digit3 + digit5 + digit7;

            // sum the digits in num1
            var crossadd1 = 0;
            var position = 0;
            for (position = 0; position < num1.length; position++) {
                crossadd1 = crossadd1 + parseInt(num1.substring(position, position + 1));
            }

            // sum the digits in num2
            var crossadd2 = 0;
            for (position = 0; position < num2.length; position++) {
                crossadd2 = crossadd2 + parseInt(num2.substring(position, position + 1));
            }

            // add the two sums
            var checksum1 = crossadd1 + crossadd2;
            var checksum2;
            var checkdigitX;

            if (checksum1.toString().substring(checksum1.toString().length - 1) == "0") {
                checksum2 = checksum1;
                checkdigitX = '0';
            } else {
                checksum2 = (Math.ceil(checksum1 / 10.0) * 10.0);
                checkdigitX = parseFloat(checksum2 - checksum1).toString();
            }

            if (checkdigitX == checkdigit) {
                valid = true;
                setCRANumberValid(true);
                msg = "Valid: " + sin + " is a valid SIN format.";
            } else {
                valid = false;
                setCRANumberValid(false);
                msg = "Invalid: " + sin + " - check digit does not pass validation test.";
            }
        }
        /*else {
            msg = "Testing only - SIN validates";
        }*/
        console.log(msg)
        // alert(msg);
        setCRANumberValidation(msg);
        return valid;
    }
    const getTaxByID = (id) => {
        console.log(id);
        TaxAgencyApi.getTaxAgencyByID(localStorage.getItem("cffggr_id"), id).then((resposne) => {
            setEditDataID(resposne.data.id);
            settaxAgencyName(resposne.data.tax_agency_name);
            setCranumber(resposne.data.cra_number);
            setremittanceSchedule(resposne.data.remitter.remitter_type);
            setRemitterID(resposne.data.remitter.id);
            setonClose(!onClose);
        }).catch((error) => {

        })
    }
    return (
        <MainCard title="Tax Agency">
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1.5 },
                }}
                noValidate
                autoComplete="off"
            >


                <Grid container spacing={matchDownSM ? 0 : 2}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            onClick={() => handleClose()
                            }
                            variant="contained"
                            color="secondary"
                        >Add Tax Agency</Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    </Grid>

                </Grid>
                <br />
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Tax Agency</StyledTableCell>
                                <StyledTableCell>CRA Number</StyledTableCell>
                                <StyledTableCell>Remitter</StyledTableCell>

                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {TaxAgencyData.map((data) => (
                                <StyledTableRow sx={{ border: 0 }}>
                                    <StyledTableCell>{data.tax_agency_name}</StyledTableCell>
                                    <StyledTableCell>{data.cra_number}</StyledTableCell>
                                    <StyledTableCell>{data.remitter !== null ? data.remitter.remitter_type : ""}</StyledTableCell>
                                    <StyledTableCell><Edit style={{ marginRight: 5, cursor: "pointer" }} onClick={() => getTaxByID(data.id)} />
                                        {data.is_fixed === true ? <></> :
                                            <Delete style={{ marginLeft: 5, cursor: "pointer" }}
                                                onClick={() => deleteTax(data.id)}
                                            />
                                        }</StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>



            </Box>


            <Dialog
                onClose={handleClose}
                maxWidth={"xl"}
                open={onClose}
                closeAfterTransition>

                <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
                    <Toolbar >

                        <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
                            Tax Agency</Typography>

                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close style={{ color: "black" }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <br />
                <br />
                <br />
                <MainCard>
                    <Formik
                        initialValues={{
                            tax_agency_name: taxAgencyName,
                            remittanceSchedule: remittanceSchedule == "" ? "Select Remitter" : remittanceSchedule,
                            cranumber: Cranumber,
                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            // password: Yup.string().max(255).required('Password is required')
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            if (EditDataID !== 0) {
                                var cdata = {
                                    id: EditDataID,
                                    "tax_agency_name": values.tax_agency_name,
                                    "cra_number": values.cranumber,
                                    "remitter_id": RemitterID,

                                };

                                TaxAgencyApi.UpdateTaxAgency(localStorage.getItem("cffggr_id"), EditDataID, cdata)
                                    .then((response) => {
                                        console.log(response.data)
                                        getTaxAgencyData();
                                        handleClose();
                                        swal("Tax Agency Updated", "", "success");
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            } else {
                                var cdata = {
                                    "tax_agency_name": values.tax_agency_name,
                                    "cra_number": values.cranumber,
                                    "remitter_id": RemitterID,
                                };

                                TaxAgencyApi.AddTaxAgency(localStorage.getItem("cffggr_id"), cdata)
                                    .then((response) => {
                                        console.log(response.data)
                                        getTaxAgencyData();
                                        handleClose();
                                        swal("Tax Agency Added", "", "success");
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }

                            try {
                                if (scriptedRef.current) {
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                }
                            } catch (err) {
                                console.error(err);
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>


                                <Grid container spacing={matchDownSM ? 0 : 2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth error={Boolean(touched.tax_agency_name && errors.tax_agency_name)}>

                                            <TextField
                                                id="outlined-number"
                                                label="Tax Agency"
                                                type="text"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="tax_agency_name"
                                                value={values.tax_agency_name}
                                                onChange={handleChange}

                                            />
                                            {touched.tax_agency_name && errors.tax_agency_name && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.tax_agency_name}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth error={Boolean(touched.cranumber && errors.cranumber)}>
                                            <TextField
                                                id="outlined-number"
                                                label="Account Number"
                                                type="text"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="cranumber"
                                                value={values.cranumber}
                                                onChange={handleChange}
                                            // onChangeCapture={(v) => validateSIN(v.target.value)}
                                            />
                                            {touched.cranumber && errors.cranumber && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.cranumber}
                                                </FormHelperText>
                                            )}
                                            <FormHelperText id="standard-weight-helper-text--register" style={{ color: CRANumberValid ? "green" : "red" }}>
                                                {CRANumberValidation}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={matchDownSM ? 0 : 2}>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl required fullWidth error={Boolean(touched.remittanceSchedule && errors.remittanceSchedule)} sx={{ ...theme.typography.customInput }}>
                                            <Select
                                                required
                                                value={values.remittanceSchedule}
                                                name="remittanceSchedule"
                                                defaultValue='Select Remitter'
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={"Select Remiter"} defaultChecked>Select Remitter</MenuItem>

                                                {RemirrerData.map((data) => {
                                                    return <MenuItem value={data.remitter_type} onClick={() => {
                                                        console.log(data);
                                                        setRemitterID(data.id);
                                                    }}>{data.remitter_type}</MenuItem>;
                                                })}

                                            </Select>

                                            {touched.remitter_type && errors.remitter_type && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.remitter_type}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Save
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </MainCard>
            </Dialog>


        </MainCard>
    )
}

export default TaxAgency;