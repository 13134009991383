import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
// routing
import Routes from 'routes';
// defaultTheme
import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import themes from 'themes';
// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { LoginApi } from 'config/api';
// ==============================|| APP ||============================== //
const App = () => {
    const navigate = useNavigate();

    useEffect(() => {
        LoginApi.checkToken().then((res) => {
            console.log(res.data);
        }).catch(() => {
            console.log('Logout');
            localStorage.clear();
            navigate('/signin');

        });
    }, []);

    const customization = useSelector((state) => state.customization);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
//  <ThemeProvider theme={themes(customization)}>
