import { AppBar, Button, Dialog, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { CompanyApi, DeductionApi, DepartmentApi, EmployeeApi, IncomeApi, IncomeEmployeeApi, JobInfoApi, PayscheduleApi, VacationApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@emotion/react';
import { Close, Delete, Edit } from '@mui/icons-material';

const Income = () => {
    const [paygroupIdget, setpaygroupIdget] = useState(0);
    const [departmentIdget, setdepartmentIdget] = useState(0);

    const [IsIncomeTypeVacation, setIsIncomeTypeVacation] = useState(null);
    const [PolicyCheck, setPolicyCheck] = useState(false);
    const [IncomeTypeId, setIncomeTypeId] = useState(0);
    const [MasterPAyType, setMasterPAyType] = useState("");
    const [MasterDataFrequency, setMasterDataFrequency] = useState([]);
    const [ChargeDepartmentsData, setChargeDepartmentsData] = useState([]);
    const [PaygroupName, setPaygroupName] = useState("");
    const [DepartmentName, setDepartmentName] = useState("");
    const [VacationsData, setVacationsData] = useState([]);
    const [vacatioIdget, setvacatioIdget] = useState(0);
    const [VacationName, setVacationName] = useState("");
    const [IncomeName, setIncomeName] = useState("");
    const [HoursAssocited, setHoursAssocited] = useState("");
    const [Ammount, setAmmount] = useState("");
    const [IncomeEditId, setIncomeEditId] = useState(0);
    const location = useLocation();


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "gray",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const theme = useTheme();
    const [PayTypesName, setPayTypesName] = useState("");
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const scriptedRef = useScriptRef();

    const [onClose, setonClose] = useState(false);
    const handleClose = () => {
        setonClose(!onClose);
    };
    useEffect(() => {
        getEmplyee();
        getEmplyeeJobInformation();
        getIncomeEmployeeData();
        getFrequencyMAsterData();
        // getIncomesMasterData();
        getVPolicyMAsterData();
        getDepartmentData();
    }, [])
    const getEmplyee = () => {

        EmployeeApi.getEmployeesById(localStorage.getItem('cffggr_id'), location.state).then((response) => {
            setPayTypesName(response.data.pay_type.name);
        }).catch((error) => {
            ; console.log(error);
        });
    }
    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    const getEmplyeeJobInformation = () => {

        JobInfoApi.getJobInfoData(localStorage.getItem('cffggr_id'), location.state).then((response) => {
            console.log(response.data);
            console.log(response.data[0].pay_type.name);
            setMasterPAyType(response.data[0].pay_type.name);
            getIncomesMasterData(response.data[0].pay_type.name);
            // setPayTypesName(response.data.pay_type.name);
        }).catch((error) => {
            ; console.log(error);
        });
    }




    const [IncomsMasterData, setIncomsMasterData] = useState([]);
    const getIncomesMasterData = (MasterPAyType) => {
        IncomeApi.GetIncomeMasterDataByPayType(localStorage.getItem("cffggr_id"), location.state, MasterPAyType).then((response) => {
            setIncomsMasterData(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }

    const getFrequencyMAsterData = () => {
        PayscheduleApi.AllPayschedule(localStorage.getItem("cffggr_id")).then((response) => {
            setMasterDataFrequency(response.data);
        }).catch((error) => {

        })
    }

    const getDepartmentData = () => {
        DepartmentApi.AllDepartment(localStorage.getItem("cffggr_id")).then((response) => {
            setChargeDepartmentsData(response.data);
        }).catch((error) => {

        })
    }

    const [IncomeEmployeeData, setIncomeEmployeeData] = useState([]);
    const getIncomeEmployeeData = () => {
        IncomeEmployeeApi.getIncomeEmployeeData(localStorage.getItem("cffggr_id"), location.state).then((response) => {
            setIncomeEmployeeData(response.data);
        }).catch((error) => {

        })
    }

    const deleteIncomeEmployeeData = (id) => {
        IncomeEmployeeApi.deleteIncomeEmployeeData(localStorage.getItem("cffggr_id"), location.state, id).then((response) => {

            getIncomeEmployeeData();
        }).catch((error) => {

        })
    }

    const getVPolicyMAsterData = () => {
        VacationApi.AllVacation(localStorage.getItem("cffggr_id")).then((response) => {
            setVacationsData(response.data);
        }).catch((error) => {

        })
    }

    const getIncomeEmployeeDataNyID = (id) => {
        IncomeEmployeeApi.getIncomeEmployeeDataById(localStorage.getItem("cffggr_id"), location.state, id).then((response) => {
            setIncomeEditId(response.data.id);
            setIncomeTypeId(response.data.income.id);
            setHoursAssocited(response.data.hours_associated);
            setAmmount(response.data.amount);
            setIncomeName(response.data.income.income_name);
            setonClose(!onClose);
        }).catch((error) => {
        })
    }


    return (
        <MainCard>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1.5 },
                }}
                noValidate
                autoComplete="off"
            >
                <Button
                    variant="contained"
                    onClick={() => handleClose()}
                    color="secondary"
                >Add Income</Button>
                <br />
                <br />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Income Type</StyledTableCell>
                                <StyledTableCell>Amount</StyledTableCell>
                                <StyledTableCell>Hours Associated</StyledTableCell>
                                <StyledTableCell>Department</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {IncomeEmployeeData.map((data) => (
                                <>

                                    <StyledTableRow sx={{ border: 0 }}>
                                        <StyledTableCell>{data.income.income_name}</StyledTableCell>
                                        <StyledTableCell>{currencyFormat(data.amount)}</StyledTableCell>
                                        <StyledTableCell>{data.hours_associated}</StyledTableCell>
                                        <StyledTableCell>{data.department !== null ? data.department.department_name : "-"}</StyledTableCell>
                                        <StyledTableCell>
                                            {data.income.income_type.is_fixed === true ? <></> :
                                                <>
                                                    <Edit style={{ marginRight: 5, cursor: "pointer" }} onClick={() => getIncomeEmployeeDataNyID(data.id)} /><Delete style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => deleteIncomeEmployeeData(data.id)} />
                                                </>
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Dialog
                    onClose={handleClose}
                    maxWidth="xl"
                    open={onClose}
                    closeAfterTransition>

                    <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
                        <Toolbar >

                            <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
                                Income</Typography>

                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <Close style={{ color: "black" }} />
                            </IconButton>

                        </Toolbar>
                    </AppBar>

                    <Box
                        sx={{
                            '& .MuiTextField-root': { m: 1.5 },
                        }}
                        style={{ padding: 20 }}
                    >
                        <Formik
                            initialValues={{
                                amount: "",
                                hour_asso: "",
                                income_name: "",
                                income_type: IncomeName == "" ? "" : IncomeName,
                                // pay_groups: PaygroupName == "" ? "select" : PaygroupName,
                                department: DepartmentName == "" ? "select" : DepartmentName,
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                amount: Yup.string().max(255).required('amount is required'),
                                hour_asso: Yup.string().max(255).required('Hours is required'),
                                income_type: Yup.string().max(255).required('Income Type is required'),
                                // pay_groups: Yup.string().max(255).required('Paygroup Type is required'),
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

                                if (IncomeEditId === 0) {
                                    var cdata = null;
                                    if (IsIncomeTypeVacation !== null && IsIncomeTypeVacation === true) {
                                        cdata = {
                                            "income_id": IncomeTypeId,
                                            "amount": parseFloat(values.amount),
                                            "policy_id": vacatioIdget,
                                            "department_id": departmentIdget === 0 ? null : departmentIdget,
                                            "hours_associated": parseFloat(values.hour_asso)
                                        };
                                    } else {
                                        cdata = {
                                            "income_id": IncomeTypeId,
                                            "amount": parseFloat(values.amount),
                                            "department_id": departmentIdget === 0 ? null : departmentIdget,
                                            "hours_associated": parseFloat(values.hour_asso)
                                        };
                                    }
                                    IncomeEmployeeApi.AddIncomeEmployeeData(localStorage.getItem("cffggr_id"), location.state, cdata)
                                        .then((response) => {
                                            console.log(response.data)
                                            getIncomeEmployeeData();
                                            handleClose();
                                            swal("Empolyee Income Added", "", "success");
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                } else {

                                    var cdata = null;
                                    if (IsIncomeTypeVacation !== null && IsIncomeTypeVacation === true) {
                                        cdata = {
                                            "id": IncomeEditId,
                                            "income_id": IncomeTypeId,
                                            "amount": parseFloat(values.amount),
                                            "policy_id": vacatioIdget,
                                            "department_id": departmentIdget,
                                            "hours_associated": parseFloat(values.hour_asso)
                                        };
                                    } else {
                                        cdata = {
                                            "id": IncomeEditId,
                                            "income_id": IncomeTypeId,
                                            "amount": parseFloat(values.amount),
                                            "department_id": departmentIdget,
                                            "hours_associated": parseFloat(values.hour_asso)
                                        };
                                    }

                                    IncomeEmployeeApi.UddateIncomeEmployeeData(localStorage.getItem("cffggr_id"), location.state, IncomeEditId, cdata)
                                        .then((response) => {
                                            console.log(response.data)
                                            getIncomeEmployeeData();
                                            handleClose();
                                            swal("Empolyee Income Added", "", "success");
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }

                                try {
                                    if (scriptedRef.current) {
                                        setStatus({ success: true });
                                        setSubmitting(false);
                                    }
                                } catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <br></br>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>

                                        <Grid item xs={12} sm={6}>
                                            <div>Income Type</div>
                                            <FormControl required fullWidth error={Boolean(touched.income_type && errors.income_type)} sx={{ ...theme.typography.customInput }}>
                                                <Select
                                                    required
                                                    value={values.income_type}
                                                    name="income_type"
                                                    defaultValue='Income Type'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"Income Type"} defaultChecked>Income Type</MenuItem>
                                                    {IncomsMasterData.map((data) => {
                                                        return <MenuItem value={data.income_name} onClick={() => {
                                                            console.log(data);
                                                            setIncomeTypeId(data.id);
                                                            setIncomeName(data.income_name);
                                                            setIsIncomeTypeVacation(data.income_type.is_vacation_pay);
                                                        }}>{data.income_name}</MenuItem>;
                                                    })}

                                                </Select>

                                                {touched.income_type && errors.income_type && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.income_type}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>

                                        {IsIncomeTypeVacation !== null ? IsIncomeTypeVacation === true ? <Grid item xs={12} sm={6}>
                                            <div>Vacation Policy</div>
                                            <FormControl required fullWidth error={Boolean(touched.vacation_type && errors.vacation_type)} sx={{ ...theme.typography.customInput }}>
                                                <Select
                                                    required
                                                    value={values.vacation_type}
                                                    name="vacation_type"
                                                    defaultValue='select'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"select"} defaultChecked>Select Type</MenuItem>
                                                    {VacationsData.map((data) => {
                                                        return <MenuItem value={data.policy_name} onClick={() => {
                                                            setvacatioIdget(data.id);
                                                            setVacationName(data.policy_name);

                                                        }}>{data.policy_name}</MenuItem>
                                                    })}  </Select>
                                                {touched.vacation_type && errors.vacation_type && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.vacation_type}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                        </Grid> : <></> : <></>}

                                    </Grid>
                                    {/* <Grid>
                                        Add as a Policy ? &nbsp;&nbsp;<Switch onChange={(v) => {
                                            setPolicyCheck(!PolicyCheck)
                                        }}
                                            checked={PolicyCheck === true ? true : false}
                                        />
                                        {IsIncomeTypeVacation !== null ? IsIncomeTypeVacation === true ? <Grid item xs={12} sm={6}>
                                            <div>Vacation Policy</div>
                                            <FormControl required fullWidth error={Boolean(touched.vacation_type && errors.vacation_type)} sx={{ ...theme.typography.customInput }}>
                                                <Select
                                                    required
                                                    value={values.vacation_type}
                                                    name="vacation_type"
                                                    defaultValue='select'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"select"} defaultChecked>Select Type</MenuItem>
                                                    {VacationsData.map((data) => {
                                                        return <MenuItem value={data.policy_name} onClick={() => {
                                                            setvacatioIdget(data.id);
                                                            setVacationName(data.policy_name);

                                                        }}>{data.policy_name}</MenuItem>
                                                    })}  </Select>
                                                {touched.vacation_type && errors.vacation_type && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.vacation_type}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                        </Grid> : <></> : <></>}
                                    </Grid> */}
                                    <Grid container spacing={matchDownSM ? 0 : 2}>

                                        <Grid item xs={12} sm={6}>
                                            <div>Department</div>

                                            <FormControl required fullWidth error={Boolean(touched.department && errors.department)} sx={{ ...theme.typography.customInput }}>
                                                <Select
                                                    required
                                                    value={values.pay_type}
                                                    name="department"
                                                    defaultValue='select'
                                                    placeholder='Income Types'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"select"} defaultChecked>Select Department</MenuItem>
                                                    {ChargeDepartmentsData.map((data) => {
                                                        return <MenuItem value={data.department_name} onClick={() => {
                                                            setdepartmentIdget(data.id);
                                                            setDepartmentName(data.department_name);
                                                        }}>{data.department_name}</MenuItem>
                                                    })}
                                                </Select>
                                                {/* {ChargeDepartmentsData.length === 0 ?
                                                    <div style={{ color: "red", marginLeft: 10, fontSize: 13, marginTop: 5 }}>Please add Department First</div>
                                                    : ""}  {touched.department && errors.department && (
                                                       
                                                    )} */}
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.department}
                                                </FormHelperText>
                                            </FormControl>


                                        </Grid>


                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        {/* <Grid item xs={12} sm={6}>
                                            <div>paygroups</div>

                                            <FormControl required fullWidth error={Boolean(touched.pay_groups && errors.pay_groups)} sx={{ ...theme.typography.customInput }}>
                                                <Select
                                                    required
                                                    value={values.pay_groups}
                                                    name="pay_groups"
                                                    defaultValue='select'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"select"} defaultChecked>Paygroup</MenuItem>
                                                    {MasterDataFrequency.map((data) => {
                                                        return <MenuItem value={data.schedule_name} onClick={() => {
                                                            setpaygroupIdget(data.id);
                                                            setPaygroupName(data.schedule_name);
                                                        }}>{data.schedule_name}</MenuItem>
                                                    })}   </Select>
                                                {touched.pay_groups && errors.pay_groups && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.pay_groups}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>


                                        </Grid> */}
                                        <Grid item xs={12} sm={6}>
                                            <div>Hours Associated</div>

                                            <FormControl variant="standard" fullWidth error={Boolean(touched.hour_asso && errors.hour_asso)} sx={{ mt: 1 }}>

                                                {/* <InputLabel htmlFor="outlined-adornment-lastname-register">Hourly Rate</InputLabel> */}
                                                <OutlinedInput
                                                    id="outlined-adornment-lastname-register"
                                                    type="text"
                                                    value={values.hour_asso}
                                                    name="hour_asso"
                                                    placeholder='hours'
                                                    onChange={handleChange}
                                                />

                                                {touched.hour_asso && errors.hour_asso && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.hour_asso}
                                                    </FormHelperText>
                                                )}

                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <div>Amount</div>

                                            <FormControl variant="standard" fullWidth error={Boolean(touched.amount && errors.amount)} sx={{ mt: 1 }}>

                                                <OutlinedInput
                                                    id="outlined-adornment-lastname-register"
                                                    type="text"
                                                    value={values.amount}
                                                    name="amount"
                                                    placeholder='amount'
                                                    onChange={handleChange}
                                                />
                                                {touched.amount && errors.amount && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.amount}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                        </Grid>


                                    </Grid>

                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                    <Box sx={{ mt: 2 }}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Save
                                            </Button>
                                        </AnimateButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>


                </Dialog>
            </Box>
        </MainCard>
    )
}

export default Income;