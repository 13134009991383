// assets
import { IconDashboard, IconHome, IconSmartHome } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconHome, IconSmartHome };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const setting = {
    id: 'setting',
    title: 'Setting',
    type: 'group',
    children: [
        {
            id: 'company',
            title: 'Company',
            type: 'collapse',
            icon: icons.IconSmartHome,
            children: [
                {
                    id: 'company-info',
                    title: 'Company Info',
                    type: 'item',
                    url: '/app-payroll/company/companyinfo',
                    breadcrumbs: false
                },
                {
                    id: 'deduction-types',
                    title: 'Deduction Types',
                    type: 'item',
                    url: '/app-payroll/company/deductiontypes',
                    breadcrumbs: false
                },
                {
                    id: 'income-types',
                    title: 'Income Types',
                    type: 'item',
                    url: '/app-payroll/company/incometypes',

                    breadcrumbs: false
                },
                {
                    id: 'Pay Scheduled',
                    title: 'Pay Schedule',
                    type: 'item',
                    url: '/app-payroll/company/payscheduel',
                    breadcrumbs: false
                },
                {
                    id: 'company-holidays',
                    title: 'Company Holidays',
                    type: 'item',
                    url: '/app-payroll/company/companyholidays',
                    breadcrumbs: false
                },
                {
                    id: 'vacations',
                    title: 'Time-Off Policies',
                    type: 'item',
                    url: '/app-payroll/company/vacations',
                    breadcrumbs: false
                },
                {
                    id: 'taxagency',
                    title: 'Tax Agency',
                    type: 'item',
                    url: '/app-payroll/company/taxagency',
                    breadcrumbs: false
                },
                {
                    id: 'department',
                    title: 'Department',
                    type: 'item',
                    url: '/app-payroll/company/department',
                    breadcrumbs: false
                }
            ]

        },
        {
            id: 'settings',
            title: 'Setting',
            type: 'item',
            url: '/app-payroll/setting',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default setting;
