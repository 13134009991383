import axios from 'axios';

class Api {
    static _axios = null;

    static instance() {
        if (this._axios == null) {
            this._axios = axios.create({
                baseURL: 'http://159.65.156.72:8080/api/',
                // baseURL: 'http://localhost:8080/api/',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json, */*',
                    secure: false,
                    Authorization: localStorage.getItem('token')
                }
            });
        }
        return this._axios;
    }

    static instanceAuth() {
        if (this._axios == null) {
            this._axios = axios.create({
                baseURL: 'http://159.65.156.72:8080/api/',
                // baseURL: 'http://localhost:8080/api/',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json, */*',
                    secure: false,
                }
            });
        }
        return this._axios;
    }
    static toJSON(data) {
        var object = {};
        data.forEach((value, key) => (object[key] = value));
        return object;
    }
}

export class LoginApi {
    static LoginClient(loginData) {
        console.log(loginData);
        return Api.instanceAuth().post('user/login', loginData);
    }
    static allUsersByEmail(query, companyID) {
        console.log(query);
        return Api.instance().get('findusers?input=' + query + "&company_id=" + companyID);
    }

    static LogoutClient() {
        return Api.instance().post('logout');
    }

    static checkToken() {
        return Api.instance().get('token_verify');
    }

}

export class RegisterApi {
    static RegisterClient(loginData, cid, eid) {
        if (cid !== "") {
            // console.log(cid + eid);
            return Api.instanceAuth().post('users?cid=' + cid + '&id=' + eid, loginData);
        } else {
            // console.log("asd")
            return Api.instance().post('users', loginData);
        }
    }
}

export class ProfileApi {
    static GetUserDataByID(id) {
        return Api.instance().get('users/' + id);
    }
    static UpdateUserDataByID(id, data) {
        return Api.instance().put('users/' + id, data);
    }
}





//Company
export class CompanyApi {
    static GetUserCompany() {
        return Api.instance().get('company');
    }
    static GetUserEmployeeCompany() {
        return Api.instance().get('employee/company');
    }
    static RegisterCompany(Data) {
        return Api.instance().post('company', Data);
    }

    static CompanyDatabyId(id) {
        return Api.instance().get('company/' + id);
    }

    static CompanyUpdatebyId(id, Data) {
        return Api.instance().put('company/' + id, Data);
    }

    static DeleteCompany(id) {
        return Api.instance().delete('company/' + id);
    }
}

export class IncomeApi {
    static GetIncomeData(cid) {
        return Api.instance().get('company/' + cid + '/income');
    }

    static RegisterIncome(cid, Data) {
        return Api.instance().post('company/' + cid + '/income', Data);
    }

    static IncomeDataDatabyId(cid, id) {
        return Api.instance().get('company/' + cid + '/income/' + id);
    }

    static IncomeDataUpdatebyId(cid, id, Data) {
        return Api.instance().put('company/' + cid + '/income/' + id, Data);
    }

    static DeleteIncomeData(cid, id) {
        return Api.instance().delete('company/' + cid + '/income/' + id);
    }

    static GetIncomeMasterData() {
        return Api.instance().get('/incomes');
    }
    static GetIncomeMasterDataByPayType(cid, Eid, PayTypes) {
        return Api.instance().get('company/' + cid + '/income?pay_type=' + PayTypes + '&eid=' + Eid);
    }
}

export class ProvincesApi {
    static AllProvinces() {
        return Api.instance().get("provinces")
    }
    static FindProvinces(id) {
        return Api.instance().get("provinces/" + id)
    }
}

export class DeductionApi {
    static AllDeduction(cid) {
        return Api.instance().get('company/' + cid + '/deduction');
    }
    static AllDeductionForEmployee(cid, Eid) {
        return Api.instance().get('company/' + cid + '/deduction?eid=' + Eid);
    }
    static AddDeduction(cid, Data) {
        return Api.instance().post('company/' + cid + '/deduction', Data);
    }

    static FindDeduction(cid, id) {
        return Api.instance().get('company/' + cid + '/deduction/' + id);
    }

    static UpdateDeduction(cid, id, Data) {
        return Api.instance().put('company/' + cid + '/deduction/' + id, Data);
    }

    static DeleteDeduction(cid, id) {
        return Api.instance().delete('company/' + cid + '/deduction/' + id);
    }

    static getDeductionMasterTypesData() {
        return Api.instance().get("deductions")
    }
    static FindDeductionMaster(id) {
        return Api.instance().get("deductions/" + id)
    }
}

export class PayscheduleApi {
    static AllPayschedule(cid) {
        return Api.instance().get('company/' + cid + '/payschedule');
    }
    static AddPayschedule(cid, Data) {
        return Api.instance().post('company/' + cid + '/payschedule', Data);
    }
    static FindPayschedule(cid, id) {
        return Api.instance().get('company/' + cid + '/payschedule/' + id);
    }
    static UpdatePayschedule(cid, id, Data) {
        return Api.instance().put('company/' + cid + '/payschedule/' + id, Data);
    }
    static DeletePayschedule(cid, id) {
        return Api.instance().delete('company/' + cid + '/payschedule/' + id);
    }
    static GetPayGroupMasterData() {
        return Api.instance().get('/paygroups');
    }
    static GetScheduleList(cid, id) {
        return Api.instance().get('company/' + cid + '/schedule_list/' + id);
    }
    static GetScheduleListAll(data) {
        return Api.instance().post('/payschedule', data);
    }
}

export class HolidayApi {
    static AllHoliday(cid) {
        return Api.instance().get('company/' + cid + '/holiday');
    }

    static AddHoliday(cid, Data) {
        return Api.instance().post('company/' + cid + '/holiday', Data);
    }

    static AddHolidaysBulk(cid, data) {
        return Api.instance().post('company/' + cid + '/addholidays', data);
    }
    static DeleteHolidaysBulk(cid) {
        return Api.instance().delete('company/' + cid + '/holiday');
    }

    static FindHoliday(cid, id) {
        return Api.instance().get('company/' + cid + '/holiday/' + id);
    }

    static UpdateHoliday(cid, id, Data) {
        return Api.instance().put('company/' + cid + '/holiday/' + id, Data);
    }

    static DeleteHoliday(cid, id) {
        return Api.instance().delete('company/' + cid + '/holiday/' + id);
    }
}

export class VacationApi {
    static AllVacation(cid) {
        return Api.instance().get('company/' + cid + '/policy');
    }

    static AddVacation(cid, Data) {
        return Api.instance().post('company/' + cid + '/policy', Data);
    }

    static UpdateVacation(cid, id, Data) {
        return Api.instance().put('company/' + cid + '/policy/' + id, Data);
    }
    static GetVacationById(cid, id) {
        return Api.instance().get('company/' + cid + '/policy/' + id);
    }

    static DeleteVacation(cid, id) {
        return Api.instance().delete('company/' + cid + '/policy/' + id);
    }
}

//bank 

export class BanksApi {
    static AllBanks() {
        return Api.instance().get("banks")
    }
    static AddBanks(data) {
        return Api.instance().post("banks", data)
    }
    static FindBanks(id) {
        return Api.instance().get("banks/" + id)
    }
    static UpdateBanks(id, data) {
        return Api.instance().put("banks/" + id, data)
    }
    static DeleteBanks(id) {
        return Api.instance().delete("banks/" + id)
    }
}

export class BranchApi {
    static AllBranch() {
        return Api.instance().get("branch")
    }
    static AddBranch(data) {
        return Api.instance().post("branch", data)
    }
    static FindBranch(id) {
        return Api.instance().get("branch/" + id)
    }
    static FindBranchByBankId(bankid) {
        // return Api.instance().get("banks/" + bankid + "/branch")
        return Api.instance().get("branch?bank_id=" + bankid)
    }
    static UpdateBranch(id, data) {
        return Api.instance().put("branch/" + id, data)
    }
    static DeleteBranch(id) {
        return Api.instance().delete("branch/" + id)
    }
}

export class TaxAgencyApi {
    static AllTaxAgency(cid) {
        return Api.instance().get('company/' + cid + '/taxagency');
    }

    static AddTaxAgency(cid, Data) {
        return Api.instance().post('company/' + cid + '/taxagency', Data);
    }

    static UpdateTaxAgency(cid, id, Data) {
        return Api.instance().put('company/' + cid + '/taxagency/' + id, Data);
    }

    static DeleteTaxAgency(cid, id) {
        return Api.instance().delete('company/' + cid + '/taxagency/' + id);
    }
    static getTaxAgencyByID(cid, id) {
        return Api.instance().get('company/' + cid + '/taxagency/' + id);
    }
}

export class DepartmentApi {
    static AllDepartment(cid) {
        return Api.instance().get('company/' + cid + '/department');
    }

    static AddDepartment(cid, Data) {
        return Api.instance().post('company/' + cid + '/department', Data);
    }

    static UpdateDepartment(cid, id, Data) {
        return Api.instance().put('company/' + cid + '/department/' + id, Data);
    }

    static DeleteDepartment(cid, id) {
        return Api.instance().delete('company/' + cid + '/department/' + id);
    }

    static GetDepartment(cid, id) {
        return Api.instance().get('company/' + cid + '/department/' + id);
    }
}

export class RemitterApi {
    static AllRemitter() {
        return Api.instance().get("remitters")
    }
    static AddRemitter(data) {
        return Api.instance().post("remitters", data)
    }
    static FindRemitter(id) {
        return Api.instance().get("remitters/" + id)
    }
    static UpdateRemitter(id, data) {
        return Api.instance().put("remitters/" + id, data)
    }
    static DeleteRemitter(id) {
        return Api.instance().delete("remitters/" + id)
    }
}


//Employee
export class EmployeeApi {
    static AddEmployee(cid, Data) {
        return Api.instance().post('company/' + cid + '/add_employee', Data);
    }
    static CreateEmployeeInCompany(cid, Data) {

        return Api.instance().post('company/' + cid + '/create_employee', Data);
    }
    static getEmployees(cid) {
        return Api.instance().get('company/' + cid + '/employee');
    }
    static getEmployeesForSeetings(cid) {
        return Api.instance().get('company/' + cid + '/employee/dashboard_access');
    }
    static DeleteEmployee(cid, eid) {
        return Api.instance().delete('company/' + cid + '/employee/' + eid);
    }
    static getContryList() {
        return Api.instance().get('countries');
    }
    static getEmployeesById(cid, uid) {
        return Api.instance().get('company/' + cid + '/employee/' + uid);
    }
    static UpdateEmployeesById(cid, uid, Data) {
        return Api.instance().post('company/' + cid + '/employee/' + uid, Data);
    }
    static UpdateEmployeesByIdForSettings(cid, uid, Data) {
        return Api.instance().post('company/' + cid + '/employee_info/' + uid + "/dashboard_access", Data);
    }
    static SentMailForEmployeeRegistration(cid, eid, emailID) {
        return Api.instance().post('company/' + cid + '/notify_user/' + eid + '?email=' + emailID);
    }
    static ChangeEmployeeStatusdisabled(cid, uid) {
        return Api.instance().get('company/' + cid + '/employee/disabled/' + uid);
    }
    static ChangeEmployeeStatusactivate(cid, uid) {
        return Api.instance().get('company/' + cid + '/employee/activate/' + uid);
    }
    static YearToDateList(cid, eid) {
        return Api.instance().get('company/' + cid + '/yeartodate/' + eid);
    }
}

export class JobInfoApi {
    static AddJobInfoData(cid, eid, Data) {
        return Api.instance().post('company/' + cid + '/job/' + eid, Data);
    }
    static getJobInfoData(cid, eid) {
        return Api.instance().get('company/' + cid + '/job/' + eid);
    }
    static getJobInfoDataById(cid, eid, id) {
        return Api.instance().get('company/' + cid + '/job/' + eid + "/" + id);
    }
    static deleteJobInfoDataById(cid, eid, id) {
        return Api.instance().delete('company/' + cid + '/job/' + eid + "/" + id);
    }
    static updateJobInfoDataById(cid, eid, id, data) {
        return Api.instance().put('company/' + cid + '/job/' + eid + "/" + id, data);
    }
    static AllPayTypes() {
        return Api.instance().get("pay_types")
    }
}

export class TaxEmployeeApi {
    static AddTaxEmployeeData(cid, eid, Data) {
        return Api.instance().post('company/' + cid + '/employee_tax/' + eid, Data);
    }
    static getTaxEmployeeData(cid, eid) {
        return Api.instance().get('company/' + cid + '/employee_tax/' + eid);
    }
}

export class HiringInfoApi {
    static AddHireTermData(cid, eid, Data) {
        return Api.instance().post('company/' + cid + '/hiring/' + eid, Data);
    }
    static UpdateHireTermData(cid, eid, id, Data) {
        return Api.instance().put('company/' + cid + '/hiring/' + eid + "/" + id, Data);
    }
    static getHireTermDataById(cid, eid, id) {
        return Api.instance().get('company/' + cid + '/hiring/' + eid + "/" + id);
    }
    static getHireTermData(cid, eid) {
        return Api.instance().get('company/' + cid + '/hiring/' + eid);
    }
    static deleteHireTermData(cid, eid, id) {
        return Api.instance().delete('company/' + cid + '/hiring/' + eid + "/" + id);
    }
}

export class IncomeEmployeeApi {
    static AddIncomeEmployeeData(cid, eid, Data) {
        return Api.instance().post('company/' + cid + '/employee_income/' + eid, Data);
    }
    static getIncomeEmployeeData(cid, eid) {
        return Api.instance().get('company/' + cid + '/employee_income/' + eid);
    }
    static getIncomeEmployeeDataById(cid, eid, id) {
        return Api.instance().get('company/' + cid + '/employee_income/' + eid + "/" + id);
    }
    static deleteIncomeEmployeeData(cid, eid, id) {
        return Api.instance().delete('company/' + cid + '/employee_income/' + eid + "/" + id);
    }
    static UddateIncomeEmployeeData(cid, eid, id, Data) {
        return Api.instance().put('company/' + cid + '/employee_income/' + eid + "/" + id, Data);
    }

}

export class DeductionEmployeeApi {
    static AddDeductionEmployeeData(cid, eid, Data) {
        return Api.instance().post('company/' + cid + '/employee_deduction/' + eid, Data);
    }
    static getDeductionEmployeeData(cid, eid) {
        return Api.instance().get('company/' + cid + '/employee_deduction/' + eid);
    }
    static getDeductionEmployeeDataById(cid, eid, id) {
        return Api.instance().get('company/' + cid + '/employee_deduction/' + eid + "/" + id);
    }
    static deleteDeductionEmployeeData(cid, eid, id) {
        return Api.instance().delete('company/' + cid + '/employee_deduction/' + eid + "/" + id);
    }
    static UddateDeductionEmployeeData(cid, eid, id, Data) {
        return Api.instance().put('company/' + cid + '/employee_deduction/' + eid + "/" + id, Data);
    }
}

export class ClaimCodesApi {
    static AllClaimCodes() {
        return Api.instance().get("claim_codes")
    }
    static AddClaimCodes(data) {
        return Api.instance().post("claim_codes", data)
    }
    static FindClaimCodes(id) {
        return Api.instance().get("claim_codes/" + id)
    }
    static UpdateClaimCodes(id, data) {
        return Api.instance().put("claim_codes/" + id, data)
    }
    static DeleteClaimCodes(id) {
        return Api.instance().delete("claim_codes/" + id)
    }

    static AllProvincesCodes() {
        return Api.instance().get("provinces_codes")
    }
    static AllProvincesCodesByProvinceId(pId) {
        return Api.instance().get("provinces_codes?province_id=" + pId)
        // return Api.instance().get("provinces/" + pId + "/codes")
    }
    static AddProvincesCodes(data) {
        return Api.instance().post("provinces_codes", data)
    }
    static FindProvincesCodes(id) {
        return Api.instance().get("provinces_codes/" + id)
    }
    static UpdateProvincesCodes(id, data) {
        return Api.instance().put("provinces_codes/" + id, data)
    }
    static DeleteProvincesCodes(id) {
        return Api.instance().delete("provinces_codes/" + id)
    }
}

export class EmployeeBankApi {
    static AddEmployeeBank(cid, eId, Data) {
        return Api.instance().post('company/' + cid + '/employee_bank/' + eId, Data);
    }
    static getEmployeeBankByEmpId(cid, eId) {
        return Api.instance().get('company/' + cid + '/employee_bank/' + eId);
    }
    static getEmployeeBankByBankId(cid, eId, bId) {
        return Api.instance().get('company/' + cid + '/employee_bank/' + eId + "/" + bId);
    }
    static UpdateEmployeeBank(cid, eId, bid, data) {
        return Api.instance().put('company/' + cid + '/employee_bank/' + eId + "/" + bid, data);
    }
    static deleteEmployeeBank(cid, bankid, eId) {
        return Api.instance().delete('company/' + cid + '/employee_bank/' + eId + "/" + bankid);
    }

}

//PayRoll : 
export class PayRollGenrateApi {
    static getEmployeeScheduleWise(cId, pId) {
        return Api.instance().get("company/" + cId + "/payschedule/" + pId + "/employee")
    }

    static getEmployeeScheduleWisae(cId, pId) {
        return Api.instance().get("company/" + cId + "/payschedule/" + pId + "/employee")
    }

    //payroll
    static AddPayroll(cid, Data) {
        return Api.instance().post('company/' + cid + '/payroll', Data);
    }
    static AddPayrollNew(cid, Data) {
        return Api.instance().post('company/' + cid + '/payroll', Data);
    }
    static getAllPayroll(cid, eId) {
        return Api.instance().get('company/' + cid + '/payroll');
    }
    static getPayrollByID(cid, pId) {
        return Api.instance().get('company/' + cid + '/payroll/' + pId);
    }
    static updatePayrollStatus(cid, pId, data) {
        return Api.instance().put('company/' + cid + '/payroll/' + pId, data);
    }
    static DeletePayroll(cid, payrollId) {
        return Api.instance().delete('company/' + cid + '/payroll/' + payrollId);
    }
    static getAllPayrollTypess() {
        return Api.instance().get('payroll_types');
    }




    // Emolyee add
    static AddPayrollEmployees(cid, payrollId, Data) {
        return Api.instance().post('company/' + cid + '/payroll/' + payrollId + "/employee", Data);
    }
    static AddEmployeesInPayroll(cid, payrollId, Data) {
        return Api.instance().post('company/' + cid + '/payroll/' + payrollId + "/add_employees", Data);
    }
    static getAllPayrollEmployees(cid, payrollId) {
        // return Api.instance().get('company/' + cid + '/payroll/' + payrollId + '/employee?payment_type_id=1');
        return Api.instance().get('company/' + cid + '/payroll/' + payrollId + '/employee');
    }
    static editAllPayrollEmployees(cid, payrollId, data) {
        // return Api.instance().put('company/' + cid + '/payroll/' + payrollId + '/employee?payment_type_id=1', data);
        return Api.instance().put('company/' + cid + '/payroll/' + payrollId + '/incomes', data);
    }
    static getInivitualPayrollEmployee(cid, payrollId, ERId) {
        return Api.instance().get('company/' + cid + '/payroll/' + payrollId + "/employee/" + ERId);
    }
    static getPayrollSelectionEmployees(cid, payrollId) {
        return Api.instance().get('company/' + cid + '/payroll/' + payrollId + "/get_employees");
    }

    // Create E Incomes
    static getIncomeEmployees(cid, Eid, payrollId) {
        // return Api.instance().get('company/' + cid + '/payroll/account/' + Eid + "?payment_type_id=1");
        return Api.instance().get('company/' + cid + '/payroll/employee/' + Eid + "/incomes");
    }

    static getDeductionEmployees(cid, Eid, paymenttypeId) {
        // return Api.instance().get('company/' + cid + '/payroll/account/' + Eid + "?payment_type_id=" + paymenttypeId);
        return Api.instance().get('company/' + cid + '/payroll/employee/' + Eid + "/deductions");
    }

    static getDeductionEmployer(cid, Eid, paymenttypeId) {
        // return Api.instance().get('company/' + cid + '/payroll/account/' + Eid + "?payment_type_id=" + paymenttypeId);
        return Api.instance().get('company/' + cid + '/payroll/employee/' + Eid + "/employer_deductions");
    }

    static putIncomeEmployees(cid, Pid, data) {
        return Api.instance().put('company/' + cid + '/payroll/' + Pid + "/incomes", data);
    }

    static getpoliciesEmployee(cid, Eid, paymenttypeId) {
        // return Api.instance().get('company/' + cid + '/payroll/account/' + Eid + "?payment_type_id=" + paymenttypeId);
        return Api.instance().get('company/' + cid + '/payroll/employee/' + Eid + "/policies");
    }
}

//Settings 

export class SettingsApi {
    //roles
    static getRoles(cid) {
        return Api.instance().get('company/' + cid + '/roles');
    }
    static addRoles(cid, data) {
        return Api.instance().post('company/' + cid + '/roles', data);
    }
    static getRoleById(cid, id) {
        return Api.instance().get('company/' + cid + '/roles/' + id);
    }
    static deleteRoles(cid, id) {
        return Api.instance().delete('company/' + cid + '/roles/' + id);
    }
    static EditRoles(cid, id, data) {
        return Api.instance().put('company/' + cid + '/roles/' + id, data);
    }

    //  //permission
    static getPermission(cid) {
        return Api.instance().get('company/' + cid + '/permissions');
    }
    static addPermission(cid, data) {
        return Api.instance().post('company/' + cid + '/permissions', data);
    }
    static getPermissionById(cid, id) {
        return Api.instance().get('company/' + cid + '/permissions?role_id=' + id);
    }
    static deletePermission(cid, id) {
        return Api.instance().delete('company/' + cid + '/permissions/' + id);
    }
    static EditPermission(cid, id, data) {
        return Api.instance().post('company/' + cid + '/permissions?role_id=' + id, data);
    }



    //users
    static getusers(cid) {
        return Api.instance().get('company/' + cid + '/users');
    }
    static addusers(cid) {
        return Api.instance().post('company/' + cid + '/users');
    }
    static getUserById(cid, id) {
        return Api.instance().get('company/' + cid + '/users/' + id);
    }
    static deleteusers(cid, id) {
        return Api.instance().delete('company/' + cid + '/users/' + id);
    }
    static Editusers(cid, id, data) {
        return Api.instance().put('company/' + cid + '/users/' + id, data);
    }
}


//Employee Info 
export class EmployeeInfo {
    static getPayrollEmployee(cid) {
        return Api.instance().get('company/' + cid + '/employees/payroll');
    }
    static getPayrollEmployeeIncomeDeduction(cid, payrollId) {
        return Api.instance().get('company/' + cid + 'employee/payroll/account/' + payrollId);
    }
    static getPayrollEmployeeWorkInfo(cid) {
        return Api.instance().get('company/' + cid + '/employees/jobs');
    }
    static getPayrollEmployeePersonalInfo(cid) {
        return Api.instance().get('company/' + cid + '/employees/info');
    }

}

