import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, ButtonBase, MenuItem, Select } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import logo from "../../../assets/company.jpg";
// assets
import { IconMenu2 } from '@tabler/icons';
import { CompanyApi } from 'config/api';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { IconDashboard, IconHome } from '@tabler/icons';
import { Home, HomeMax, HomeMaxRounded, HomeMini, HomeRounded } from '@mui/icons-material';
const icons = { IconDashboard, IconHome };

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, type }) => {
    const theme = useTheme();
    useEffect(() => {
        gerCData();
        getCdata()
    }, [])
    const navigate = useNavigate();
    const [Data, setData] = useState([]);
    const [CName, setCName] = useState("");
    const [Selectedcompany, setSelectedcompany] = useState("Company");

    const gerCData = () => {
        CompanyApi.CompanyDatabyId(localStorage.getItem(type === "employee" ? "effggr_id" : "cffggr_id")).then((res) => {
            console.log(res.data);
            setSelectedcompany(res.data.company_name)
        }).catch((eror) => {
        })
    }

    const getCdata = () => {
        if (type === "employee") {
            CompanyApi.GetUserEmployeeCompany().then((response) => {
                setData(response.data);
                gerCData();
                // setLoading(false);
            }).catch((error) => {
                // setLoading(false);
            });

        } else {
            CompanyApi.GetUserCompany().then((response) => {
                setData(response.data);
                gerCData();
                // setLoading(false);
            }).catch((error) => {
                // setLoading(false);
            });
        }

    }
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    {/* <LogoSection /> */}
                    <center>
                        <img src={logo} width="40" height={"50"} />
                    </center></Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            {/* <h3>{CName}</h3> */}
            {type === "employee" ? <Select
                required
                value={Selectedcompany}
                name="cname"
                variant='outlined'
                color='secondary'
                style={{ border: "0px solid black", backgroundColor: "transparent" }}
                onChange={(v) => setSelectedcompany(v.target.value)}
            >
                <MenuItem value={"new"} style={{ fontWeight: "bold" }} onClick={() => {
                    navigate('/app/product/company');
                }}>+ Add new Company</MenuItem>
                {Data.map((data) => {
                    return <MenuItem value={data.company_name} onClick={() => {
                        navigate('/app-payroll-employee/home', { state: { id: data.id }, replace: true });
                        localStorage.setItem("effggr_id", data.id);
                        window.location.reload(true)
                    }}>{data.company_name}</MenuItem>;
                })}
            </Select>
                : <Select
                    required
                    value={Selectedcompany}
                    name="cname"
                    variant='outlined'
                    color='secondary'
                    style={{ border: "0px solid black", backgroundColor: "transparent" }}
                    onChange={(v) => setSelectedcompany(v.target.value)}
                >
                    <MenuItem value={"new"} style={{ fontWeight: "bold" }} onClick={() => {
                        navigate('/app/product/company');
                    }}>+ Add new Company</MenuItem>
                    {Data.map((data) => {
                        return <MenuItem value={data.company.company_name} onClick={() => {
                            localStorage.setItem("cffggr_id", data.company.id);
                            navigate('/app-payroll/home', { state: { id: data.company.id }, replace: true });
                            window.location.reload(true)
                        }}>{data.company.company_name}</MenuItem>;
                    })}
                </Select>
            }

            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <Button onClick={() => {
                navigate('/app/product/company');
            }}><HomeRounded /></Button>
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
