import { AppBar, Autocomplete, Button, Checkbox, createFilterOptions, Dialog, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Switch, Table, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import MainCard from 'ui-component/cards/MainCard'
import { CompanyApi, DeductionApi, EmployeeApi, LoginApi, PaygroupApi, RegisterApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import { Link, useNavigate } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@emotion/react';
import { Close, Delete, Edit, Info, Visibility, VisibilityOff } from '@mui/icons-material';

import swal from 'sweetalert';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
function Employee() {

    const [AllUsers, setAllUsers] = useState([]);
    const [Employees, setEmployees] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(true);
    const theme = useTheme();

    const [SelectedEmId, setSelectedEmId] = useState(0);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();

    const googleHandler = async () => {
        console.error('Register');
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    useEffect(() => {
        getEmployies();
    }, []);
    const getUSerData = (query) => {
        setAllUsers([]);
        if (query !== "") {
            LoginApi.allUsersByEmail(query, localStorage.getItem('cffggr_id')).then((response) => {
                console.log(response.data)
                setAllUsers(response.data);
                console.log(AllUsers)
            }).catch((error) => {
            });
        }

    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const [onClose, setonClose] = useState(false);
    const handleClose = () => {
        setonClose(!onClose);
    };

    const [onCloseNewEm, setonCloseNewEm] = useState(false);
    const handleCloseNewEm = () => {
        setonCloseNewEm(!onCloseNewEm);
    };


    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.title,
    });
    const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },
        { title: 'The Godfather: Part II', year: 1974 },
        { title: 'The Dark Knight', year: 2008 },
        { title: '12 Angry Men', year: 1957 },
        { title: "Schindler's List", year: 1993 },
        { title: 'Pulp Fiction', year: 1994 },
        {
            title: 'The Lord of the Rings: The Return of the King',
            year: 2003,
        },
        { title: 'The Good, the Bad and the Ugly', year: 1966 },
        { title: 'Fight Club', year: 1999 },
        {
            title: 'The Lord of the Rings: The Fellowship of the Ring',
            year: 2001,
        },
        {
            title: 'Star Wars: Episode V - The Empire Strikes Back',
            year: 1980,
        },
        { title: 'Forrest Gump', year: 1994 },
        { title: 'Inception', year: 2010 },
        {
            title: 'The Lord of the Rings: The Two Towers',
            year: 2002,
        },
        { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
        { title: 'Goodfellas', year: 1990 },
        { title: 'The Matrix', year: 1999 },
        { title: 'Seven Samurai', year: 1954 },
        {
            title: 'Star Wars: Episode IV - A New Hope',
            year: 1977,
        },
        { title: 'City of God', year: 2002 },
        { title: 'Se7en', year: 1995 },
        { title: 'The Silence of the Lambs', year: 1991 },
        { title: "It's a Wonderful Life", year: 1946 },
        { title: 'Life Is Beautiful', year: 1997 },
        { title: 'The Usual Suspects', year: 1995 },
        { title: 'Léon: The Professional', year: 1994 },
        { title: 'Spirited Away', year: 2001 },
        { title: 'Saving Private Ryan', year: 1998 },
        { title: 'Once Upon a Time in the West', year: 1968 },
        { title: 'American History X', year: 1998 },
        { title: 'Interstellar', year: 2014 },
        { title: 'Casablanca', year: 1942 },
        { title: 'City Lights', year: 1931 },
        { title: 'Psycho', year: 1960 },
        { title: 'The Green Mile', year: 1999 },
        { title: 'The Intouchables', year: 2011 },
        { title: 'Modern Times', year: 1936 },
        { title: 'Raiders of the Lost Ark', year: 1981 },
        { title: 'Rear Window', year: 1954 },
        { title: 'The Pianist', year: 2002 },
        { title: 'The Departed', year: 2006 },
        { title: 'Terminator 2: Judgment Day', year: 1991 },
        { title: 'Back to the Future', year: 1985 },
        { title: 'Whiplash', year: 2014 },
        { title: 'Gladiator', year: 2000 },
        { title: 'Memento', year: 2000 },
        { title: 'The Prestige', year: 2006 },
        { title: 'The Lion King', year: 1994 },
        { title: 'Apocalypse Now', year: 1979 },
        { title: 'Alien', year: 1979 },
        { title: 'Sunset Boulevard', year: 1950 },
        {
            title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
            year: 1964,
        },
        { title: 'The Great Dictator', year: 1940 },
        { title: 'Cinema Paradiso', year: 1988 },
        { title: 'The Lives of Others', year: 2006 },
        { title: 'Grave of the Fireflies', year: 1988 },
        { title: 'Paths of Glory', year: 1957 },
        { title: 'Django Unchained', year: 2012 },
        { title: 'The Shining', year: 1980 },
        { title: 'WALL·E', year: 2008 },
        { title: 'American Beauty', year: 1999 },
        { title: 'The Dark Knight Rises', year: 2012 },
        { title: 'Princess Mononoke', year: 1997 },
        { title: 'Aliens', year: 1986 },
        { title: 'Oldboy', year: 2003 },
        { title: 'Once Upon a Time in America', year: 1984 },
        { title: 'Witness for the Prosecution', year: 1957 },
        { title: 'Das Boot', year: 1981 },
        { title: 'Citizen Kane', year: 1941 },
        { title: 'North by Northwest', year: 1959 },
        { title: 'Vertigo', year: 1958 },
        {
            title: 'Star Wars: Episode VI - Return of the Jedi',
            year: 1983,
        },
        { title: 'Reservoir Dogs', year: 1992 },
        { title: 'Braveheart', year: 1995 },
        { title: 'M', year: 1931 },
        { title: 'Requiem for a Dream', year: 2000 },
        { title: 'Amélie', year: 2001 },
        { title: 'A Clockwork Orange', year: 1971 },
        { title: 'Like Stars on Earth', year: 2007 },
        { title: 'Taxi Driver', year: 1976 },
        { title: 'Lawrence of Arabia', year: 1962 },
        { title: 'Double Indemnity', year: 1944 },
        {
            title: 'Eternal Sunshine of the Spotless Mind',
            year: 2004,
        },
        { title: 'Amadeus', year: 1984 },
        { title: 'To Kill a Mockingbird', year: 1962 },
        { title: 'Toy Story 3', year: 2010 },
        { title: 'Logan', year: 2017 },
        { title: 'Full Metal Jacket', year: 1987 },
        { title: 'Dangal', year: 2016 },
        { title: 'The Sting', year: 1973 },
        { title: '2001: A Space Odyssey', year: 1968 },
        { title: "Singin' in the Rain", year: 1952 },
        { title: 'Toy Story', year: 1995 },
        { title: 'Bicycle Thieves', year: 1948 },
        { title: 'The Kid', year: 1921 },
        { title: 'Inglourious Basterds', year: 2009 },
        { title: 'Snatch', year: 2000 },
        { title: '3 Idiots', year: 2009 },
        { title: 'Monty Python and the Holy Grail', year: 1975 },
    ];
    const navigate = useNavigate();
    function handleRoute(id) {
        navigate('/app-payroll/employee/details', { state: id });
    }

    const AddEmployee = (SelectedEmId) => {
        EmployeeApi.AddEmployee(localStorage.getItem('cffggr_id'), { user_id: SelectedEmId }).then((response) => {
            handleClose();
            swal("Employee added Succesfully", "Wait for comformation from employee to join", "success");
            getEmployies();
        }).catch((error) => {
            // if(error.response.data.message);
            swal(error.response.data.message, "", "warning");
            console.log(error);
        });
    }
    const DeleteEmployee = (Eid) => {
        EmployeeApi.DeleteEmployee(localStorage.getItem('cffggr_id'), Eid).then((response) => {

            swal("Employee Deleted Succesfully", "", "success");
            getEmployies();
        }).catch((error) => {
            // if(error.response.data.message);
            swal(error.response.data.message, "", "warning");
            console.log(error);
        });
    }

    const getEmployies = () => {
        EmployeeApi.getEmployees(localStorage.getItem('cffggr_id')).then((response) => {
            setEmployees(response.data)
        }).catch((error) => {
            console.log(error);
        });
    }
    return (
        <MainCard title="Employee">

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1.5 },
                }}
                noValidate
                autoComplete="off"
            >

                <Button
                    onClick={() => handleClose()
                    }
                    // disabled

                    variant="contained"
                    color="secondary"
                >Add New Employee</Button>
                <br />
                <br />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>Rate Of Pay</StyledTableCell>
                                <StyledTableCell>Vacation</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Employees.map((data) => (
                                <StyledTableRow sx={{ border: 0 }}>
                                    <StyledTableCell>{data.first_name}</StyledTableCell>
                                    <StyledTableCell>{data.email}</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                    <StyledTableCell>Active</StyledTableCell>
                                    <StyledTableCell><Edit style={{ marginRight: 5, cursor: "pointer" }} onClick={() => handleRoute(data.id)} /><Delete style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => DeleteEmployee(data.id)} /></StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>



            </Box>

            <Dialog
                onClose={handleClose}
                open={onClose}
                closeAfterTransition>

                <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
                    <Toolbar >

                        <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
                            Employees</Typography>

                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close style={{ color: "black" }} />
                        </IconButton>

                    </Toolbar>
                </AppBar>
                <MainCard >
                    Username or email address
                    <Autocomplete
                        id="filter-demo"
                        getOptionLabel={(option) => option.first_name}
                        options={AllUsers}
                        onChange={(event, value) => {
                            console.log(event.target.value);
                            console.log(value);
                            setSelectedEmId(value.id);

                        }}
                        onInputChange={(event, newInputValue) => {
                            console.log(newInputValue)
                            getUSerData(newInputValue);
                        }}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        filterOptions={(x) => x}
                        // onSelect={(v) => {
                        //     console.log(v);
                        //     // setSelectedEmId()
                        // }}
                        sx={{ width: 300 }}
                        renderOption={(props, option) => {
                            return (
                                <div style={{ display: 'flex', height: '100%' }} {...props}>
                                    <img src={'http://igenerate.odhavtech.com/profile?text=' + option.first_name}
                                        width={40}
                                        height={40}
                                    />
                                    <div style={{ marginLeft: 20 }}>
                                        <b> {option.first_name}</b><br />
                                        {option.email}
                                    </div>
                                </div>
                            );
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="User name or email" />}
                    />
                    <br></br>

                    <Button
                        onClick={() => AddEmployee(SelectedEmId)}
                        variant="contained"
                        color="secondary"
                    >Add Employee</Button>
                    <center><h5>If Employee is not available<Button color='info' onClick={() => handleCloseNewEm()}>Create new</Button></h5>
                    </center>
                </MainCard>

            </Dialog>


            <Dialog
                onClose={handleCloseNewEm}
                open={onCloseNewEm}
                closeAfterTransition
            >

                <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
                    <Toolbar >

                        <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
                            Create Employee</Typography>

                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                handleCloseNewEm();
                                handleClose();
                            }}
                            aria-label="close"
                        >
                            <Close style={{ color: "black" }} />
                        </IconButton>

                    </Toolbar>
                </AppBar>
                <MainCard>
                    <Formik
                        initialValues={{
                            first_name: "",
                            last_name: "",
                            middle_name: "",

                            email: '',
                            phone: '',
                            password: '',
                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            // password: Yup.string().max(255).required('Password is required'),
                            first_name: Yup.string().max(255).required('FirstName is required'),
                            // phone: Yup.string().max(255).required('Phone Number is required'),
                            // middle_name: Yup.string().max(255).required('Middle Name is required'),

                            last_name: Yup.string().max(255).required('LastName is required'),

                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            console.log("asdasd");
                            EmployeeApi.CreateEmployeeInCompany(localStorage.getItem('cffggr_id'), { first_name: values.first_name, last_name: values.last_name })
                                .then((re) => {
                                    console.log(re.data);
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                    setSelectedEmId(re.data.id);
                                    getEmployies();
                                    // AddEmployee(re.data.id);
                                    handleCloseNewEm();
                                    handleClose();
                                    swal("Employee Added Successfully", "", "success").then((value) => {
                                        handleCloseNewEm();
                                        handleClose();
                                    });;
                                })
                                .catch((err) => {
                                    console.log(err.response.data);
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                });

                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={matchDownSM ? 0 : 2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth error={Boolean(touched.first_name && errors.first_name)} sx={{ ...theme.typography.customInput }}>

                                            <InputLabel htmlFor="outlined-adornment-firstname-register">First Name</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-firstname-register"
                                                type="first_name"
                                                value={values.first_name}
                                                name="first_name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                inputProps={{}}
                                            />
                                            {touched.first_name && errors.first_name && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.first_name}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth error={Boolean(touched.middle_name && errors.middle_name)} sx={{ ...theme.typography.customInput }}>

                                            <InputLabel htmlFor="outlined-adornment-lastname-register">Middle Name</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-lastname-register"
                                                type="text"
                                                value={values.middle_name}
                                                name="middle_name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                inputProps={{}}
                                            />
                                            {touched.middle_name && errors.middle_name && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.middle_name}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={matchDownSM ? 0 : 2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth error={Boolean(touched.last_name && errors.last_name)} sx={{ ...theme.typography.customInput }}>

                                            <InputLabel htmlFor="outlined-adornment-lastname-register">Last Name</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-lastname-register"
                                                type="last_name"
                                                value={values.last_name}
                                                name="last_name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                inputProps={{}}
                                            />
                                            {touched.last_name && errors.last_name && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.last_name}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>

                                            <InputLabel htmlFor="outlined-adornment-email-register">Email Address / Username</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-email-register"
                                                type="email"
                                                value={values.email}
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                inputProps={{}}
                                            />
                                            {touched.email && errors.email && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.email}
                                                </FormHelperText>
                                            )}
                                        </FormControl></Grid>

                                </Grid>
                                <FormControl fullWidth error={Boolean(touched.phone && errors.phone)} sx={{ ...theme.typography.customInput }}>
                                    <InputLabel htmlFor="outlined-adornment-email-register">Phone Number</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-phone-register"
                                        type="number"
                                        value={values.phone}
                                        name="phone"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.phone && errors.phone && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.phone}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                {strength !== 0 && (
                                    <FormControl fullWidth>
                                        <Box sx={{ mb: 2 }}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item>
                                                    <Box
                                                        style={{ backgroundColor: level?.color }}
                                                        sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1" fontSize="0.75rem">
                                                        {level?.label}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </FormControl>
                                )}

                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}

                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Add Employee
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </MainCard>
            </Dialog>
        </MainCard>

    )
}

export default Employee