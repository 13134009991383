import React from 'react'
import MainCard from 'ui-component/cards/MainCard'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AppBar, Button, Dialog, FormControl, IconButton, InputLabel, OutlinedInput, Switch, Toolbar, Typography } from '@mui/material';
import { EmployeeApi } from 'config/api';
import { useState } from 'react';
import { useEffect } from 'react';
import { Close, Mail, MailLock, MailOutline } from '@mui/icons-material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Box } from '@mui/system';
import swal from 'sweetalert';
import { useLocation } from 'react-router';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Roles from './role/roles';
import Users from './users/users';
function Setting() {


    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const location = useLocation();

    const [Employees, setEmployees] = useState([]);
    const [modal, setmodal] = useState(false);
    const [EmployeeID, setEmployeeID] = useState(0);

    const closeOn = () => {
        setmodal(!modal);
    }
    const [emailId, setemailId] = useState("");
    const getEmployies = () => {
        EmployeeApi.getEmployees(localStorage.getItem('cffggr_id')).then((response) => {
            setEmployees(response.data)
        }).catch((error) => {
            console.log(error);
        });
    }
    useEffect(() => {
        getEmployies();
    }, []);
    function Row({ row }) {

        return <><TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                {row.first_name + " " + row.last_name}
            </TableCell>
            <TableCell align="center">{row.email !== null ? row.email : "-"}</TableCell>
            <TableCell align="right"><Switch onChange={(v) => {
                setEmployeeID(row.id);
                if (row.dashboard_access === true) {

                    console.log("asa");

                    if (row.email === null) {
                        closeOn();
                    } else {
                        console.log("Email is Exists");
                        var cdata = {
                            "dashboard_access": false,
                        };
                        EmployeeApi.UpdateEmployeesById(localStorage.getItem('cffggr_id'), row.id, cdata)
                            .then((response) => {
                                getEmployies();
                                console.log(response.data)
                                swal("Permission Denied!", "Employee can not access portal", "success");
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                    }
                } else {
                    if (row.email === null) {
                        closeOn();
                    } else {
                        console.log("");
                        var cdata = {
                            "dashboard_access": true,
                        };
                        EmployeeApi.UpdateEmployeesById(localStorage.getItem('cffggr_id'), row.id, cdata)
                            .then((response) => {
                                console.log(response.data);
                                getEmployies();
                                swal("Permission Granted!", "Employee can access portal.", "success");

                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }

                }

            }}
                // checked={EnableUser}
                checked={row.dashboard_access === true ? true : false}

            />
            </TableCell>
        </TableRow>


        </>
    }
    return (
        <MainCard title="Setting">
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Users" value="1" />
                            <Tab label="Role" value="2" />
                            <Tab label="Dashboard Access" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Users />
                    </TabPanel>
                    <TabPanel value="2">  <Roles /></TabPanel>
                    <TabPanel value="3">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Employee Name</TableCell>
                                        <TableCell align="center">Email</TableCell>
                                        <TableCell align="right">Employee Portal Visibility</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Employees.map((row) => (
                                        <><Row row={row} /></>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                </TabContext>
            </Box>



            <Dialog
                onClose={closeOn}
                open={modal}
                closeAfterTransition>
                <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
                    <Toolbar >
                        <Typography sx={{ ml: 1, flex: 1 }} variant="h4" component="div">
                            Sent Mail For Registeration link</Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={closeOn}
                            aria-label="close"
                        ><Close style={{ color: "black" }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <MainCard >

                    <InputLabel htmlFor="outlined-adornment-firstname-register">Email Id : </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-firstname-register"
                        type="first_name"
                        value={emailId}
                        name="first_name"
                        placeholder='Enter email id'
                        onChange={(v) => setemailId(v.target.value)}
                        inputProps={{}}
                    />
                    <InputLabel htmlFor="outlined-adornment-firstname-register"></InputLabel>
                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    // var cdata = {
                                    //     // "dashboard_access": true,
                                    //     "email": emailId
                                    // };
                                    EmployeeApi.SentMailForEmployeeRegistration(localStorage.getItem('cffggr_id'), EmployeeID, emailId)
                                        .then((response) => {
                                            console.log(response.data)
                                            swal("we have sent the registration link on " + emailId, "", "success");
                                            onclose();
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            swal("we have sent the registration link on " + emailId, "", "success");

                                            onclose();

                                        });
                                }}
                            >
                                <MailOutline /> &nbsp;&nbsp; Sent
                            </Button>
                        </AnimateButton>
                    </Box>
                    <br></br>
                </MainCard>
            </Dialog>
        </MainCard>

    )
}

export default Setting