import { AppBar, Button, Dialog, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Switch, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { CompanyApi, DeductionApi, PayscheduleApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@emotion/react';
import { CheckBox, Close, Delete, Edit, Info } from '@mui/icons-material';
import moment from 'moment';
import Moment from 'react-moment';

// ==============================|| SAMPLE PAGE ||============================== //


const PaySchedule = () => {
  const location = useLocation();
  const [onClose, setonClose] = useState(false);
  const handleClose = () => {
    setonClose(!onClose);
  };
  //Style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const theme = useTheme();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));


  //------------
  const scriptedRef = useScriptRef();

  const [paygroup_name, setpaygroup_name] = useState("")
  const [payroll_frequency, setpayroll_frequency] = useState("Select frequency")
  const [next_paydate, setnext_paydate] = useState("");
  const [end_payp, setend_payp] = useState("");
  const [DataPaySchedule, setDataPaySchedule] = useState([]);
  const [MasterDataFrequency, setMasterDataFrequency] = useState([]);
  const [FrequencyIdget, setFrequencyIdget] = useState([]);
  const [FrequencySelectedNumber, setFrequencySelectedNumber] = useState("");
  const [FrequencySelectedname, setFrequencySelectedname] = useState("");
  const getPaySchedule = () => {
    PayscheduleApi.AllPayschedule(localStorage.getItem("cffggr_id")).then((response) => {
      setDataPaySchedule(response.data);
    })
      .catch((error) => {
      });
  }

  useEffect(() => {
    getPaySchedule();
    getFrequencyMAsterData();
  }, []);




  const getFrequencyMAsterData = () => {
    PayscheduleApi.GetPayGroupMasterData().then((response) => {
      setMasterDataFrequency(response.data);
    }).catch((error) => {

    })
  }

  const [checkis_last_day_of_month, setcheckis_last_day_of_month] = useState(false);
  const [checkis_mid_day_of_month, setcheckis_mid_day_of_month] = useState(false);
  const [ListOfSchedule, setListOfSchedule] = useState([]);
  const [PayDate, setPayDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [editId, seteditId] = useState(0);
  const getEditData = (id) => {
    PayscheduleApi.FindPayschedule(localStorage.getItem("cffggr_id"), id).then((res) => {
      handleClose();
      setPayDate(moment(res.data.pay_date).format('YYYY-MM-DD'));
      setEndDate(moment(res.data.end_date).format('YYYY-MM-DD'));
      setcheckis_last_day_of_month(res.data.is_last_day_of_month);
      setcheckis_mid_day_of_month(res.data.is_mid_day_of_month);
      setpaygroup_name(res.data.schedule_name);
      setpayroll_frequency(res.data.paygroup.frequency);
      setFrequencyIdget(res.data.paygroup.id);
      setFrequencySelectedNumber(res.data.paygroup.number);
      setFrequencySelectedname(res.data.paygroup.frequency);
      getFrequncyList();
    }).catch((err) => { })

  }

  const deletePaySchedule = (id) => {
    PayscheduleApi.DeletePayschedule(localStorage.getItem("cffggr_id"), id).then((res) => {
      getPaySchedule();
      getFrequencyMAsterData();
    }).catch((err) => { })

  };
  const getFrequncyList = () => {
    
    console.log({
      "paygroup_id": FrequencyIdget,
      "pay_date": PayDate,
      "end_date": EndDate,
      "is_last_day_of_month": checkis_last_day_of_month,
      "is_mid_day_of_month": checkis_mid_day_of_month,
    });
    var data = {
      "paygroup_id": FrequencyIdget,
      "pay_date": PayDate,
      "end_date": EndDate,
      "is_last_day_of_month": checkis_last_day_of_month,
      "is_mid_day_of_month": checkis_mid_day_of_month,
    };
    if (FrequencyIdget !== "" && PayDate !== "" && EndDate !== "") {

      PayscheduleApi.GetScheduleListAll(data).then((re) => {
        setListOfSchedule(re.data);
      }).catch((err) => { console.log(err) });

    }

  }
  return (
    <MainCard title="Pay Schedule">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1.5 },
        }}
        noValidate
        autoComplete="off"
      >

        <Button

          onClick={() => handleClose()
          }
          // disabled

          variant="contained"
          color="secondary"
        >Create Pay Schedule</Button>
        <br />
        <br />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell>Employee Location</StyledTableCell> */}
                <StyledTableCell>Payroll Frequency</StyledTableCell>
                <StyledTableCell>Pay Schedule</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {DataPaySchedule.map((data) => (
                <StyledTableRow sx={{ border: 0 }}>
                  {/* <StyledTableCell>{data.employee_location}</StyledTableCell> */}
                  <StyledTableCell>{data.paygroup.frequency}</StyledTableCell>
                  <StyledTableCell>{data.schedule_name}</StyledTableCell>
                  <StyledTableCell><Edit style={{ marginRight: 5, cursor: "pointer" }} onClick={() => {
                    getEditData(data.id);
                    seteditId(data.id);
                  }} /><Delete style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => deletePaySchedule(data.id)} /></StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>



      </Box>


      <Dialog
        onClose={handleClose}
        open={onClose}
        fullScreen
        closeAfterTransition>

        <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
          <Toolbar >

            <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
              Pay Schedule</Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close style={{ color: "black" }} />
            </IconButton>

          </Toolbar>
        </AppBar>
        <div style={{ padding: 20 }}>
          <Formik
            initialValues={{
              paygroup_name: paygroup_name,
              payroll_frequency: payroll_frequency,
              next_paydate: PayDate,
              end_payp: EndDate,
              submit: null
            }}
            validationSchema={Yup.object().shape({

              // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              paygroup_name: Yup.string().max(255).required('Pay Schedule Name is required'),
              payroll_frequency: Yup.string().max(255).required('Frequency is required'),
              end_payp: Yup.string().max(255).required('End of next Pay Period is required'),
              next_paydate: Yup.string().max(255).required('Next Payday is required'),
            })}

            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

              if (editId === 0) {
                var cdata = {
                  schedule_name: values.paygroup_name,
                  paygroup_id: FrequencyIdget,
                  is_last_day_of_month: checkis_last_day_of_month,
                  is_mid_day_of_month: checkis_mid_day_of_month,
                  pay_date: values.next_paydate,
                  end_date: values.end_payp,
                };
                console.log(cdata);
                PayscheduleApi.AddPayschedule(localStorage.getItem("cffggr_id"), cdata)
                  .then((response) => {
                    console.log(response.data)
                    getPaySchedule();
                    handleClose();
                    swal("Pay Schedule Added", "", "success");

                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                var cdata = {
                  schedule_name: values.paygroup_name,
                  paygroup_id: FrequencyIdget,
                  is_last_day_of_month: checkis_last_day_of_month,
                  is_mid_day_of_month: checkis_mid_day_of_month,
                  pay_date: values.next_paydate,
                  end_date: values.end_payp,
                };
                console.log(cdata);
                PayscheduleApi.UpdatePayschedule(localStorage.getItem("cffggr_id"), editId, cdata)
                  .then((response) => {
                    console.log(response.data)
                    getPaySchedule();
                    handleClose();
                    swal("Pay Schedule Updated", "", "success");

                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

              try {
                if (scriptedRef.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
              } catch (err) {
                console.error(err);
                if (scriptedRef.current) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form noValidate onSubmit={handleSubmit}>

                <Grid container spacing={matchDownSM ? 0 : 2}>
                  <Grid item xs={12} sm={4}>

                    <FormControl required fullWidth error={Boolean(touched.payroll_frequency && errors.payroll_frequency)} sx={{ ...theme.typography.customInput }}>
                      <div>Frequency</div>
                      <Select
                        required
                        value={values.payroll_frequency}
                        name="payroll_frequency"
                        defaultValue='Select frequency'
                        onChange={handleChange}
                      >
                        <MenuItem value={"Select frequency"} defaultChecked>Select frequency</MenuItem>
                        {MasterDataFrequency.map((data) => {
                          return <MenuItem value={data.frequency} onClick={() => {
                            setFrequencyIdget(data.id);
                            setFrequencySelectedNumber(data.number);
                            setFrequencySelectedname(data.frequency);
                            // getFrequncyList();
                          }}>{data.frequency}</MenuItem>
                        })}
                      </Select>

                      {touched.payroll_frequency && errors.payroll_frequency && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.payroll_frequency}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <br />
                    <br />
                    <Grid item xs={12} sm={6}>
                      {FrequencySelectedname !== "" ? FrequencySelectedname === "Monthly" ? <div>


                        <Switch onChange={(v) => {
                          setcheckis_last_day_of_month(!checkis_last_day_of_month)
                        }}
                          checked={checkis_last_day_of_month === true ? true : false}
                        />
                        {/* <input
                          type="checkbox"
                          value={checkis_last_day_of_month}
                          onChange={() => setcheckis_last_day_of_month(!checkis_last_day_of_month)}
                        />
                         */}
                        &nbsp;&nbsp;Last day of Month
                      </div> : FrequencySelectedname === "Semi-Monthly" ? <div>
                        <Switch onChange={(v) => {
                          setcheckis_mid_day_of_month(!checkis_mid_day_of_month)
                        }}
                          checked={checkis_mid_day_of_month === true ? true : false}
                        />
                        {/* <input
                          type="checkbox"
                          value={checkis_mid_day_of_month}
                          onChange={() => setcheckis_mid_day_of_month(!checkis_mid_day_of_month)}
                        />
                         */}
                        &nbsp;&nbsp;Mid Month
                      </div> : <></> : <></>}

                    </Grid>
                    <br />
                    <br />
                    <FormControl fullWidth error={Boolean(touched.paygroup_name && errors.paygroup_name)} >

                      <TextField
                        id="outlined-number"
                        type="text"
                        value={values.paygroup_name}
                        name="paygroup_name"
                        label="Pay Schedule name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{}}
                      />
                      {touched.paygroup_name && errors.paygroup_name && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.paygroup_name}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <FormControl fullWidth error={Boolean(touched.end_payp && errors.end_payp)}>

                      {/* <InputLabel>Date</InputLabel> */}
                      <TextField
                        id="outlined-number"
                        label="End of next pay period"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.end_payp}
                        name="end_payp"
                        onChange={handleChange}
                        onChangeCapture={(e) => {
                          setEndDate(e.target.value);
                          values.next_paydate = null;
                          // getFrequncyList();
                        }}

                      />
                      <div style={{ marginLeft: 15 }}>
                        <Moment format="dddd" utc="true">
                          {values.end_payp}
                        </Moment>

                        {/* {moment(values.end_payp).format('dddd')} */}

                      </div>
                      {touched.end_payp && errors.end_payp && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.end_payp}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <FormControl fullWidth error={Boolean(touched.next_paydate && errors.next_paydate)}>

                      {/* <InputLabel>Date</InputLabel> */}
                      <TextField
                        id="outlined-number"
                        label="Next payday"
                        type="date"
                        inputProps={{
                          min: EndDate,
                        }}
                        InputLabelProps={{
                          shrink: true,

                        }}
                        value={values.next_paydate}
                        name="next_paydate"
                        onChange={handleChange}
                        onChangeCapture={(e) => { setPayDate(e.target.value); }}


                      />
                      <div style={{ marginLeft: 15 }}>

                        <Moment format="dddd" utc="true">
                          {values.next_paydate}
                        </Moment>
                        {


                          // moment(values.next_paydate).format('dddd')


                        }</div>
                      {touched.next_paydate && errors.next_paydate && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.next_paydate}
                        </FormHelperText>
                      )}
                    </FormControl>





                  </Grid>
                  <Grid item xs={12} sm={1}>
                  </Grid>
                  <br />
                  <Box sx={{ mt: 2 }}>
                    <AnimateButton>
                      <Button
                        disableElevation
                        disabled={isSubmitting}
                        size="medium"
                        type="submit"
                        variant="contained"
                        color="secondary"
                      >
                        Save
                      </Button>
                    </AnimateButton>
                  </Box>
                  <Grid item xs={12} sm={6}>
                    <center style={{ fontSize: 20 }}><b>  Upcoming Pay Periods <Button onClick={() =>
                      getFrequncyList()
                    }>Show Schedule List</Button>
                    </b> </center>
                    <br />
                    <br />

                    {ListOfSchedule.map((data, i) => {
                      return <div class="payschedulListcontainer" style={{ borderWidth: 1, borderColor: "black", borderRadius: 10, padding: 20 }}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                          <Grid item xs={12} sm={8}>
                            {i + 1}  &nbsp;&nbsp;&nbsp;Pay Period
                            <br />
                            <Moment format="LL" utc="true">
                              {data.start_date}
                            </Moment> -
                            <Moment format="LL" utc="true">
                              {data.end_date}
                            </Moment>
                            {/* {moment(data.start_date).utc(true).format("ll")} - {moment(data.end_date).utc(true).format("ll")} */}
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            Pay Date
                            <br />

                            <Moment format="LL" utc="true">
                              {data.pay_date}
                            </Moment>
                            {/* {moment(data.pay_date).utc(true).format("ll")} */}
                          </Grid>
                        </Grid>

                      </div>
                    })}


                  </Grid>
                </Grid>
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
                <br />

              </form>
            )}
          </Formik>
        </div>
      </Dialog>
    </MainCard >
  );
};

export default PaySchedule;
