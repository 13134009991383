import { AppBar, Button, Dialog, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Table, TableContainer, TableHead, TableRow, TextareaAutosize, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { CompanyApi, DepartmentApi, JobInfoApi, PayscheduleApi, VacationApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
import * as Yup from 'yup';
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import swal from 'sweetalert';
import { Close, Delete, Edit, Info } from '@mui/icons-material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import moment from 'moment';
import Moment from 'react-moment';
const JobInfo = () => {
    const location = useLocation();
    console.log(location.state);
    const theme = useTheme();
    const scriptedRef = useScriptRef();

    const [hourly_rate, sethourly_rate] = useState("")
    const [pay_type, setpay_type] = useState("pay Type")
    const [hourly_per_week, sethourly_per_week] = useState("")
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [idData, setidData] = useState(false);
    const [PaygroupName, setPaygroupName] = useState("");
    const [PayTypeName, setPayTypeName] = useState("");
    const [MasterDataFrequency, setMasterDataFrequency] = useState([]);
    const [MasterPayTypes, setMasterPayTypes] = useState([]);
    const [paygroupIdget, setpaygroupIdget] = useState(0);
    const [EditID, setEditID] = useState(0);
    const [PayTypeSelectedID, setPayTypeSelectedID] = useState(0);
    const [ChargeDepartmentsData, setChargeDepartmentsData] = useState([]);
    const [departmentIdget, setdepartmentIdget] = useState(0);
    const [JobInfoTableData, setJobInfoTableData] = useState([]);
    const [StartDate, setStartDate] = useState("");
    const [JobTitle, setJobTitle] = useState("");
    const [Comments, setComments] = useState("");
    const [DepartmentName, setDepartmentName] = useState("");
    const [onClose, setonClose] = useState(false);
    const [EndDate, setEndDate] = useState("");
    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    const handleClose = () => {
        setonClose(!onClose);
    };
    useEffect(() => {
        getFrequencyMAsterData();
        getJobInfoData();

        getPayTypesMAsterData();
        getDepartmentData();
    }, [])
    const getFrequencyMAsterData = () => {
        PayscheduleApi.AllPayschedule(localStorage.getItem("cffggr_id")).then((response) => {
            setMasterDataFrequency(response.data);
        }).catch((error) => {

        })
    }
    const getDepartmentData = () => {
        DepartmentApi.AllDepartment(localStorage.getItem("cffggr_id")).then((response) => {
            setChargeDepartmentsData(response.data);
        }).catch((error) => {
        })
    }
    const getDataById = (id) => {

        JobInfoApi.getJobInfoDataById(localStorage.getItem('cffggr_id'), location.state, id).then((res) => {
            handleClose();
            sethourly_rate(res.data.amount);
            setEditID(res.data.id);
            setStartDate(moment(res.data.start_date).format('YYYY-MM-DD'));
            setJobTitle(res.data.job_title);
            setpay_type(res.data.pay_type);
            setPayTypeName(res.data.pay_type.name);
            sethourly_per_week(res.data.hours_associated);
            setPaygroupName(res.data.payschedule.schedule_name);
            setpaygroupIdget(res.data.payschedule.id);
            setdepartmentIdget(res.data.department.id);
            setDepartmentName(res.data.department.department_name);
            setComments(res.data.comment);
            setidData(true);
        }).catch((err) => {
            console.log(err);
        });
    }
    const deleteDataById = (id) => {

        JobInfoApi.deleteJobInfoDataById(localStorage.getItem('cffggr_id'), location.state, id).then((res) => {
            getJobInfoData();

        }).catch((err) => {
            console.log(err);
        });
    }
    const getPayTypesMAsterData = () => {
        JobInfoApi.AllPayTypes().then((response) => {
            setMasterPayTypes(response.data);
        }).catch((error) => {

        })
    }
    const getJobInfoData = () => {
        JobInfoApi.getJobInfoData(localStorage.getItem('cffggr_id'), location.state).then((res) => {
            console.log(res.data);

            setJobInfoTableData(res.data);
        }).catch((err) => {
            setidData(true);

        });
    }

    //Styles

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#5E35B1",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (
        <MainCard title="" style={{ marginTop: 20 }}>
            <Box
                sx={{
                    '& .MuiTextField-root': { m: 1.5 },
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => handleClose()}
                    color="secondary"
                >Add Job Info</Button>
                <br />
                <br />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Start Date</StyledTableCell>
                                <StyledTableCell>Job Title</StyledTableCell>
                                <StyledTableCell>Comments</StyledTableCell>
                                <StyledTableCell>Department</StyledTableCell>
                                <StyledTableCell>Amount</StyledTableCell>
                                <StyledTableCell>Hours Associated</StyledTableCell>
                                <StyledTableCell>Pay Schedule</StyledTableCell>
                                <StyledTableCell>Pay Type</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {JobInfoTableData !== null ? JobInfoTableData.map((data) => (
                                <StyledTableRow sx={{ border: 0 }}>
                                    <StyledTableCell>
                                        <Moment format="LL" utc="true">
                                            {data.start_date}
                                        </Moment>

                                        {/* {moment(data.start_date).format('LL')} */}

                                    </StyledTableCell>
                                    <StyledTableCell>{data.job_title}</StyledTableCell>
                                    <StyledTableCell>{data.comment}</StyledTableCell>
                                    <StyledTableCell>{data.department === null ? "-" : data.department.department_name}</StyledTableCell>
                                    <StyledTableCell>{currencyFormat(data.amount)}</StyledTableCell>
                                    <StyledTableCell>{data.hours_associated}</StyledTableCell>
                                    <StyledTableCell>{data.payschedule.schedule_name}</StyledTableCell>
                                    <StyledTableCell>{data.pay_type.name}</StyledTableCell>
                                    <StyledTableCell><Edit style={{ marginRight: 5, cursor: "pointer" }} onClick={() => { getDataById(data.id); }} /><Delete style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => { deleteDataById(data.id); }} /></StyledTableCell>
                                </StyledTableRow>
                            )) : ""}
                        </TableBody>
                    </Table>
                </TableContainer>


                <Dialog
                    onClose={handleClose}
                    maxWidth="xl"
                    open={onClose}
                    closeAfterTransition>
                    <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
                        <Toolbar >

                            <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
                                Job Info</Typography>

                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <Close style={{ color: "black" }} />
                            </IconButton>

                        </Toolbar>
                    </AppBar>
                    <Box
                        sx={{
                            '& .MuiTextField-root': { m: 1.5 },
                        }}
                        style={{ padding: 20 }}
                    >
                        <Formik
                            initialValues={{
                                start_date: StartDate,
                                end_date: EndDate,
                                job_title: JobTitle,
                                comment: Comments,
                                department: DepartmentName == "" ? "select" : DepartmentName,
                                pay_type: PayTypeName == "" ? "" : PayTypeName,
                                pay_groups: PaygroupName == "" ? "" : PaygroupName,
                                amount: hourly_rate,
                                hour_asso: hourly_per_week,
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                job_title: Yup.string().max(255).required('Job Title is required'),
                                amount: Yup.string().max(255).required('Amount is required'),
                                hour_asso: Yup.string().max(255).required('Hours is required'),
                                start_date: Yup.string().max(255).required('Start Date is required'),
                                pay_type: Yup.string().max(255).required('Pay Type is required'),
                                pay_groups: Yup.string().max(255).required('Pay Schedule is required')
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

                                if (EditID == 0) {
                                    var data = {
                                        "start_date": values.start_date,
                                        "job_title": values.job_title,
                                        'end_date': values.end_date !== "" ? values.end_date : null,
                                        "comment": values.comment,
                                        "department_id": departmentIdget === 0 ? null : departmentIdget,
                                        "amount": parseInt(values.amount),
                                        "hours_associated": parseInt(values.hour_asso),
                                        "payschedule_id": paygroupIdget,
                                        "pay_type_id": PayTypeSelectedID,
                                    }
                                    console.log(data);
                                    JobInfoApi.AddJobInfoData(localStorage.getItem('cffggr_id'), location.state, data).then((res) => {
                                        getJobInfoData();
                                        swal("Job Info Updated Successful", "", "success");
                                        sethourly_rate("");
                                        setStartDate("");
                                        setEndDate("");
                                        setJobTitle("");
                                        setpay_type("");
                                        setPayTypeName("");
                                        sethourly_per_week("");
                                        setPaygroupName("");
                                        setpaygroupIdget(0);
                                        setdepartmentIdget(0);
                                        setEditID(0);
                                        setDepartmentName("");
                                        setComments("");
                                        handleClose();
                                    }).catch((err) => { });

                                } else {
                                    var data = {
                                        id: EditID,
                                        "start_date": values.start_date,
                                        "job_title": values.job_title,
                                        'end_date': values.end_date !== "" ? values.end_date : null,
                                        "comment": values.comment,
                                        "department_id": departmentIdget === 0 ? null : departmentIdget,
                                        "amount": parseInt(values.amount),
                                        "hours_associated": parseInt(values.hour_asso),
                                        "payschedule_id": paygroupIdget,
                                        "pay_type_id": PayTypeSelectedID,
                                    }
                                    console.log(data);
                                    JobInfoApi.updateJobInfoDataById(localStorage.getItem('cffggr_id'), location.state, EditID, data).then((res) => {
                                        getJobInfoData();
                                        swal("Job Info Updated Successful", "", "success");
                                        sethourly_rate("");
                                        setEndDate("");
                                        setStartDate("");
                                        setJobTitle("");
                                        setpay_type("");
                                        setPayTypeName("");
                                        sethourly_per_week("");
                                        setPaygroupName("");
                                        setpaygroupIdget(0);
                                        setdepartmentIdget(0);
                                        setEditID(0);
                                        setDepartmentName("");
                                        setComments("");
                                        handleClose();
                                    }).catch((err) => { });
                                }
                                try {
                                    if (scriptedRef.current) {
                                        setStatus({ success: true });
                                        setSubmitting(false);
                                    }
                                } catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={6}>
                                            <div>Start Date</div>

                                            <FormControl variant="standard" fullWidth error={Boolean(touched.start_date && errors.start_date)} sx={{ mt: 1 }}>

                                                {/* <InputLabel htmlFor="outlined-adornment-lastname-register">Hourly Rate</InputLabel> */}
                                                <OutlinedInput
                                                    id="outlined-adornment-lastname-register"
                                                    type="date"
                                                    value={values.start_date}
                                                    name="start_date"
                                                    placeholder='start date'
                                                    onChange={handleChange}
                                                />

                                                {touched.start_date && errors.start_date && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.start_date}
                                                    </FormHelperText>
                                                )}

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div>End Date</div>

                                            <FormControl variant="standard" fullWidth error={Boolean(touched.end_date && errors.end_date)} sx={{ mt: 1 }}>
                                                {/* <InputLabel htmlFor="outlined-adornment-lastname-register">Hourly Rate</InputLabel> */}
                                                <OutlinedInput
                                                    id="outlined-adornment-lastname-register"
                                                    type="date"
                                                    value={values.end_date}
                                                    name="end_date"
                                                    placeholder='Term date'
                                                    onChange={handleChange}
                                                />

                                                {touched.end_date && errors.end_date && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.end_date}
                                                    </FormHelperText>
                                                )}

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div>Job Title</div>

                                            <FormControl variant="standard" fullWidth error={Boolean(touched.job_title && errors.job_title)} sx={{ mt: 1 }}>

                                                <OutlinedInput
                                                    id="outlined-adornment-lastname-register"
                                                    type="text"
                                                    value={values.job_title}
                                                    name="job_title"
                                                    placeholder='Job title'
                                                    onChange={handleChange}
                                                />
                                                {touched.job_title && errors.job_title && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.job_title}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={6}>
                                            <div>Pay Type</div>
                                            <FormControl required fullWidth error={Boolean(touched.pay_type && errors.pay_type)} sx={{ ...theme.typography.customInput }}>
                                                <Select
                                                    required
                                                    value={values.pay_type}
                                                    name="pay_type"
                                                    defaultValue='select'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"select"} defaultChecked>Pay Type</MenuItem>
                                                    {MasterPayTypes.map((data) => {
                                                        return <MenuItem value={data.name} onClick={() => {
                                                            setPayTypeSelectedID(data.id);
                                                            setPayTypeName(data.name);
                                                        }}>{data.name}</MenuItem>
                                                    })}
                                                    {/* <MenuItem value={"Hourly"}>Hourly</MenuItem>
                                                <MenuItem value={"Salary"}>salary</MenuItem> */}
                                                </Select>

                                                {touched.pay_type && errors.pay_type && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.pay_type}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div>Pay Schedule</div>

                                            <FormControl required fullWidth error={Boolean(touched.pay_groups && errors.pay_groups)} sx={{ ...theme.typography.customInput }}>
                                                <Select
                                                    required
                                                    value={values.pay_groups}
                                                    name="pay_groups"
                                                    defaultValue='select'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"select"} defaultChecked>Pay Schedule</MenuItem>
                                                    {MasterDataFrequency.map((data) => {
                                                        return <MenuItem value={data.schedule_name} onClick={() => {
                                                            setpaygroupIdget(data.id);
                                                            setPaygroupName(data.schedule_name);
                                                        }}>{data.schedule_name}</MenuItem>
                                                    })}   </Select>
                                                {touched.pay_groups && errors.pay_groups && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.pay_groups}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={6}>
                                            <div>Hours/Pay</div>

                                            <FormControl variant="standard" fullWidth error={Boolean(touched.hour_asso && errors.hour_asso)} sx={{ mt: 1 }}>

                                                {/* <InputLabel htmlFor="outlined-adornment-lastname-register">Hourly Rate</InputLabel> */}
                                                <OutlinedInput
                                                    id="outlined-adornment-lastname-register"
                                                    type="text"
                                                    value={values.hour_asso}
                                                    name="hour_asso"
                                                    placeholder='hours'
                                                    onChange={handleChange}
                                                />
                                                {touched.hour_asso && errors.hour_asso && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.hour_asso}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <div>{PayTypeName === "Hourly" ? "Rate/ Hour" : PayTypeName === "Salary" ? "Salary/Year" : PayTypeName === "Commission" ? "Commission Amount" : "-"}</div>

                                            <FormControl variant="standard" fullWidth error={Boolean(touched.amount && errors.amount)} sx={{ mt: 1 }}>

                                                <OutlinedInput
                                                    id="outlined-adornment-lastname-register"
                                                    type="text"
                                                    value={values.amount}
                                                    name="amount"
                                                    placeholder='amount'
                                                    onChange={handleChange}
                                                />
                                                {touched.amount && errors.amount && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.amount}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>

                                        <Grid item xs={12} sm={6}>
                                            <div>Department</div>

                                            <FormControl required fullWidth error={Boolean(touched.department && errors.department)} sx={{ ...theme.typography.customInput }}>
                                                <Select
                                                    required
                                                    value={values.department}
                                                    name="department"
                                                    defaultValue='select'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"select"} defaultChecked>Select Department</MenuItem>
                                                    {ChargeDepartmentsData.map((data) => {
                                                        return <MenuItem value={data.department_name} onClick={() => {
                                                            setdepartmentIdget(data.id);
                                                            setDepartmentName(data.department_name);
                                                        }}>{data.department_name}</MenuItem>
                                                    })}
                                                </Select> <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.department}
                                                </FormHelperText>
                                                {/* {ChargeDepartmentsData.length === 0 ?
                                                    <div style={{ color: "red", marginLeft: 10, fontSize: 13, marginTop: 5 }}>Please add Department First From Company Settings</div>
                                                    : ""}  {touched.department && errors.department && (
                                                       
                                                    )} */}
                                            </FormControl>


                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div>Comments</div>
                                            <FormControl variant="standard" fullWidth error={Boolean(touched.comment && errors.comment)} sx={{ mt: 1 }}>
                                                {/* <InputLabel htmlFor="outlined-adornment-lastname-register">Hourly Rate</InputLabel> */}
                                                <TextareaAutosize
                                                    id="outlined-adornment-lastname-register"
                                                    minRows={5}
                                                    value={values.comment}
                                                    name="comment"
                                                    placeholder='comments'
                                                    onChange={handleChange}
                                                />
                                                {touched.comment && errors.comment && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.comment}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>


                                    </Grid>
                                    <br />
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Save
                                        </Button>
                                    </AnimateButton>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Dialog>

            </Box>


        </MainCard >
    )
}

export default JobInfo;

{/* <Grid container spacing={matchDownSM ? 0 : 2}>
                                    <Grid item xs={12} sm={6}>
                                        <div>Hours/Week</div>
                                        <FormControl variant="standard" fullWidth error={Boolean(touched.hourly_per_week && errors.hourly_per_week)} sx={{ mt: 1 }}>
                                            <OutlinedInput
                                                id="outlined-adornment-lastname-register"
                                                type="number"
                                                value={values.hourly_per_week}
                                                name="hourly_per_week"
                                                placeholder='Expected Hours Per Week'
                                                onChange={handleChange}
                                            />

                                            {touched.hourly_per_week && errors.hourly_per_week && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.hourly_per_week}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div>Hourly Rate</div>
                                        <FormControl variant="standard" fullWidth error={Boolean(touched.hourly_rate && errors.hourly_rate)} sx={{ mt: 1 }}>

                                            {/* <InputLabel htmlFor="outlined-adornment-lastname-register">Hourly Rate</InputLabel> */}
                                //             <OutlinedInput
                                //                 id="outlined-adornment-lastname-register"
                                //                 type="number"
                                //                 value={values.hourly_rate}
                                //                 name="hourly_rate"
                                //                 placeholder='Hourly Rate of Pay'
                                //                 startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                //                 onChange={handleChange}
                                //             />
                                //             {touched.hourly_rate && errors.hourly_rate && (
                                //                 <FormHelperText error id="standard-weight-helper-text--register">
                                //                     {errors.hourly_rate}
                                //                 </FormHelperText>
                                //             )}
                                //         </FormControl>

                                //     </Grid>
                                // </Grid> 