import { Alert } from '@mui/material'
import React from 'react'
import MainCard from 'ui-component/cards/MainCard'

function Home() {
    return (
        <MainCard title="Home">
            <Alert severity="error">Please Fill up the Company Information First</Alert>
        </MainCard>

    )
}

export default Home