import { CardHeader, Switch, Tab, Tabs } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard'
import EBankAccount from './fragments/e_bank_accounts';
import EDeduction from './fragments/e_deduction';
import Ehiringinfo from './fragments/e_hiring_info';
import EmployeePersonalDetails from './fragments/e_p_details';
import Income from './fragments/income';
import JobInfo from './fragments/job_info';
import TaxInfo from './fragments/tax_info';
import YTDAmount from './fragments/ytd_amount';
import { EmployeeApi } from 'config/api';
import { useState } from 'react';
import moment from 'moment';

const EditEmployee = () => {
    const [valueTab, setValueTab] = React.useState(0);
    const location = useLocation();

    const [first_name, setfirst_name] = useState("")
    const [middle_name, setmiddle_name] = useState("")
    const [last_name, setlast_name] = useState("")
    const [birth_date, setbirth_date] = useState("")
    const [hire_date, sethire_date] = useState()
    const [SIN, setSIN] = useState("")
    const [term_date, setterm_date] = useState("")
    const [external_id, setexternal_id] = useState("")
    const [address, setaddress] = useState("")
    const [city, setcity] = useState(null)
    const [postal_code, setpostal_code] = useState("")
    const [country, setcountry] = useState("")
    const [phone, setphone] = useState("")
    const [Email, setEmail] = useState("")
    const [PayTypeName, setPayTypeName] = useState("select");
    const [PayTypeSelectedID, setPayTypeSelectedID] = useState(0);
    const [paygroupIdget, setpaygroupIdget] = useState(0);
    const [PaygroupName, setPaygroupName] = useState("select");
    const [CountrySelected, setCountrySelected] = useState("select");
    const [CountryId, setCountryId] = useState(0);
    const handleChangeTab = (event, newValueTab) => {
        // localStorage.setItem("editin", newValueTab);
        setValueTab(newValueTab);
    };

    useEffect(() => {
        getEmplyee();
        // setValueTab(localStorage.getItem("editin"));
    }, [])

    const getData = () => {

    }
    const getEmplyee = () => {

        EmployeeApi.getEmployeesById(localStorage.getItem('cffggr_id'), location.state).then((response) => {

            setphone(response.data.phone);
            setEmail(response.data.email);
            setfirst_name(response.data.first_name);
            setlast_name(response.data.last_name);
            setmiddle_name(response.data.middle_name);
            setSIN(response.data.sin);
            setcity(response.data.city);
            setaddress(response.data.address_1);
            setCountryId(response.data.country.id);
            setCountrySelected(response.data.country.name);
            setbirth_date(moment(response.data.birth_date).format('YYYY-MM-DD'));
            console.log(moment(response.data.birth_date).format('YYYY-MM-DD'));
            setcity(response.data.city);
            setpostal_code(response.data.postal_code);
            setcountry(response.data.country);
            setPaygroupName(response.data.payschedule.schedule_name);
            setpaygroupIdget(response.data.payschedule.id);
            setPayTypeName(response.data.pay_type.name);
            setPayTypeSelectedID(response.data.pay_type.id);
        }).catch((error) => {
            console.log(error);
        });
    }
    return (
        <MainCard>



            <div style={{ fontSize: 18, color: "gray" }}>
                <b>Edit Employee Details : </b>  {first_name}{" "}{last_name}
            </div>
            <div style={{ color: "black", fontWeight: "normal", float: "right" }}>
                <Switch
                    checked={false}
                    // onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </div>
            <br />
            <Tabs
                orientation="horizontal"
                variant="scrollable"
                centered
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                value={valueTab} onChange={handleChangeTab}
            >
                <Tab label="Employee Details" />
                <Tab label="Job Information" />
                {/* <Tab label="Hiring Information" /> */}
                <Tab label="Tax Information" />
                <Tab label="Bank Account" />
                <Tab label="Income" />
                <Tab label="Deduction" />
                <Tab label="YTD Amounts" />
            </Tabs>

            {valueTab == 0 ? <EmployeePersonalDetails /> : valueTab == 1 ? <JobInfo /> : valueTab == 2 ? <TaxInfo /> : valueTab == 3 ? <EBankAccount /> : valueTab == 4 ? <Income /> : valueTab == 5 ? <EDeduction /> : valueTab == 6 ? <YTDAmount /> : <div></div>}
        </MainCard>
    )
}
export default EditEmployee