import { AppBar, Button, Dialog, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Switch, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { CompanyApi, DeductionApi, HolidayApi, PaygroupApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@emotion/react';
import { Close, Delete, Edit, Info } from '@mui/icons-material';
import moment from 'moment';
import Moment from 'react-moment';

// ==============================|| SAMPLE PAGE ||============================== //

const CompanyHolidays = () => {
  const location = useLocation();
  console.log(localStorage.getItem("cffggr_id")
  );
  //Style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const theme = useTheme();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  //--------------
  const [holiday_des, setholiday_des] = useState("");
  const [holiday_date, setholiday_date] = useState("");
  const [editHolidayId, seteditHolidayId] = useState(0);
  const scriptedRef = useScriptRef();
  const [DataCompanyHolidays, setDataCompanyHolidays] = useState([]);

  const getCompany = () => {

    HolidayApi.AllHoliday(localStorage.getItem("cffggr_id")).then((response) => {
      if (response.data === null)
        return null;
      setDataCompanyHolidays(response.data);
    })
      .catch((error) => {
      });
  }

  const deleteDeduction = (id) => {
    console.log(id);
    HolidayApi.DeleteHoliday(localStorage.getItem("cffggr_id"), id).then((resposne) => {
      getCompany();
    }).catch((error) => {

    })
  }
  const deleteDeductionBulk = (id) => {
    console.log(id);
    HolidayApi.DeleteHolidaysBulk(localStorage.getItem("cffggr_id"), id).then((resposne) => {
      swal("All Holidays Deleted", "", "success");

      getCompany();
    }).catch((error) => {

    })
  }
  useEffect(() => {
    getCompany();
  }, []);

  const [onClose, setonClose] = useState(false);
  const handleClose = () => {
    setonClose(!onClose);
  };

  const handleBulkAddData = () => {
    HolidayApi.AddHolidaysBulk(localStorage.getItem("cffggr_id"), {}).then((response) => {
      swal("State Holidays Added", "", "success");
      getCompany();
    }).catch((error) => {

    });
  }

  const editHoliday = (id) => {
    HolidayApi.FindHoliday(localStorage.getItem("cffggr_id"), id).then((res) => {
      handleClose();
      setholiday_des(res.data.holiday_name);
      setholiday_date(res.data.date);
    }).catch((err) => { });
  }
  return (
    <MainCard title="Holidays">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1.5 },
        }}
        noValidate
        autoComplete="off"
      >


        <Grid container spacing={matchDownSM ? 0 : 2}>
          <Grid item xs={12} sm={12}>
            <Button
              onClick={() => {
                handleClose();
                seteditHolidayId(0);
                setholiday_des("");
                setholiday_date("");
              }
              }
              variant="contained"
              color="secondary"
            >Create New Holiday</Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              onClick={() => {
                swal({
                  title: "Arr you sure?",
                  text: "This will delete all Holidays.",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                  .then((willDelete) => {
                    if (willDelete) {
                      deleteDeductionBulk();
                    } else {
                      console.log("Not");
                    }
                  });
              }
              }
              variant="contained"
              color="warning"
            >Remove All Holidays</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              onClick={() => handleBulkAddData()
              }
              variant="contained"
              color="info"
            >Load Stat Holidays</Button>
          </Grid>

        </Grid>
        <br />
        <br />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Holiday Name</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {DataCompanyHolidays.map((data) => (
                <StyledTableRow sx={{ border: 0 }}>
                  <StyledTableCell>{data.holiday_name}</StyledTableCell>
                  <StyledTableCell>
                    <Moment format="LL" utc="true">
                      {data.date}
                    </Moment>

                    {/* {moment(data.date).utc(true).format('LL')} */}

                  </StyledTableCell>
                  <StyledTableCell><Edit style={{ marginRight: 5, cursor: "pointer" }} onClick={() => {
                    editHoliday(data.id);
                    seteditHolidayId(data.id);

                  }} /><Delete style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => deleteDeduction(data.id)} /></StyledTableCell>

                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>



      </Box>


      <Dialog
        onClose={handleClose}

        open={onClose}
        closeAfterTransition>

        <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
          <Toolbar >

            <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
              Holiday</Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close style={{ color: "black" }} />
            </IconButton>

          </Toolbar>
        </AppBar>
        <div style={{ padding: 20 }}>
          <Formik
            initialValues={{
              holiday_des: holiday_des,
              holiday_date: holiday_date === "" ? "" : holiday_date,
              submit: null
            }}
            validationSchema={Yup.object().shape({
              // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              // password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

              if (editHolidayId === 0) {
                var cdata = {
                  holiday_name: values.holiday_des,
                  date: values.holiday_date,
                };
                HolidayApi.AddHoliday(localStorage.getItem("cffggr_id"), cdata)
                  .then((response) => {
                    console.log(response.data)
                    getCompany();
                    handleClose();
                    swal("Holiday Added", "", "success");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                var cdata = {
                  holiday_name: values.holiday_des,
                  date: values.holiday_date,
                };
                HolidayApi.UpdateHoliday(localStorage.getItem("cffggr_id"), editHolidayId, cdata)
                  .then((response) => {
                    console.log(response.data)
                    getCompany();
                    handleClose();
                    swal("Holiday (" + values.holiday_des + ") Edited", "", "success");
                  })
                  .catch((error) => {
                    console.log(error);
                  });

              }
              try {
                if (scriptedRef.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
              } catch (err) {
                console.error(err);
                if (scriptedRef.current) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form noValidate onSubmit={handleSubmit}>


                <Grid container spacing={matchDownSM ? 0 : 2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={Boolean(touched.holiday_des && errors.holiday_des)}>

                      {/* <InputLabel htmlFor="outlined-adornment-lastname-register">Holiday Description</InputLabel> */}
                      <TextField
                        id="outlined-number"
                        label="Holiday Description"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="holiday_des"

                        value={values.holiday_des}
                        onChange={handleChange}

                      />

                      {/* <OutlinedInput
                        id="outlined-adornment-lastname-register"
                        type="text"
                        value={values.holiday_des}
                        name="holiday_des"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      /> */}
                      {touched.holiday_des && errors.holiday_des && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.holiday_des}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth error={Boolean(touched.holiday_date && errors.holiday_date)}>

                      {/* <InputLabel>Date</InputLabel> */}
                      <TextField
                        id="outlined-number"
                        label="Date"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.holiday_date}
                        name="holiday_date"
                        onChange={handleChange}
                      />
                      {touched.holiday_date && errors.holiday_date && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.holiday_date}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>


                </Grid>
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Box sx={{ mt: 2 }}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      Save
                    </Button>
                  </AnimateButton>
                </Box>
              </form>
            )}
          </Formik>
        </div>
      </Dialog>


    </MainCard >
  );


};

export default CompanyHolidays;
