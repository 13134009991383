import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Switch, TextField, Tooltip, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { ClaimCodesApi, CompanyApi, ProvincesApi, TaxEmployeeApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
import * as Yup from 'yup';
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Info } from '@mui/icons-material';
import swal from 'sweetalert';

const TaxInfo = () => {

    const location = useLocation();
    console.log(location.state);
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const [federalTD1, setfederalTD1] = useState("")
    const [federak_claiam_code, setfederak_claiam_code] = useState(0);
    const [ProvincialName, setProvincialName] = useState("");
    const [AddAdditionTax, setAddAdditionTax] = useState(0);
    const [ProvincialClaimCode, setProvincialClaimCode] = useState(0);
    const [provincial_maaster_id, setprovincial_maaster_id] = useState(0);
    const [add_additional_tax, setadd_additional_tax] = useState("Select Type");

    const [withhold_income_tax, setwithhold_income_tax] = useState(false);
    const [cpp, setcpp] = useState(false);
    const [ei, setei] = useState(false);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [TaxType, setTaxType] = React.useState('%');
    const [isLoading, setisLoading] = useState(false);
    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    const [selectedCode, setselectedCode] = useState("");
    const [ProvincesALL, setProvincesALL] = useState([]);
    const [ProvinceID, setProvinceID] = useState(0);
    const [ProvinceName, setProvinceName] = useState("Select Province");
    const handleChangeTax = (event) => {
        setTaxType(event.target.value);
    };


    useEffect(() => {
        getClaimCode();
        getTaxData();
        getProvinceData();
    }, [])

    const [FadrellData, setFadrellData] = useState([]);

    const [ProvincesData, setProvincesData] = useState([]);
    const getClaimCode = () => {
        ClaimCodesApi.AllClaimCodes().then((re) => {
            setFadrellData(re.data);
            console.log(re);
        }).catch((err) => { })


    }

    const getProvinceDataCode = (ProvinceID) => {
        ClaimCodesApi.AllProvincesCodesByProvinceId(ProvinceID)
            .then((response) => {
                setProvincesData(response.data)
            })
            .catch((error) => { });
    }
    const getTaxData = () => {
        setisLoading(false);
        TaxEmployeeApi.getTaxEmployeeData(localStorage.getItem("cffggr_id"), location.state).then((re) => {
            setisLoading(true);
            setProvincialName("Code" + re.data.province_claimcodes.claim_code + " (" + re.data.province_claimcodes.amount_from + " to " + re.data.province_claimcodes.amount_to + ")");
            console.log(ProvincialName);
            setfederalTD1("Code" + re.data.federal_claim_codes.claim_code + "(" + re.data.federal_claim_codes.amount_from + " to " + re.data.federal_claim_codes.amount_to + ")");
            setAddAdditionTax(re.data.additional_tax);
            setcpp(re.data.cpp);
            setei(re.data.ei);
            setProvinceID(re.data.province_id);
            getProvinceDataCode(re.data.province_id);
            setTaxType(re.data.tax_type === "percentage" ? "%" : "$");
            setwithhold_income_tax(re.data.income_tax);
        }).catch((Err) => {
            setisLoading(true);
        });
    }
    const getProvinceData = () => {
        setisLoading(false);
        ProvincesApi.AllProvinces().then((re) => {
            setProvincesALL(re.data);
        }).catch((Err) => {
        });
    }

    return (
        <MainCard title="" style={{ marginTop: 20 }}>
            <Box
                sx={{
                    '& .MuiTextField-root': { m: 1.5 },
                }}

            >
                {isLoading ?
                    <Formik
                        initialValues={{
                            federal_TD1: federalTD1 == "" ? "Federal Claims" : federalTD1,
                            province: ProvincialName == "" ? "provincial" : ProvincialName,
                            add_additional_tax: AddAdditionTax,
                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            // password: Yup.string().max(255).required('Password is required')
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

                            var cdata = {
                                "federal_claim_code": federak_claiam_code,
                                // "federal_code_id": federak_claiam_code,
                                "additional_tax": parseFloat(values.add_additional_tax),
                                "tax_type": TaxType === "%" ? "percentage" : "fixed",
                                "province_id": ProvinceID,
                                "province_claim_code": ProvincialClaimCode,
                                // "province_code_id": ProvincialClaimCode,
                                "income_tax": withhold_income_tax,
                                // "province": ProvinceName,
                                "cpp": cpp,
                                "ei": ei
                            }

                            TaxEmployeeApi.AddTaxEmployeeData(localStorage.getItem("cffggr_id"), location.state, cdata)
                                .then((response) => {
                                    console.log(response.data)
                                    swal("Tax Info Updated", "", "success");
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            try {
                                if (scriptedRef.current) {
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                }
                            } catch (err) {
                                console.error(err);
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <div style={{ color: theme.palette.secondary.dark }}>Province</div>
                                <br></br>


                                <Grid container spacing={matchDownSM ? 0 : 2}>

                                    <Grid item xs={12} sm={6}>
                                        {/* <div>Province</div> */}

                                        <FormControl required fullWidth error={Boolean(touched.province && errors.province)} sx={{ ...theme.typography.customInput }}>

                                            <Select
                                                required
                                                name="province"
                                                value={ProvinceName}
                                                defaultValue='Select Province'
                                                onChange={(e) => setProvinceName(e.target.value)}
                                            >
                                                <MenuItem value={"Select Province"} defaultChecked>Select Province</MenuItem>
                                                {ProvincesALL.map((data) => {
                                                    return <MenuItem value={data.name} onClick={() => {
                                                        console.log(data);
                                                        setProvinceID(data.id);
                                                        getProvinceDataCode(data.id)
                                                    }}>{data.name}</MenuItem>;
                                                })}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}></Grid>

                                </Grid>
                                <br />
                                <div style={{ color: theme.palette.secondary.dark }}>Federal & Provincial Claims</div>

                                <br />
                                <br />
                                <Grid container spacing={matchDownSM ? 0 : 2}>
                                    <Grid item xs={12} sm={6}>
                                        <div>Federal Claims</div>

                                        <FormControl required fullWidth error={Boolean(touched.federal_TD1 && errors.federal_TD1)} sx={{ ...theme.typography.customInput }}>
                                            <Select
                                                required
                                                value={values.federal_TD1}
                                                name="federal_TD1"
                                                // defaultValue='Federal Claims'
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={"Federal Claims"} defaultChecked>Federal Claims</MenuItem>
                                                {FadrellData.map((data) => {
                                                    return <MenuItem value={"Code" + data.claim_code + "(" + data.amount_from + " to " + data.amount_to + ")"} onClick={() => {
                                                        setfederak_claiam_code(data.claim_code);
                                                        setselectedCode("Code " + data.claim_code);
                                                        setfederalTD1("Code" + data.claim_code + " (" + data.amount_from + " to " + data.amount_to + ")");
                                                        // setIncomeTypeId(data.id)
                                                    }}>Code {data.claim_code}&nbsp;<b>{data.amount_from === null ? "( No claim amount )" : " (" + currencyFormat(data.amount_from) + " to " + currencyFormat(data.amount_to) + ")"}</b></MenuItem>;
                                                })}
                                            </Select>
                                            {touched.federal_TD1 && errors.federal_TD1 && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.federal_TD1}
                                                </FormHelperText>
                                            )}
                                        </FormControl>


                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div>Provincial Claims</div>

                                        <FormControl required fullWidth error={Boolean(touched.province && errors.province)} sx={{ ...theme.typography.customInput }}>
                                            <Select
                                                required
                                                value={values.province}
                                                name="province"
                                                // defaultValue='Federal TD1'
                                                disabled={ProvinceID === 0 ? true : false}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={"provincial"} defaultChecked>Provincial</MenuItem>
                                                {ProvincesData.map((data) => {
                                                    return <MenuItem value={"Code" + data.claim_code + " (" + data.amount_from + " to " + data.amount_to + ")"} onClick={() => {
                                                        setProvincialClaimCode(data.claim_code);
                                                        setprovincial_maaster_id(data.province_id);
                                                        setProvincialName("Code" + data.claim_code + " (" + data.amount_from + " to " + data.amount_to + ")");
                                                        // setIncomeTypeId(data.id)
                                                    }}>Code {data.claim_code}&nbsp;<b>{data.amount_from === null ? "( No claim amount )" : " (" + currencyFormat(data.amount_from) + " to " + currencyFormat(data.amount_to) + ")"}</b></MenuItem>;
                                                })}
                                            </Select>
                                            {touched.province && errors.province && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.province}
                                                </FormHelperText>
                                            )}
                                        </FormControl>


                                    </Grid>

                                </Grid>

                                {/* <hr /> */}
                                <br />
                                <Grid container spacing={matchDownSM ? 0 : 2}>
                                    <Grid item xs={12} sm={4}>
                                        <div>Add Additional Tax</div>

                                        <FormControl variant="standard" fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} sx={{ mt: 1 }}>

                                            {/* <InputLabel htmlFor="outlined-adornment-lastname-register">Hourly Rate</InputLabel> */}
                                            <OutlinedInput
                                                id="outlined-adornment-lastname-register"
                                                type="number"
                                                value={values.add_additional_tax}
                                                name="add_additional_tax"
                                                placeholder='additional tax'
                                                onChange={handleChange}
                                            />
                                            {touched.add_additional_tax && errors.add_additional_tax && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.add_additional_tax}
                                                </FormHelperText>
                                            )}
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Tax Type</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                value={TaxType}
                                                onChange={handleChangeTax}
                                            >
                                                <FormControlLabel value="%" control={<Radio />} label="%" />
                                                <FormControlLabel value="$" control={<Radio />} label="$" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <br></br>
                                <div style={{ color: theme.palette.secondary.dark }}>Tax Settings</div>
                                <br></br>
                                <Grid container spacing={matchDownSM ? 0 : 2}>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            Exempt Income Tax</div>
                                        <Switch
                                            checked={withhold_income_tax}
                                            onChange={() => {
                                                if (withhold_income_tax === true) {
                                                    setwithhold_income_tax(false);
                                                } else {
                                                    setwithhold_income_tax(true);
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div>
                                            Exempt From CPP & QPP </div>
                                        <Switch
                                            checked={cpp}

                                            onChange={() => {
                                                if (cpp === true) {
                                                    setcpp(false);
                                                } else {
                                                    setcpp(true);
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Grid>  <Grid item xs={12} sm={4}>
                                        <div>
                                            Exempt From EI </div>
                                        <Switch
                                            checked={ei}
                                            onChange={() => {
                                                if (ei === true) {
                                                    setei(false);
                                                } else {
                                                    setei(true);
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Grid>

                                </Grid>
                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}

                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Save
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                    : ""}
            </Box>

        </MainCard>
    )
}

export default TaxInfo;