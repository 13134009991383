import payroll from './payroll';
import setting from './setting';
import dashboard from './dashboard';
import Other from "./";
import { IconDashboard } from '@tabler/icons';

const icons = { IconDashboard };

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, payroll, setting]
};

export default menuItems;
