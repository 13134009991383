import { AppBar, Button, Dialog, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { BanksApi, BranchApi, CompanyApi, DeductionApi, EmployeeBankApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@emotion/react';
import { Close, Delete, Edit, Info } from '@mui/icons-material';

const EBankAccount = () => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "blueviolet",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const theme = useTheme();

    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [onClose, setonClose] = useState(false);
    const handleClose = () => {
        setonClose(!onClose);
    };

    const location = useLocation();
    console.log(location.state);
    const scriptedRef = useScriptRef();

    const [AccountType, setAccountType] = React.useState("");
    const [BankAccount, setBankAccount] = useState("");
    const [BranchName, setBranchName] = useState("");
    const [AccountNumber, setAccountNumber] = useState("");
    const [BankAccountId, setBankAccountId] = useState(0);
    const handleChangeAccountType = (event) => {
        setAccountType(event.target.value);
    };

    const [AllBankEmployees, setAllBankEmployees] = useState([]);
    useEffect(() => {
        getAllBankEmployee();
        getbanks();
    }, [])

    const [AllBanks, setAllBanks] = useState([]);
    const [AllBranches, setAllBranches] = useState([]);

    const [BrachID, setBrachID] = useState(0);
    const getbanks = () => {
        BanksApi.AllBanks().then((re) => {
            setAllBanks(re.data);
            console.log(re.data)
        }).catch((eerr) => {

        })
    }
    const getbanksBrance = (id) => {
        BranchApi.FindBranchByBankId(id).then((re) => {
            setAllBranches(re.data);
            console.log(re.data)
        }).catch((eerr) => {

        })
    }
    const getAllBankEmployee = () => {
        EmployeeBankApi.getEmployeeBankByEmpId(localStorage.getItem("cffggr_id"), location.state).then((re) => {
            setAllBankEmployees(re.data);
            console.log(re.data)
        }).catch((eerr) => {

        })
    }


    const getBankDetaildEmployeeById = (id) => {
        EmployeeBankApi.getEmployeeBankByBankId(localStorage.getItem("cffggr_id"), location.state, id).then((re) => {
            // setAllBankEmployees(re.data);
            handleClose();
            getbanksBrance(re.data.bank_branch.bank_id);
            setAccountType(re.data.account_type);
            setBankAccount(re.data.bank_branch.bank.bank_name);
            setBranchName(re.data.bank_branch.branch);
            setAccountNumber(re.data.account_number);

        }).catch((eerr) => {

        })
    }
    return (
        <MainCard>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1.5 },
                }}
                noValidate
                autoComplete="off"
            >

                <Button
                    variant="contained"
                    onClick={() => {
                        setBankAccountId(0);
                        setAccountType("");
                        setBankAccount("");
                        setBranchName("");
                        setAccountNumber("");
                        handleClose();
                    }}
                    color="secondary"
                >Add Bank Account</Button>
                <br />
                <br />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Account Type</StyledTableCell>
                                <StyledTableCell>Account Number</StyledTableCell>
                                <StyledTableCell>Bank/Branch</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {AllBankEmployees.map((data) => (
                                <StyledTableRow sx={{ border: 0 }}>
                                    <StyledTableCell>{data.account_type}</StyledTableCell>
                                    <StyledTableCell>{data.account_number}</StyledTableCell>

                                    <StyledTableCell>{data.bank_branch.bank.bank_name}-{data.bank_branch.branch}</StyledTableCell>

                                    <StyledTableCell><Edit style={{ marginRight: 5, cursor: "pointer" }} onClick={() => { getBankDetaildEmployeeById(data.id); setBankAccountId(data.id); }} /><Delete style={{ marginLeft: 5, cursor: "pointer" }} /></StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Dialog
                    onClose={handleClose}
                    maxWidth="xl"
                    open={onClose}
                    closeAfterTransition>

                    <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
                        <Toolbar >

                            <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
                                Account Details</Typography>

                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <Close style={{ color: "black" }} />
                            </IconButton>

                        </Toolbar>
                    </AppBar>

                    <Box
                        sx={{
                            '& .MuiTextField-root': { m: 1.5 },
                        }}
                        style={{ padding: 20 }}
                    >

                        <Formik
                            initialValues={{
                                bank_type: BankAccount == "" ? "bank account" : BankAccount,
                                branch_type: BranchName == "" ? "branch" : BranchName,
                                account_number: AccountNumber == "" ? "" : AccountNumber,
                                account_Type: AccountType == "" ? "cheque" : AccountType,
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                // password: Yup.string().max(255).required('Password is required')
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

                                if (BankAccountId === 0) {
                                    var data = {
                                        "branch_id": BrachID,
                                        "account_number": values.account_number + "",
                                        "account_type": AccountType
                                    }
                                    EmployeeBankApi.AddEmployeeBank(localStorage.getItem("cffggr_id"), location.state, data).then((res) => {
                                        swal("Bank Account Added", "", "success");
                                        getAllBankEmployee();
                                        handleClose();
                                    }).catch((err) => {
                                    });
                                } else {
                                    var data = {
                                        "id": BankAccountId,
                                        "branch_id": BrachID,
                                        "account_number": values.account_number + "",
                                        "account_type": AccountType
                                    }
                                    EmployeeBankApi.UpdateEmployeeBank(localStorage.getItem("cffggr_id"), location.state, BankAccountId, data).then((res) => {
                                        swal("Bank Account Updated", "", "success");
                                        getAllBankEmployee();
                                        handleClose();
                                    }).catch((err) => {
                                    });
                                }




                                try {
                                    if (scriptedRef.current) {
                                        setStatus({ success: true });
                                        setSubmitting(false);
                                    }
                                } catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <br></br>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={6}>
                                            <div>Bank *</div>

                                            <FormControl required fullWidth error={Boolean(touched.bank_type && errors.bank_type)} sx={{ ...theme.typography.customInput }}>
                                                <Select
                                                    required
                                                    value={values.bank_type}
                                                    name="bank_type"
                                                    // defaultValue='Select Type'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"bank account"} defaultChecked>Bank Account</MenuItem>
                                                    {AllBanks.map((data) => {
                                                        return <MenuItem value={data.bank_name} onClick={() => {
                                                            getbanksBrance(data.id);
                                                        }}>{data.bank_name}</MenuItem>
                                                    })}

                                                </Select>

                                                {touched.bank_type && errors.bank_type && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.bank_type}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>


                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div>Branch *</div>
                                            <FormControl required fullWidth error={Boolean(touched.branch_type && errors.branch_type)} sx={{ ...theme.typography.customInput }}>
                                                <Select
                                                    required
                                                    value={values.branch_type}
                                                    name="branch_type"
                                                    // defaultValue='Select Type'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"branch"} defaultChecked>Branch Name</MenuItem>
                                                    {AllBranches.map((data) => {
                                                        return <MenuItem value={data.branch} onClick={() => {
                                                            setBrachID(data.id)
                                                        }}>{data.branch}-{data.transit_code}</MenuItem>
                                                    })}
                                                </Select>

                                                {touched.branch_type && errors.branch_type && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.branch_type}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>


                                        </Grid>

                                    </Grid>
                                    {/* <br></br>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={3}>

                                            <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >

                                                <InputLabel htmlFor="outlined-adornment-lastname-register">Amount</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-lastname-register"
                                                    label="Amount"
                                                    type="text"
                                                    fullWidth
                                                    value={values.add_additional_tax}
                                                    name="add_additional_tax"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{}}
                                                />
                                                {touched.add_additional_tax && errors.add_additional_tax && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.add_additional_tax}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <div>
                                            </div>
                                            <FormControl>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    value={TaxType}
                                                    onChange={handleChangeTax}
                                                >
                                                    <FormControlLabel value="%" control={<Radio />} label="%" />
                                                    <FormControlLabel value="$" control={<Radio />} label="$" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >

                                                <InputLabel htmlFor="outlined-adornment-lastname-register">Account Number</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-lastname-register"
                                                    label="Account Number"
                                                    type="text"
                                                    fullWidth
                                                    value={values.add_additional_tax}
                                                    name="add_additional_tax"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{}}
                                                />
                                                {touched.add_additional_tax && errors.add_additional_tax && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.add_additional_tax}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                        </Grid>

                                    </Grid>
                                    <br></br> */}

                                    <Grid container spacing={matchDownSM ? 0 : 2}>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl variant="standard" fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} sx={{ mt: 1 }}>
                                                <div>Account Number</div>
                                                {/* <InputLabel htmlFor="outlined-adornment-lastname-register">Account Number</InputLabel> */}
                                                <OutlinedInput
                                                    id="outlined-adornment-lastname-register"
                                                    type="number"
                                                    value={values.account_number}
                                                    name="account_number"
                                                    placeholder='Account Number'
                                                    onChange={handleChange}
                                                />
                                                {touched.add_additional_tax && errors.add_additional_tax && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.add_additional_tax}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl>
                                                <FormLabel id="demo-row-radio-buttons-group-label">Account Type</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    value={AccountType}
                                                    onChange={handleChangeAccountType}
                                                >
                                                    <FormControlLabel value="cheque" control={<Radio />} label="Chequing" />
                                                    <FormControlLabel value="account" control={<Radio />} label="Saving Account" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                    <Box sx={{ mt: 2 }}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Save
                                            </Button>
                                        </AnimateButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>


                </Dialog>
            </Box >
        </MainCard >
    )
}

export default EBankAccount