import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import DeductionTypes from 'views/dashboards/company/deduction-types';
import IncomeTypes from 'views/dashboards/company/income-types';
import PayGroups from 'views/dashboards/company/pay-scheduel';
import CompanyHolidays from 'views/dashboards/company/company-holidays';
import Home from 'views/dashboards/home/home';
import Reports from 'views/dashboards/reports/reports';
import Employee from 'views/dashboards/employee/employee';
import Setting from 'views/dashboards/setting/setting';
import EditEmployee from 'views/dashboards/employee/editemployee';
import Vacation from 'views/dashboards/company/vacation';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Payroll = Loadable(lazy(() => import('views/dashboards/payroll/payroll')));
const Report = Loadable(lazy(() => import('views/dashboards/company/company')));
const Company = Loadable(lazy(() => import('views/dashboards/company/company')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    errorElement: <>sdfsd</>,
    children: [
        {
            path: 'dashboard',
            element: <Home />
        },
        {
            path: '/profile/profile',
            element: <Home />
        },
        {
            path: 'company',
            children: [
                {
                    path: 'home',
                    element: <Home />
                },
                {
                    path: 'payroll',
                    element: <Payroll />
                },
                {
                    path: 'report',
                    element: <Reports />
                },
                {
                    path: 'employee',
                    element: <Employee />
                },
                {
                    path: 'employee/details',
                    element: <EditEmployee />
                }
            ]
        },
        {
            path: 'setting',
            children: [
                {
                    path: 'companyinfo',
                    element: <Company />
                },
                {
                    path: 'deductiontypes',
                    element: <DeductionTypes />

                },
                {
                    path: 'incometypes',
                    element: <IncomeTypes />
                },
                {
                    path: 'paygroups',
                    element: <PayGroups />
                },
                {
                    path: 'companyholidays',
                    element: <CompanyHolidays />
                },
                {
                    path: 'vacations',
                    element: <Vacation />
                },
                {
                    path: 'setting',
                    element: <Setting />
                }
            ]
        }
    ]
};

export default MainRoutes;
