import { AppBar, Button, Dialog, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { CompanyApi, DeductionApi, DeductionEmployeeApi, PayscheduleApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@emotion/react';
import { Close, Delete, Edit, Info } from '@mui/icons-material';

const EDeduction = () => {

    const location = useLocation();

    const [employeeTaxType, setemployeeTaxType] = React.useState('%');
    const handleChangeemployeeTax = (event) => {
        setemployeeTaxType(event.target.value);
    };
    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    const [employerTaxType, setemployerTaxType] = React.useState('%');
    const handleChangeemployerTax = (event) => {
        setemployerTaxType(event.target.value);
    };
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "gray",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const theme = useTheme();

    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const scriptedRef = useScriptRef();

    const [onClose, setonClose] = useState(false);
    const handleClose = () => {
        setonClose(!onClose);
    };
    useEffect(() => {
        getDedctionAllData();
        getFrequencyMAsterData();
        getDedctionEmployeeData();
    }, [])

    const [deductionIdget, setdeductionIdget] = useState(0);
    const [deductionName, setdeductionName] = useState("");
    const [DedctionAllData, setDedctionAllData] = useState([]);
    const [MasterDataFrequency, setMasterDataFrequency] = useState([]);
    const [EditId, setEditId] = useState(0);
    const [DedctionEmployeeData, setDedctionEmployeeData] = useState([]);
    const [EmployeeAmount, setEmployeeAmount] = useState(0);
    const [EmployerAmount, setEmployerAmount] = useState(0);
    const getDedctionDataById = (id) => {
        DeductionEmployeeApi.getDeductionEmployeeDataById(localStorage.getItem("cffggr_id"), location.state, id).then((response) => {
            setdeductionName(response.data.deduction.deduction_name);
            setdeductionIdget(response.data.deduction.id);
            setEmployeeAmount(response.data.employee_amount);
            setEmployerAmount(response.data.employer_amount);
            setEditId(response.data.id);
            handleClose();
        }).catch((error) => { })
    }
    const getDedctionAllData = () => {
        DeductionApi.AllDeductionForEmployee(localStorage.getItem("cffggr_id"), location.state).then((response) => {
            if (response.data !== null) {
                setDedctionAllData(response.data);
            }
        }).catch((error) => {

        })
    }
    const getDedctionEmployeeData = () => {
        DeductionEmployeeApi.getDeductionEmployeeData(localStorage.getItem("cffggr_id"), location.state).then((response) => {
            setDedctionEmployeeData(response.data);

        }).catch((error) => {

        })
    }
    const deleteDedctionEmployeeData = (id) => {
        DeductionEmployeeApi.deleteDeductionEmployeeData(localStorage.getItem("cffggr_id"), location.state, id).then((response) => {

            getDedctionEmployeeData();
        }).catch((error) => {

        })
    }
    const getFrequencyMAsterData = () => {
        PayscheduleApi.AllPayschedule(localStorage.getItem("cffggr_id")).then((response) => {
            setMasterDataFrequency(response.data);
        }).catch((error) => {

        })
    }




    return (
        <MainCard>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1.5 },
                }}
                noValidate
                autoComplete="off"
            >
                <Button
                    variant="contained"
                    onClick={() => {
                        handleClose();
                        setdeductionName("");
                        setdeductionIdget(0);
                        setEmployeeAmount(0);
                        setEmployerAmount(0);
                    }}
                    color="secondary"
                >Add Deduction</Button>
                <br />
                <br />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Deduction Type</StyledTableCell>
                                <StyledTableCell>Employee Amount</StyledTableCell>
                                <StyledTableCell>Employer Amount</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {DedctionEmployeeData.map((data) => (
                                <StyledTableRow sx={{ border: 0 }}>
                                    <StyledTableCell>{data.deduction.deduction_name}</StyledTableCell>
                                    <StyledTableCell>{currencyFormat(data.employee_amount)}</StyledTableCell>
                                    <StyledTableCell>{currencyFormat(data.employer_amount)}</StyledTableCell>
                                    <StyledTableCell>
                                        {data.deduction.deduction_type.is_fixed === true ? "" : <><Edit style={{ marginRight: 5, cursor: "pointer" }} onClick={() => getDedctionDataById(data.id)} /><Delete style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => deleteDedctionEmployeeData(data.id)} /></>}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Dialog
                    onClose={handleClose}
                    maxWidth="xl"
                    open={onClose}
                    closeAfterTransition>
                    <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
                        <Toolbar >

                            <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
                                Deduction Type</Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <Close style={{ color: "black" }} />
                            </IconButton>

                        </Toolbar>
                    </AppBar>

                    <Box
                        sx={{
                            '& .MuiTextField-root': { m: 1.5 },
                        }}

                        style={{ padding: 20 }}
                    >
                        <Formik
                            initialValues={{
                                employee_ammount: EmployeeAmount,
                                employer_ammount: EmployerAmount,
                                dedction_name: deductionName,
                                dedction_type: deductionName === "" ? "select" : deductionName,
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                // password: Yup.string().max(255).required('Password is required')
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

                                if (EditId === 0) {
                                    var cdata = {
                                        "deduction_id": deductionIdget,
                                        "employee_amount": values.employee_ammount,
                                        "employee_type": employeeTaxType === "%" ? "percentage" : "fixed",
                                        "employer_amount": values.employer_ammount,
                                        "employer_type": employerTaxType === "%" ? "percentage" : "fixed"
                                    };

                                    DeductionEmployeeApi.AddDeductionEmployeeData(localStorage.getItem("cffggr_id"), location.state, cdata)
                                        .then((response) => {
                                            console.log(response.data)
                                            getDedctionEmployeeData();
                                            handleClose();
                                            swal("Empolyee Deduction Added", "", "success");
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                } else {
                                    var cdata = {
                                        "id": EditId,
                                        "deduction_id": deductionIdget,
                                        "employee_amount": values.employee_ammount,
                                        "employee_type": employeeTaxType === "%" ? "percentage" : "fixed",
                                        "employer_amount": values.employer_ammount,
                                        "employer_type": employerTaxType === "%" ? "percentage" : "fixed"
                                    };
                                    DeductionEmployeeApi.UddateDeductionEmployeeData(localStorage.getItem("cffggr_id"), location.state, EditId, cdata)
                                        .then((response) => {
                                            console.log(response.data)
                                            getDedctionEmployeeData();
                                            handleClose();
                                            swal("Empolyee Deduction Added", "", "success");
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }


                                try {
                                    if (scriptedRef.current) {
                                        setStatus({ success: true });
                                        setSubmitting(false);
                                    }
                                } catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <br></br>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>

                                        <Grid item xs={12} sm={6}>
                                            <div>Deduction</div>
                                            <FormControl required fullWidth error={Boolean(touched.dedction_type && errors.dedction_type)} sx={{ ...theme.typography.customInput }}>
                                                <Select
                                                    required
                                                    value={values.dedction_type}
                                                    name="dedction_type"
                                                    defaultValue='select'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"select"} defaultChecked>Deduction</MenuItem>
                                                    {DedctionAllData.map((data) => {
                                                        return <MenuItem value={data.deduction_name} onClick={() => {
                                                            setdeductionIdget(data.id);
                                                            setdeductionName(data.deduction_name);
                                                        }}>{data.deduction_name}</MenuItem>
                                                    })}   </Select>
                                                {touched.dedction_type && errors.dedction_type && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.dedction_type}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid item xs={12} sm={6}>
                                            <div>paygroups</div>

                                            <FormControl required fullWidth error={Boolean(touched.pay_groups && errors.pay_groups)} sx={{ ...theme.typography.customInput }}>
                                                <Select
                                                    required
                                                    value={values.pay_groups}
                                                    name="pay_groups"
                                                    defaultValue='select'
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"select"} defaultChecked>Paygroup</MenuItem>
                                                    {MasterDataFrequency.map((data) => {
                                                        return <MenuItem value={data.schedule_name} onClick={() => {
                                                            setpaygroupIdget(data.id);
                                                            setPaygroupName(data.schedule_name);
                                                        }}>{data.schedule_name}</MenuItem>
                                                    })}   </Select>
                                                {touched.pay_groups && errors.pay_groups && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.pay_groups}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>


                                        </Grid> */}
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth error={Boolean(touched.employee_ammount && errors.employee_ammount)} >

                                                <TextField
                                                    id="outlined-uncontrolled"
                                                    label="Employee Amount"
                                                    type="number"
                                                    value={values.employee_ammount}
                                                    name="employee_ammount"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{}}
                                                />
                                                {touched.employee_ammount && errors.employee_ammount && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.employee_ammount}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    value={employeeTaxType}
                                                    onChange={handleChangeemployeeTax}
                                                >
                                                    <FormControlLabel value="%" control={<Radio />} label="%" />
                                                    <FormControlLabel value="$" control={<Radio />} label="$" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>

                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth error={Boolean(touched.employer_ammount && errors.employer_ammount)} >
                                                <TextField
                                                    id="outlined-uncontrolled"
                                                    label="Employer Amount"
                                                    type="number"
                                                    value={values.employer_ammount}
                                                    name="employer_ammount"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={{}}
                                                />
                                                {touched.employer_ammount && errors.employer_ammount && (
                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                        {errors.employer_ammount}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    value={employerTaxType}
                                                    onChange={handleChangeemployerTax}
                                                >
                                                    <FormControlLabel value="%" control={<Radio />} label="%" />
                                                    <FormControlLabel value="$" control={<Radio />} label="$" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                    <Box sx={{ mt: 2 }}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Save
                                            </Button>
                                        </AnimateButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Dialog>
            </Box>
        </MainCard>
    )
}

export default EDeduction;