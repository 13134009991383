import { AppBar, Button, Card, Dialog, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Switch, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { CompanyApi, DeductionApi, HolidayApi, PaygroupApi, SettingsApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@emotion/react';
import { Close, Delete, Edit, Info } from '@mui/icons-material';

const Users = () => {
    const location = useLocation();
    console.log(localStorage.getItem("cffggr_id")
    );

    const [EditDataId, setEditDataId] = useState(0);
    const [Name, setName] = useState("")
    const scriptedRef = useScriptRef();
    const [usersData, setusersData] = useState([]);

    const getusersData = () => {
        SettingsApi.getusers(localStorage.getItem("cffggr_id")).then((response) => {
            if (response.data === null)
                return null;
            setusersData(response.data);
        })
            .catch((error) => {
            });
    }

    useEffect(() => {
        getusersData();
    }, []);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const theme = useTheme();

    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [TaxType, setTaxType] = React.useState('%');
    const handleChangeTax = (event) => {
        setTaxType(event.target.value);
    };
    const [onClose, setonClose] = useState(false);
    const handleClose = () => {
        setEditDataId(0);
        setName("");
        setonClose(!onClose);
    };
    const Deleteusers = (id) => {
        console.log(id);
        SettingsApi.Deleteusers(localStorage.getItem("cffggr_id"), id).then((resposne) => {
            getusersData();
        }).catch((error) => {

        })
    }
    const GetusersById = (id) => {
        console.log(id);
        SettingsApi.getusersById(localStorage.getItem("cffggr_id"), id).then((resposne) => {
            setEditDataId(resposne.data.id);
            setName(resposne.data.name);
            setonClose(!onClose);
        }).catch((error) => {
        })
    }
    return (
        <div>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1.5 },
                }}
                noValidate
                autoComplete="off"
            >

                <Grid container spacing={matchDownSM ? 0 : 2}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            onClick={() => handleClose()
                            }
                            variant="contained"
                            color="secondary"
                        >Add users</Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    </Grid>

                </Grid>
                <br />
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Full Name</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usersData.map((data) => (
                                <StyledTableRow sx={{ border: 0 }}>
                                    <StyledTableCell>{data.user.first_name} {data.user.last_name}</StyledTableCell>
                                    <StyledTableCell>{data.user.email}</StyledTableCell>
                                    <StyledTableCell><Edit style={{ marginRight: 5, cursor: "pointer" }} onClick={() => GetusersById(data.id)} /><Delete style={{ marginLeft: 5, cursor: "pointer" }}
                                        onClick={() => Deleteusers(data.id)}
                                    /></StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>



            </Box>
            <Dialog
                onClose={handleClose}
                maxWidth={"xl"}
                open={onClose}
                closeAfterTransition>

                <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
                    <Toolbar >

                        <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
                            users</Typography>

                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close style={{ color: "black" }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <br />
                <br />
                <br />
                <MainCard>
                    <Formik
                        initialValues={{
                            name: Name,

                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            // password: Yup.string().max(255).required('Password is required')
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            if (EditDataId !== 0) {
                                var cdata = {
                                    "id": EditDataId,
                                    "name": values.name,
                                };
                                SettingsApi.Editusers(localStorage.getItem("cffggr_id"), EditDataId, cdata)
                                    .then((response) => {
                                        console.log(response.data)
                                        getusersData();
                                        handleClose();
                                        swal("users Updated", "", "success");
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            } else {
                                var cdata = {
                                    "name": values.name,
                                };
                                console.log(cdata);
                                SettingsApi.addusers(localStorage.getItem("cffggr_id"), cdata)
                                    .then((response) => {
                                        console.log(response.data)
                                        getusersData();
                                        handleClose();
                                        swal("users Added", "", "success");
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }

                            try {
                                if (scriptedRef.current) {
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                }
                            } catch (err) {
                                console.error(err);
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>


                                <Grid container spacing={matchDownSM ? 0 : 2}>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth error={Boolean(touched.name && errors.name)}>

                                            <TextField
                                                id="outlined-number"
                                                label="users"
                                                type="text"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}

                                            />
                                            {touched.name && errors.name && (
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.name}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br />


                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Save
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </MainCard>
            </Dialog>
        </div>
    )
}

export default Users;