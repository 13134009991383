import { useEffect } from 'react';
import "../../assets/style/style.css";
import { gridSpacing } from 'store/constant';
import { SET_MENU } from 'store/actions';
import imgg from "../../assets/payroll1.png";
import ProfileSection from 'layout/MainLayout/Header/ProfileSection';
import { useState } from 'react';
import imgs from '../../assets/companyback2.jpeg';
import { useNavigate, useParams } from 'react-router';
import { Close, CloseFullscreen, CloseRounded, DeleteForever, DockTwoTone, ImportExport, Info, People, PhonelinkLockOutlined, Settings, Warning, WarningAmber, WarningRounded } from '@mui/icons-material';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Skeleton,
    Stack,
    Step,
    StepButton,
    Stepper,
    Tab,
    Tabs,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

import swal from 'sweetalert';
import { CompanyApi, LoginApi, RegisterApi } from 'config/api';
// import moment from 'moment/moment';
import Moment from 'react-moment';
import { TabContext, TabList, TabPanel } from '@mui/lab';
const steps = ['Company Info', 'Company Holidays', 'Income Types', 'Pay Groups', 'Deduction Types'];
const Comapany = () => {
    const navigate = useNavigate();
    const [Data, setData] = useState([]);
    const [EData, setEData] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [Loading1, setLoading1] = useState(true);
    const [onClose, setonClose] = useState(false);


    const [DeleteID, setDeleteID] = useState(0);
    const [DeletePName, setDeletePName] = useState("");
    const [AddNewComanyModal, setAddNewComanyModal] = useState(false);
    const handleAddCompanyClose = () => {
        setAddNewComanyModal(!AddNewComanyModal);
    };

    const [DeleteModal, setDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => {
        setDeleteModal(!DeleteModal);
    };
    const handleClose = () => {
        setonClose(!onClose);
    };
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    //Company
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpenManuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const handleCompanyAdd = (cname) => {
        CompanyApi.RegisterCompany({ company_name: cname })
            .then((re) => {
                console.log(re.data);
                swal("Company Created Successful", "", "success");
                handleAddCompanyClose();
                getCdata();
            })
            .catch((err) => {
                console.log(err.response.data);
            });
    };

    const deleteCompany = (id) => {
        CompanyApi.DeleteCompany(id)
            .then((re) => {
                console.log(re.data);
                swal("Company Deleted Successful", "", "success");
                getCdata();
            })
            .catch((err) => {
                console.log(err.response.data);
            });
    }

    useEffect(() => {
        getCdata();
    }, []);

    const getCdata = () => {
        CompanyApi.GetUserCompany().then((response) => {
            setData(response.data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        });
        CompanyApi.GetUserEmployeeCompany().then((response) => {
            setEData(response.data);
            setLoading1(false);
        }).catch((error) => {
            setLoading1(false);
        });
    }
    const handleDeleteC = (id) => {
        CompanyApi.DeleteCompany(id).then((respone) => {
            swal("Company Added", "", "warning");
        }).catch((error) => {

        });
    }

    const handleClick = (id, role) => {
        console.log('Sds');
        if (role == "employee") {
            localStorage.setItem("effggr_id", id);
            // localStorage.setItem("cffggr_id", id);
            navigate('/app-payroll-employee', { state: { id: id } });
        } else {
            localStorage.setItem("cffggr_id", id);
            navigate('/app-payroll/home', { state: { id: id } });

        }
    };

    return (
        <div style={{
            backgroundColor: 'white',
            backgroundImage: `url(${imgs})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',
        }}>
            <Toolbar>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ flexGrow: 1 }} style={{ fontSize: 20, color: "black" }}>
                    Company
                </Box>
                <ProfileSection />
            </Toolbar>
            <Grid container justifyContent="center" alignItems="center" alignContent={"center"} textAlign={"center"} alignSelf={"center"} style={{ padding: 20 }}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="My Company" value="1" />
                                <Tab label="My Employment" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1"> <Grid item xs={12}>

                            <Grid container spacing={gridSpacing}>
                                <Grid item lg={3} md={6} sm={6} xs={12} onClick={() => handleAddCompanyClose()}>
                                    <Box class="card">
                                        <div class="cardnew">
                                            <center >+ Add Company</center>
                                        </div>
                                    </Box>
                                </Grid>
                                {Loading ? <> {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => {
                                    return <Grid item lg={3} md={6} sm={6} xs={12} >
                                        <Box class="card" justifyContent={"space-between"} display={"inline-block"}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}>
                                                <Skeleton variant="circular" height={35} width={35} />
                                                <div class="">

                                                    <Skeleton variant="circular" height={35} width={35} />
                                                </div>
                                            </Box>
                                            <div class="cardCName" style={{ color: theme.palette.secondary.dark }}>
                                                <Skeleton variant="text" height={35} width={260} />
                                            </div>
                                            <Skeleton variant="text" height={35} width={260} />
                                        </Box>

                                    </Grid>
                                })}</> : <> {Data.length != 0 ? Data.map((dataa, i) => {
                                    return (
                                        <Grid item lg={3} md={6} sm={6} xs={12} >
                                            <Box class="card" justifyContent={"space-between"} display={"inline-block"} style={{ backgroundColor: dataa["role"]["name"] == "User" ? "#f7f7f7" : "white" }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}>
                                                    <Tooltip title={dataa["role"]["name"] == "User" ? "You are Employer Of this Company" : "You can manage this company"}>
                                                        <Info />
                                                    </Tooltip>
                                                    {dataa["role"]["name"] == "User" ? <></> : <div class="">

                                                        <Avatar
                                                            variant="rounded"
                                                            sx={{
                                                                ...theme.typography.commonAvatar,
                                                                ...theme.typography.mediumAvatar,
                                                                backgroundColor: theme.palette.secondary.dark,
                                                                color: theme.palette.secondary[200],
                                                                zIndex: 1
                                                            }}
                                                            aria-controls="menu-earning-card"
                                                            aria-haspopup="true"
                                                            onClick={(e) => {
                                                                handleOpenManuClick(e)
                                                                console.log(dataa["company"]["company_name"])
                                                                setDeletePName(dataa["company"]["company_name"]);
                                                                setDeleteID(dataa["company"]["id"]);
                                                            }}
                                                        >
                                                            <Settings fontSize="inherit" style={{}} />
                                                        </Avatar>
                                                        <Menu
                                                            id="menu-earning-card"
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleCloseMenu}
                                                            variant="selectedMenu"
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right'
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right'
                                                            }}
                                                        >
                                                            {/* <MenuItem onClick={handleCloseMenu}>
                                    <People sx={{ mr: 1.75 }} /> Add User
                                </MenuItem> */}

                                                            <MenuItem onClick={handleCloseMenu}>
                                                                <ImportExport sx={{ mr: 1.75 }} /> Export setData
                                                            </MenuItem>
                                                            <MenuItem onClick={() => {
                                                                console.log(dataa["company"]["company_name"]);
                                                                handleCloseMenu();

                                                                handleCloseDeleteModal();
                                                            }}>
                                                                <DeleteForever sx={{ mr: 1.75 }} /> Delete Company
                                                            </MenuItem>
                                                        </Menu>
                                                    </div>}

                                                </Box>
                                                <div class="cardCName" style={{ color: theme.palette.secondary.dark }} onClick={() => handleClick(dataa["company"]["id"], dataa["role"]["name"])}>
                                                    <center >{dataa["company"]["company_name"]}</center>
                                                </div>
                                                <p onClick={() => handleClick(dataa["company"]["id"], dataa["role"]["name"])}>Created :&nbsp;
                                                    {
                                                        <Moment format="LL" utc="true">
                                                            {dataa["company"]["created_at"]}
                                                        </Moment>

                                                        // moment(dataa["company"]["created_at"]).format('LL')
                                                    }
                                                </p>
                                            </Box>

                                        </Grid>

                                    );
                                }) : <></>}</>}

                            </Grid>
                        </Grid></TabPanel>
                        <TabPanel value="2">
                            <Grid container spacing={gridSpacing}>
                                {Loading1 ? <> {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => {
                                    return <Grid item lg={3} md={6} sm={6} xs={12} >
                                        <Box class="card" justifyContent={"space-between"} display={"inline-block"}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}>
                                                <Skeleton variant="circular" height={35} width={35} />
                                                <div class="">

                                                    <Skeleton variant="circular" height={35} width={35} />
                                                </div>
                                            </Box>
                                            <div class="cardCName" style={{ color: theme.palette.secondary.dark }}>
                                                <Skeleton variant="text" height={35} width={260} />
                                            </div>
                                            <Skeleton variant="text" height={35} width={260} />
                                        </Box>

                                    </Grid>
                                })}</> : <> {EData.length != 0 ? EData.map((dataa, i) => {
                                    return (
                                        <Grid item lg={3} md={6} sm={6} xs={12} >
                                            <Box class="card" justifyContent={"space-between"} display={"inline-block"} style={{ backgroundColor: "white" }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}>
                                                    <Tooltip title={"You are Employer Of this Company"}>
                                                        <Info />
                                                    </Tooltip>

                                                </Box>
                                                <div class="cardCName" style={{ color: theme.palette.secondary.dark }} onClick={() => handleClick(dataa["id"], "employee")}>
                                                    <center >{dataa["company_name"]}</center>
                                                </div>
                                                <p onClick={() => handleClick(dataa["id"], "employee")}>Created :&nbsp;

                                                    <Moment format="LL" utc="true">
                                                        {dataa["created_at"]}
                                                    </Moment>


                                                </p>
                                            </Box>

                                        </Grid>

                                    );
                                }) : <></>}</>}

                            </Grid></TabPanel>
                    </TabContext>
                </Box>

            </Grid >

            < Dialog onClose={handleClose}

                open={onClose} fullScreen >

                <AppBar sx={{ position: 'relative', backgroundColor: "white" }}>
                    <Toolbar >
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close style={{ color: "black" }} />
                        </IconButton>

                        <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
                            Company Information </Typography>

                        <Button autoFocus color="info" onClick={handleClose}>
                            save
                        </Button>
                    </Toolbar>
                    <Box sx={{ width: '100%' }}>
                        <Stepper nonLinear activeStep={activeStep} alternativeLabel >
                            {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]}>
                                    <StepButton color="inherit" onClick={handleStep(index)}>
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                        <div style={{ padding: 20 }}>
                            {activeStep == 0 ?
                                <Box justifyContent="center" display="flex" flexDirection="row" style={{ color: "black" }}>Company Info</Box>
                                : 'Step'}
                        </div>
                    </Box>
                </AppBar>
            </Dialog >
            <Dialog
                onTouchCancel={false}
                onClose={handleAddCompanyClose}
                open={AddNewComanyModal}
                closeAfterTransition>
                <div style={{ width: '100%' }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 1,
                        m: 1,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                    }}>
                        <div style={{ color: "black", fontSize: 20 }}>Add Company</div> <div>
                            <Close style={{ color: "black", cursor: "pointer" }} onClick={handleAddCompanyClose} />
                        </div>
                    </Box>
                </div>
                <Box sx={{ width: '100%', padding: 5 }}>
                    <Formik
                        initialValues={
                            { c_name: '' }
                        }

                        validationSchema={Yup.object().shape({
                            c_name: Yup.string().max(255).required('FirstName is required'),

                        })}

                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

                            handleCompanyAdd(values.c_name);

                            try {
                                if (scriptedRef.current) {
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                }
                            } catch (err) {
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth error={Boolean(touched.c_name && errors.c_name)} sx={{ ...theme.typography.customInput }}>

                                        <InputLabel>Comapny Name</InputLabel>
                                        <OutlinedInput
                                            type="text"
                                            name="c_name"
                                            value={values.c_name}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.c_name && errors.c_name && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.c_name}
                                            </FormHelperText>
                                        )}
                                    </FormControl></Grid>
                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}

                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Submit
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>)}

                    </Formik>
                </Box>

            </Dialog>


            <Dialog
                onTouchCancel={false}
                onClose={handleCloseDeleteModal}
                open={DeleteModal}
                closeAfterTransition>
                <div style={{ width: '100%' }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 1,
                        m: 1,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                    }}>
                        <div style={{ color: "black", fontSize: 15 }}>Are you absolutely sure?</div>

                        <div>
                            <Close style={{ color: "black", cursor: "pointer" }} onClick={handleCloseDeleteModal} />
                        </div>
                    </Box>
                </div>

                <Box style={{ padding: 10, backgroundColor: "#ffdddd", margin: 10, borderRadius: 5, color: "black" }}>
                    <div style={{ fontSize: 13 }}><WarningRounded style={{ color: "red", marginRight: 10 }} />You are about to delete this project containing:
                    </div>
                    <div>

                        <ul><li>0 Payroll Backups</li>
                            <li>0 Employees</li>
                            <li>0 Company Data</li>
                        </ul>
                        This process deletes the Company and all related resources.
                    </div>
                </Box>
                <Box sx={{ width: '100%', padding: 5 }}>
                    <Formik
                        initialValues={
                            { c_name: '' }
                        }
                        validationSchema={Yup.object().shape({
                            c_name: Yup.string().max(255).required('required'),
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

                            // if (DeletePName === values.c_name) {
                            CompanyApi.DeleteCompany(DeleteID).then((response) => {
                                getCdata();
                                swal("Company Deleted", "", "Success");
                                setDeletePName("");
                                handleCloseDeleteModal();
                            }).catch((error) => {
                            })
                            // } else {
                            //     console.log("sd");
                            // }

                            try {
                                if (scriptedRef.current) {
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                }
                            } catch (err) {
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>

                                <Grid item xs={12} sm={6}>

                                    <div>{"Enter the following to confirm:"}</div>
                                    <div style={{ backgroundColor: "#f2e1e1", color: "black", borderRadius: 2, padding: 5 }}>{DeletePName}</div>

                                    <FormControl fullWidth error={Boolean(touched.c_name && errors.c_name)} sx={{ ...theme.typography.customInput }}>

                                        <InputLabel>Enter the name of above</InputLabel>

                                        <OutlinedInput
                                            type="text"
                                            name="c_name"
                                            value={values.c_name}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.c_name && errors.c_name && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.c_name}
                                            </FormHelperText>
                                        )}
                                    </FormControl></Grid>
                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}

                                <Box sx={{ mt: 2 }}>

                                    <Grid container>
                                        <Grid item xs={12} sm={6}>
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    onClick={() => handleCloseDeleteModal()}
                                                    size="large"
                                                    variant="contained"
                                                    color="secondary"
                                                    style={{ margin: 5 }}
                                                >
                                                    No , Keep It.
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    disabled={isSubmitting}
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    style={{
                                                        margin: 5,
                                                        color: "white",
                                                        backgroundColor: "#ff0000"

                                                    }}

                                                >
                                                    Okay, Delete It.
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                    </Grid>


                                </Box>
                            </form>)}

                    </Formik>
                </Box>

            </Dialog>
        </div >
    );
};

export default Comapany;
