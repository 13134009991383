import { AppBar, Button, Dialog, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { CompanyApi, DeductionApi, TaxAgencyApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@emotion/react';
import { Check, Close, Delete, Edit, Info } from '@mui/icons-material';
import TaxAgency from './tax_agency';
import { Link } from 'react-router-dom';

// ==============================|| SAMPLE PAGE ||============================== //

const DeductionTypes = () => {
  const location = useLocation();
  console.log(localStorage.getItem("cffggr_id")
  );

  const [deduction_name, setdeduction_name] = useState("")
  const [deduction_type, setdeduction_type] = useState("Select Type")
  const [gl_expense_account, setgl_expense_account] = useState("")
  const [tax_agency, settax_agency] = useState("tax agency");
  const [gl_liablity_account, setgl_liablity_account] = useState("")
  const [income_tax, setincome_tax] = useState(false)
  const [cpp, setcpp] = useState(false)
  const [ei, setei] = useState(false)
  const scriptedRef = useScriptRef();
  const [DataDeduction, setDataDeduction] = useState([]);
  const [dedctionMasterData, setdedctionMasterData] = useState([]);
  const [TaxagencyData, setTaxagencyData] = useState([]);
  const [taxAgencyId, settaxAgencyId] = useState(0);
  const [EditDeductionId, setEditDeductionId] = useState(0);


  //Type Masters
  const [SelectedTypeOdDeductionID, setSelectedTypeOdDeductionID] = useState(0);

  const [IncomeTax, setIncomeTax] = useState("");
  const [Cpp_and_Qpp, setCpp_and_Qpp] = useState("");
  const [ei_select, setei_select] = useState("");
  const [qpip, setqpip] = useState("");

  const [IncomeTaxEmployer, setIncomeTaxEmployer] = useState("");
  const [Cpp_and_QppEmployer, setCpp_and_QppEmployer] = useState("");
  const [ei_selectEmployer, setei_selectEmployer] = useState("");
  const [qpipEmployer, setqpipEmployer] = useState("");


  const [Payble, setPayble] = useState("");
  const [insurableHours, setinsurableHours] = useState("");
  const [insurableEarning, setinsurableEarning] = useState('');
  const [boxT4, setboxT4] = useState("");
  const [RL1Box, setRL1Box] = useState("");


  //Server Integrations
  const getCompany = () => {

    DeductionApi.AllDeduction(localStorage.getItem("cffggr_id")).then((response) => {
      if (response.data === null)
        return null;
      setDataDeduction(response.data);
    })
      .catch((error) => {
      });
  }

  const deleteDeduction = (id) => {
    console.log(id);
    DeductionApi.DeleteDeduction(localStorage.getItem("cffggr_id"), id).then((resposne) => {
      getCompany();
    }).catch((error) => {

    })
  }

  const getdTypeData = () => {
    DeductionApi.getDeductionMasterTypesData().then((response) => {
      setdedctionMasterData(response.data);
    }).catch((error) => {

    })
  }
  const getTxAgencyData = () => {
    TaxAgencyApi.AllTaxAgency(localStorage.getItem("cffggr_id")).then((response) => {
      setTaxagencyData(response.data);
    }).catch((error) => {

    })
  }

  useEffect(() => {
    getCompany();
    getTxAgencyData();
    getdTypeData();
  }, []);


  const clearData = () => {
    setdeduction_name("");
    setdeduction_type("");
    setgl_expense_account("");
    settax_agency("");
    setgl_liablity_account("");
    setSelectedTypeOdDeductionID(0);
    setIncomeTax("");
    setCpp_and_Qpp("");
    setei_select("");
    setPayble("");
    setIncomeTaxEmployer("");
    setCpp_and_QppEmployer("");
    setei_selectEmployer("");
    setqpipEmployer("");
    setqpip("");
    setinsurableHours("");
    setinsurableEarning("");
    setboxT4("");
    setRL1Box("");
  }
  //UI Side
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const theme = useTheme();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const [onClose, setonClose] = useState(false);
  const handleClose = () => {
    setEditDeductionId(0);
    clearData();
    setonClose(!onClose);
  };

  const [valueTab, setValueTab] = React.useState(0);

  const handleChangeTab = (event, newValueTab) => {
    setValueTab(newValueTab);
  };
  const getdeductionDataById = (id) => {
    console.log(id);
    DeductionApi.FindDeduction(localStorage.getItem("cffggr_id"), id).then((resposne) => {
      setonClose(!onClose);
      setEditDeductionId(resposne.data.id);
      setdeduction_name(resposne.data.deduction_name);
      setdeduction_type(resposne.data.deduction_type.deduction_type);
      setgl_expense_account(resposne.data.gl_expense_account);
      settax_agency(resposne.data.taxagency.tax_agency_name);
      setgl_liablity_account(resposne.data.gl_liablity_account);
      setSelectedTypeOdDeductionID(resposne.data.id);
      setIncomeTax(resposne.data.deduction_type.employee_income_tax);
      setCpp_and_Qpp(resposne.data.deduction_type.employee_ei);
      setei_select(resposne.data.deduction_type.employee_ei);
      setPayble(resposne.data.deduction_type.payable);
      setIncomeTaxEmployer(resposne.data.deduction_type.employer_income_tax);
      setCpp_and_QppEmployer(resposne.data.deduction_type.employer_cpp);
      setei_selectEmployer(resposne.data.deduction_type.employer_ei);
      setqpipEmployer(resposne.data.deduction_type.employer_qpip);
      setqpip(resposne.data.deduction_type.employee_qpip);
      setinsurableHours(resposne.data.deduction_type.insurable_hours);
      setinsurableEarning(resposne.data.deduction_type.insurable_earning);
      setboxT4("85");
      setRL1Box("A,J");
    }).catch((error) => {

    })
  }
  return (
    <MainCard title="Deduction Types">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1.5 },
        }}
        noValidate
        autoComplete="off"
      >

        <Button onClick={() => handleClose()}
          variant="contained"
          color="secondary"
        >Add Deduction</Button>
        <br />
        <br />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>GL Expense no</StyledTableCell>
                <StyledTableCell>GL Liability no</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {DataDeduction.map((data) => (
                <StyledTableRow sx={{ border: 0 }}>
                  <StyledTableCell>{data.deduction_name}</StyledTableCell>
                  <StyledTableCell>{data.deduction_type.deduction_type}</StyledTableCell>
                  <StyledTableCell>{data.gl_expense_account}</StyledTableCell>
                  <StyledTableCell>{data.gl_liablity_account}</StyledTableCell>
                  <StyledTableCell><Edit style={{ marginRight: 5, cursor: "pointer" }} onClick={() => getdeductionDataById(data.id)} />{data.deduction_type.is_fixed === false ? <Delete style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => deleteDeduction(data.id)} /> : ""}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog
        onClose={handleClose}
        maxWidth="xl"
        open={onClose}
        closeAfterTransition>

        <AppBar sx={{ position: 'relative', backgroundColor: "white" }} elevation={0}>
          <Toolbar >

            <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
              Deduction Type</Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close style={{ color: "black" }} />
            </IconButton>

          </Toolbar>
        </AppBar>

        <Grid container spacing={matchDownSM ? 0 : 2}>
          <Grid item xs={12} sm={6}>
            <div style={{ padding: 20 }}>
              <Formik
                initialValues={{
                  deduction_name: deduction_name,
                  deduction_type: deduction_type == "" ? "Select Type" : deduction_type,
                  gl_expense_account: gl_expense_account,
                  tax_agency: tax_agency == "" ? "tax agency" : tax_agency,
                  gl_liablity_account: gl_liablity_account,
                  income_tax: income_tax,
                  cpp: cpp,
                  ei: ei,
                  submit: null
                }}
                validationSchema={Yup.object().shape({
                  // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                  // password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

                  if (EditDeductionId !== 0) {
                    var cdata = {
                      id: EditDeductionId,
                      deduction_name: values.deduction_name,
                      deduction_id: SelectedTypeOdDeductionID,
                      gl_expense_account: values.gl_expense_account,
                      gl_liablity_account: values.gl_liablity_account,
                      tax_id: taxAgencyId
                    };
                    DeductionApi.UpdateDeduction(localStorage.getItem("cffggr_id"), EditDeductionId, cdata)
                      .then((response) => {
                        console.log(response.data)
                        getCompany();
                        handleClose();
                        swal("Deduction Added", "", "success");

                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    var cdata = {
                      deduction_name: values.deduction_name,
                      deduction_id: SelectedTypeOdDeductionID,
                      gl_expense_account: values.gl_expense_account,
                      gl_liablity_account: values.gl_liablity_account,
                      tax_id: taxAgencyId
                    };
                    DeductionApi.AddDeduction(localStorage.getItem("cffggr_id"), cdata)
                      .then((response) => {
                        console.log(response.data)
                        getCompany();
                        handleClose();
                        swal("Deduction Added", "", "success");

                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }

                  try {
                    if (scriptedRef.current) {
                      setStatus({ success: true });
                      setSubmitting(false);
                    }
                  } catch (err) {
                    console.error(err);
                    if (scriptedRef.current) {
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                    }
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <FormControl required fullWidth error={Boolean(touched.deduction_type && errors.deduction_type)} sx={{ ...theme.typography.customInput }}>
                      <Select
                        required
                        value={values.deduction_type}
                        name="deduction_type"
                        defaultValue='Select Type'
                        onChange={handleChange}
                      >
                        <MenuItem value={"Select Type"} defaultChecked>Deduction Type</MenuItem>

                        {dedctionMasterData.map((data) => {
                          return <MenuItem value={data.deduction_type} onClick={() => {
                            console.log(data);
                            setSelectedTypeOdDeductionID(data.id);
                            setIncomeTax(data.employee_income_tax);
                            setCpp_and_Qpp(data.employee_ei);
                            setei_select(data.employee_ei);
                            setPayble(data.payable);

                            setIncomeTaxEmployer(data.employer_income_tax);
                            setCpp_and_QppEmployer(data.employer_cpp);
                            setei_selectEmployer(data.employer_ei);
                            setqpipEmployer(data.employer_qpip);

                            setqpip(data.employee_qpip);
                            setinsurableHours(data.insurable_hours);
                            setinsurableEarning(data.insurable_earning);
                            setboxT4("85");
                            setRL1Box("A,J");
                          }}>{data.deduction_type}</MenuItem>;
                        })}

                      </Select>

                      {touched.deduction_type && errors.deduction_type && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.deduction_type}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl required fullWidth error={Boolean(touched.tax_agency && errors.tax_agency)} sx={{ ...theme.typography.customInput }}>
                      <Select
                        required
                        value={values.tax_agency}
                        name="tax_agency"
                        defaultValue='tax agency'
                        onChange={handleChange}
                      >
                        <MenuItem value={"tax agency"} defaultChecked>Tax Agency</MenuItem>

                        {TaxagencyData.map((data) => {
                          return <MenuItem value={data.tax_agency_name} onClick={() => {
                            console.log(data);
                            settaxAgencyId(data.id);
                          }}>{data.tax_agency_name}</MenuItem>;
                        })}

                      </Select>
                      {TaxagencyData.length === 0 ?
                        <div style={{ color: "red", marginLeft: 10, fontSize: 13, marginTop: 5 }}>Please add Tax Agency First <Link to="/app-payroll/company/taxagency">From here</Link></div>
                        : ""}
                      {touched.tax_agency && errors.tax_agency && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.tax_agency}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth error={Boolean(touched.deduction_name && errors.deduction_name)} sx={{ ...theme.typography.customInput }}>
                      <InputLabel htmlFor="outlined-adornment-lastname-register">Deduction Name</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-lastname-register"
                        type="text"
                        value={values.deduction_name}
                        name="deduction_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.deduction_name && errors.deduction_name && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.deduction_name}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth error={Boolean(touched.gl_expense_account && errors.gl_expense_account)} sx={{ ...theme.typography.customInput }}>
                      <InputLabel htmlFor="outlined-adornment-lastname-register">GL Expense Account</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-lastname-register"
                        type="text"
                        value={values.gl_expense_account}
                        name="gl_expense_account"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.gl_expense_account && errors.gl_expense_account && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.gl_expense_account}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth error={Boolean(touched.gl_liablity_account && errors.gl_liablity_account)} sx={{ ...theme.typography.customInput }}>
                      <InputLabel htmlFor="outlined-adornment-lastname-register">GL Liablity Account</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-lastname-register"
                        type="gl_liablity_account"
                        value={values.gl_liablity_account}
                        name="gl_liablity_account"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.gl_liablity_account && errors.gl_liablity_account && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                          {errors.gl_liablity_account}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Grid container spacing={matchDownSM ? 0 : 2}>
                      <Grid item xs={12} sm={6}>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                      </Grid>
                    </Grid>

                    {/* <Grid container spacing={matchDownSM ? 0 : 2}>
                  <Grid item xs={12} sm={4}>
                    <div>
                      Deduct After Income Tax  <Tooltip title={"Deduct After Income Tax "}>
                        <Info />
                      </Tooltip></div>
                    <Switch
                      checked={income_tax}

                      onChange={() => {
                        if (income_tax === true) {
                          setincome_tax(false);
                        } else {
                          setincome_tax(true);
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div>
                      Deduct After CPP  <Tooltip title={"Deduct After CPP"}>
                        <Info />
                      </Tooltip></div>
                    <Switch
                      checked={cpp}

                      onChange={() => {
                        if (cpp === true) {
                          setcpp(false);
                        } else {
                          setcpp(true);
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Grid>  <Grid item xs={12} sm={4}>
                    <div>
                      Deduct After EI <Tooltip title={"Deduct After EI"}>
                        <Info />
                      </Tooltip></div>
                    <Switch
                      checked={ei}
                      onChange={() => {
                        if (ei === true) {
                          setei(false);
                        } else {
                          setei(true);
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Grid>

                </Grid> */}



                    {errors.submit && (
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}

                    <Box sx={{ mt: 2 }}>
                      <AnimateButton>
                        <Button
                          disableElevation
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          color="secondary"
                        >
                          Save
                        </Button>
                      </AnimateButton>
                    </Box>
                  </form>
                )}
              </Formik>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div style={{ padding: 20 }}>
              <Tabs
                orientation="horizontal"
                variant="scrollable"
                centered
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                value={valueTab} onChange={handleChangeTab}
              >
                <Tab label="Employee Contribution" />
                <Tab label="Employer Contribution" />

              </Tabs>
              {
                valueTab === 0 ? <div>
                  <Grid container spacing={matchDownSM ? 0 : 2}>
                    <Grid item xs={12} sm={12}>
                      <TableContainer >
                        <Table sx={{ minWidth: 200 }} >
                          {/* <TableHead style={{ backgroundColor: "wheat" }}> */}
                          <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>FEDERAL</StyledTableCell>
                            {/* <StyledTableCell>GL Expense no</StyledTableCell> */}

                          </TableRow>
                          {/* </TableHead> */}
                          <TableBody style={{ borderWidth: 3, borderColor: "black" }} >

                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Income Tax</StyledTableCell>
                              <StyledTableCell>{IncomeTax ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>CPP/QPP</StyledTableCell>
                              <StyledTableCell>{Cpp_and_Qpp ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>  <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>EI</StyledTableCell>
                              <StyledTableCell>{ei_select ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>QPIP</StyledTableCell>
                              <StyledTableCell>{qpip ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                          <br />
                          <TableBody>
                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Payable</StyledTableCell>
                              <StyledTableCell>{Payble ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>

                          </TableBody>
                          <br />
                          <b>Record Of Employment</b>
                          <br />

                          <TableBody>

                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Insurable hours</StyledTableCell>
                              <StyledTableCell>{insurableHours}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Insurable earnings</StyledTableCell>
                              <StyledTableCell>{insurableEarning}</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>

                          <br />
                          <b>From T4</b>
                          <br />

                          <TableBody>

                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Box</StyledTableCell>
                              <StyledTableCell>{boxT4}</StyledTableCell>
                            </StyledTableRow>

                          </TableBody>
                          <br />
                          <b>From RL-1</b>
                          <br />

                          <TableBody>

                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Box</StyledTableCell>
                              <StyledTableCell>{RL1Box}</StyledTableCell>
                            </StyledTableRow>

                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                  </Grid>
                </div> : <div>
                  <Grid container spacing={matchDownSM ? 0 : 2}>
                    <Grid item xs={12} sm={12}>
                      <TableContainer >
                        <Table sx={{ minWidth: 200 }} >
                          {/* <TableHead style={{ backgroundColor: "wheat" }}> */}
                          <TableRow>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell>FEDERAL</StyledTableCell>
                            {/* <StyledTableCell>GL Expense no</StyledTableCell> */}

                          </TableRow>
                          {/* </TableHead> */}
                          <TableBody style={{ borderWidth: 3, borderColor: "black" }} >

                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>Income Tax</StyledTableCell>
                              <StyledTableCell>{IncomeTaxEmployer ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>CPP/QPP</StyledTableCell>
                              <StyledTableCell>{Cpp_and_QppEmployer ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>  <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>EI</StyledTableCell>
                              <StyledTableCell>{ei_selectEmployer ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow sx={{ border: 0 }}>
                              <StyledTableCell>QPIP</StyledTableCell>
                              <StyledTableCell>{qpipEmployer ? <Check style={{ color: "green" }} /> : "-"}</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>

                        </Table>
                      </TableContainer>
                    </Grid>

                  </Grid>

                </div>
              }
            </div>
          </Grid>

        </Grid>
      </Dialog>



    </MainCard >
  );
};

export default DeductionTypes;
