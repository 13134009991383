
// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import {
    BrowserRouter,
    Route,
    useNavigate,
    Routes,
    useRoutes,
    Outlet,
} from "react-router-dom";
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import PrivateRoutes from 'config/privateroute';
import MainLayout from 'layout/MainLayout';
import MainLayoutEm from 'layout/MainLayout/index_emplloyee';
import Comapany from 'views/company/company';
import { Button } from '@mui/material';
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Product = Loadable(lazy(() => import('views/product/products')));
const MyProfile = Loadable(lazy(() => import('views/profile/my_profile')));


const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Payroll = Loadable(lazy(() => import('views/dashboards/payroll/payroll')));
const PayrollEmployee = Loadable(lazy(() => import('views/EmployeeInfo/payrolls')));
const Home = Loadable(lazy(() => import('views/dashboards/home/home')));
const HomeEmployee = Loadable(lazy(() => import('views/EmployeeInfo/dashboard')));
const WorkInfoEmployee = Loadable(lazy(() => import('views/EmployeeInfo/workinfo/workinfo')));
const EmpInfoEmployee = Loadable(lazy(() => import('views/EmployeeInfo/empinfo/Empinfo')));
const Company = Loadable(lazy(() => import('views/dashboards/company/company')));
const Report = Loadable(lazy(() => import('views/dashboards/reports/reports')));
const CInfo = Loadable(lazy(() => import('views/dashboards/company/company')));
const DeTy = Loadable(lazy(() => import('views/dashboards/company/deduction-types')));
const InTye = Loadable(lazy(() => import('views/dashboards/company/income-types')));
const PayScheduel = Loadable(lazy(() => import('views/dashboards/company/pay-scheduel')));
const ComHol = Loadable(lazy(() => import('views/dashboards/company/company-holidays')));
const Vac = Loadable(lazy(() => import('views/dashboards/company/vacation')));
const TaxAgency = Loadable(lazy(() => import('views/dashboards/company/tax_agency')));
const Departments = Loadable(lazy(() => import('views/dashboards/company/deparments')));
const EmployeeInfoDashboardMaster = Loadable(lazy(() => import('views/EmployeeInfo/employeeDashboard')));


const Employee = Loadable(lazy(() => import('views/dashboards/employee/employee')));
const EditEmployee = Loadable(lazy(() => import('views/dashboards/employee/editemployee')));

const Settings = Loadable(lazy(() => import('views/dashboards/setting/setting')));

export default function ThemeRoutes() {
    const navigate = useNavigate();

    // return useRoutes([MainRoutes, AuthenticationRoutes]);
    return (
        <Routes>
            <Route
                index
                path="/"
                name="Main Page"
                element={
                    <><center>
                        Comming Soon
                        <Button onClick={() => {
                            navigate('/signin');
                        }}>Go to Login Page</Button>
                    </center></>
                }
            />
            <Route
                element={
                    <PrivateRoutes />
                }>
                <Route element={<MainLayout />} path="/app-payroll" >
                    <Route element={<Home />} path="home" exact />
                    <Route element={<Payroll />} path="payroll" exact />
                    <Route element={<Report />} path="report" exact />
                    <Route element={<Employee />} path="employee" />
                    <Route path="employee" >
                        <Route element={<EditEmployee />} path="details" exact />
                    </Route>
                    <Route element={<Settings />} path="setting" exact />
                    <Route path="company" >
                        <Route element={<CInfo />} path="companyinfo" exact />
                        <Route element={<DeTy />} path="deductiontypes" exact />
                        <Route element={<InTye />} path="incometypes" exact />
                        <Route element={<PayScheduel />} path="payscheduel" exact />
                        <Route element={<ComHol />} path="companyholidays" exact />
                        <Route element={<Vac />} path="vacations" exact />
                        <Route element={<TaxAgency />} path="taxagency" exact />
                        <Route element={<Departments />} path="department" exact />
                    </Route>
                </Route>

                <Route element={<MainLayoutEm />} path="/app-payroll-employee" >
                    <Route element={<HomeEmployee />} path="home" exact />
                    <Route element={<WorkInfoEmployee />} path="work-info" exact />
                    <Route element={<EmpInfoEmployee />} path="my-Info" exact />
                </Route>
                <Route element={<Product />} path="/app/products" />
                <Route element={<MyProfile />} path="/app/profile" />
                <Route
                    path="/app/product/company/employee"
                    name="payroll-empdash"
                    element={<EmployeeInfoDashboardMaster />}
                />
                <Route
                    index
                    path="/app/product/company"
                    name="payroll-companies"
                    element={<Comapany />}
                />
            </Route>
            <Route
                path="/signin"
                name="Login Page"
                element={<AuthLogin3 />}
            />
            <Route
                index
                path="/signup"
                name="Register Page"
                element={<AuthRegister3 />}
            />

            {/* <PrivateRoutes
                path="/"
                name="Home"
                render={(props) => <MainLayout {...props} />}
            /> */}
        </Routes >
    );
}
