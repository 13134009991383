import { AppBar, Button, Dialog, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Switch, Tab, Tabs, TextField, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { DeductionEmployeeApi, EmployeeApi } from 'config/api';
import { useEffect } from 'react';
import React, { useState } from 'react';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useLocation } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@emotion/react';
import { Close, Delete, Edit, Info } from '@mui/icons-material';

const YTDAmount = () => {

    const location = useLocation();

    const [YTDData, setYTDData] = useState([]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "gray",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const theme = useTheme();

    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const scriptedRef = useScriptRef();

    const [onClose, setonClose] = useState(false);
    const handleClose = () => {
        setonClose(!onClose);
    };

    const getYTDData = () => {
        EmployeeApi.YearToDateList(localStorage.getItem("cffggr_id"), location.state).then((response) => {
            setYTDData(response.data);
        }).catch((error) => {
        })
    }

    useEffect(() => {
        getYTDData()
    }, [])

    return (
        <MainCard>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1.5 },
                }}
                noValidate
                autoComplete="off"
            >

                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1.5 },
                    }}
                    noValidate
                    autoComplete="off"
                    style={{ padding: 20 }}
                >
                    <Formik
                        initialValues={{
                            federal_TD1: "Code 1 (0.00 to 14398.00)",
                            add_additional_tax: "0.00",
                            withhold_income_tax: 'withhold_income_tax',
                            exempt_from_cpp_and_qpp: "select",
                            exempt_from_ei: "select",
                            tax_type: false,
                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            // email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            // password: Yup.string().max(255).required('Password is required')
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                if (scriptedRef.current) {
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                }
                            } catch (err) {
                                console.error(err);
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <br></br>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Tax/Earning Description</StyledTableCell>
                                                <StyledTableCell>Initial YTD</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {YTDData.map((data) => (
                                                <StyledTableRow sx={{ border: 0 }}>
                                                    {(data.incomes !== null ?
                                                        <>
                                                            <StyledTableCell>{data.incomes.income.income_name}</StyledTableCell>
                                                            <StyledTableCell>
                                                                <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                                    <TextField
                                                                        id="outlined-uncontrolled"
                                                                        label={data.incomes.income.income_name}
                                                                        type="text"
                                                                        value={data.ytd_amount}
                                                                        name="add_additional_tax"
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        inputProps={{}}
                                                                    />
                                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                                            {errors.add_additional_tax}
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </StyledTableCell>
                                                        </>
                                                        : <>
                                                        <StyledTableCell>{data.deductions.deduction.deduction_name}</StyledTableCell>
                                                        <StyledTableCell>
                                                            <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                                <TextField
                                                                    id="outlined-uncontrolled"
                                                                    label={data.deductions.deduction.deduction_name}
                                                                    type="text"
                                                                    value={data.ytd_amount}
                                                                    name="add_additional_tax"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    inputProps={{}}
                                                                />
                                                                {touched.add_additional_tax && errors.add_additional_tax && (
                                                                    <FormHelperText error id="standard-weight-helper-text--register">
                                                                        {errors.add_additional_tax}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </StyledTableCell>
                                                    </>
                                                    )
                                                    }
                                                </StyledTableRow>
                                            ))}
                                            {/* <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>Fed+Prov Tax</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="Fed+Prov Tax"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>Employee CPP/QPP</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="Employee CPP/QPP"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>Employee EI</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="Employee EI"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>Bonus - Discretionary Wages</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="Bonus Discretionary Wages"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>Bonus - Work-related Wages</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="Bonus Work-related Wages"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>OverTime Wages</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="OverTime Wages"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>Ragular pay Wages</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="Ragular pay Wages"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>Ragular Pay Hours</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="Employee Work-related Wages"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>Stat Holiday Wages</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="Stat Holiday Wages"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>Time Worked On Stat Holiday Wages</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="Time Worked On Stat Holiday Wages"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>Vacation Pay" Paid $$</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="Vacation Pay Paid $$"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>Vacation Pay Hours Paid</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="Vacation Pay Hours Paid"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ border: 0 }}>
                                                <StyledTableCell>Vacation Pay Banked $$</StyledTableCell>
                                                <StyledTableCell>  <FormControl fullWidth error={Boolean(touched.add_additional_tax && errors.add_additional_tax)} >
                                                    <TextField
                                                        id="outlined-uncontrolled"
                                                        label="Vacation Pay Banked $$"
                                                        type="text"
                                                        value={values.add_additional_tax}
                                                        name="add_additional_tax"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        inputProps={{}}
                                                    />
                                                    {touched.add_additional_tax && errors.add_additional_tax && (
                                                        <FormHelperText error id="standard-weight-helper-text--register">
                                                            {errors.add_additional_tax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl></StyledTableCell>
                                            </StyledTableRow> */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>


                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}
                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}

                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Save
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </MainCard>
    )
}

export default YTDAmount;