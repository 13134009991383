import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "../../assets/style/style.css";
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { SET_MENU } from 'store/actions';
import imgg from "../../assets/payroll1.png";
import ProfileSection from 'layout/MainLayout/Header/ProfileSection';
import EarningCard from 'ui-component/cards/Skeleton/EarningCard';
import TotalOrderLineChartCard from 'views/dashboard/Default/TotalOrderLineChartCard';
import TotalIncomeDarkCard from 'views/dashboard/Default/TotalIncomeDarkCard';
import TotalIncomeLightCard from 'views/dashboard/Default/TotalIncomeLightCard';
import TotalGrowthBarChart from 'ui-component/cards/Skeleton/TotalGrowthBarChart';
import PopularCard from 'ui-component/cards/Skeleton/PopularCard';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import styled from '@emotion/styled';
import imgs from '../../assets/productback.png';
import { useNavigate } from 'react-router';
const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    color: '#fff',
    height: 250,
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    '&>div': {
        position: 'relative',
        zIndex: 5
    },

    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.primary[800],
        borderRadius: '50%',
        zIndex: 1,
        top: -85,
        right: -95,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        width: 210,
        height: 210,
        background: theme.palette.primary[800],
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70
        }
    }
}));

const Product = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        console.log('Sds');
        navigate('/app/product/company');
    };
    var data = [
        {
            name: 'Payroll'
        }
    ];
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, []);
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <div style={{
            backgroundColor: 'white',
            backgroundImage: `url(${imgs})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',
        }}>
            <Toolbar>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ flexGrow: 1 }} style={{ fontSize: 20, color: "black" }}>
                    Product
                </Box>
                <ProfileSection />
            </Toolbar>
            <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                <div onClick={() => handleClick()} style={{ cursor: "pointer" }} class="divProduct">
                    <img src={imgg} class="prodpayrollimg"></img><br />
                    <center><div style={{ fontSize: 25, color: "balck" }}>Payroll</div></center>
                </div>
            </Grid>
            {/* <center>
                <Box sx={{ display: 'flex', textAlign: 'center' }} justifyContent="center">
                    {data.map((dataa) => {
                        return (
                            <Box>
                                <CardWrapper border={false} content={false}>
                                    <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                                        $500.00
                                    </Typography>
                                </CardWrapper>
                            </Box>
                        );
                    })}
                </Box>
            </center> */}
        </div>
    );
};

export default Product;
