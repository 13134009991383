// assets
import { IconDashboard, IconHome, IconUser, IconHome2 } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconHome, IconUser, IconHome2 };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'home',
            type: 'item',
            url: '/app-payroll-employee/home',
            icon: icons.IconHome,
            breadcrumbs: false
        },
        {
            id: 'workinfo',
            title: 'Work Info',
            type: 'item',
            url: '/app-payroll-employee/work-info',
            icon: icons.IconHome2,
            breadcrumbs: false
        },
        {
            id: 'myinfo',
            title: 'My Info',
            type: 'item',
            url: '/app-payroll-employee/my-info',
            icon: icons.IconUser,
            breadcrumbs: false
        },
    ]
};

export default dashboard;
